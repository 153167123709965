import React, { useEffect } from 'react';
import './popUpDiv.scss';
import { FaTimes } from 'react-icons/fa';

interface popUpDivChildProps {
  className?: string;
  children: React.ReactNode;
  initialValue?: boolean;
  setPopUp: Function;
  popupTitle?: string;
  header?: boolean;
  scrollOff?: boolean;
  fullScreenMode?: boolean;
  innerDivRef?: React.RefObject<HTMLDivElement> | null;
}
const PopUpDivChild = ({
  className = '',
  children,
  initialValue = false,
  setPopUp,
  popupTitle = '',
  header = true,
  scrollOff = true,
  innerDivRef = null,
  fullScreenMode = false,
}: popUpDivChildProps) => {
  const handlePopUp = () => {
    setPopUp(!initialValue);
  };
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (scrollOff) {
      document.body.classList.add('PopUpDivChildScrollLock');
      return () => {
        document.body.classList.remove('PopUpDivChildScrollLock');
      };
    }
  }, []);

  return (
    <div className={`PopUpDivChild ${className}`}>
      {initialValue && (
        <div
          className={`popUpOuterContaier ${className ? `${className}popUpOuterContaier` : ''}`}
          onClick={() => handlePopUp()}
        >
          <div
            className={`PopUpInnerContaier ${className ? `${className}PopUpInnerContaier` : ''}`}
            onClick={(e) => stopPropagation(e)}
            ref={innerDivRef}
            style={
              fullScreenMode
                ? {
                    minHeight: '100%',
                    minWidth: '100%',
                    height: '100%',
                  }
                : {}
            }
          >
            {header ? (
              <div className={`popupTitleDiv ${className ? `${className}popupTitleDiv` : ''}`}>
                <div className='popupTitle'>{popupTitle}</div>
                <div className='closeButton' onClick={() => handlePopUp()}>
                  <FaTimes />
                </div>
              </div>
            ) : null}
            <div
              className={`ChildernContainer ${className ? `${className}ChildernContainer` : ''}`}
              style={
                fullScreenMode
                  ? {
                      overflow: 'auto',
                    }
                  : {}
              }
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpDivChild;
