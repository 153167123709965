import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useEffect, useState } from 'react';
import styles from './aiCustomizationEditorContainer.module.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { toast } from 'react-toastify';
import './templateData.scss';
import { FaRegCopy } from 'react-icons/fa';
interface templateDataProps {
  isTemplateDataPopup: boolean;
  setIsTemplateDataPopup: Function;
  componentSchema: any;
  selectedData: any;
  selectedTable: string;
}

const TemplateData = ({
  isTemplateDataPopup,
  setIsTemplateDataPopup,
  componentSchema,
}: templateDataProps) => {
  // const moreAction = selectedData?.actionOrder ?? [];
  const [templatesData, setTemplatesData] = useState(componentSchema?.templates ?? {});
  const [templatesDataSearch, setTemplatesDataSearch] = useState('');
  const [copiedID, setCopiedID] = useState('');

  useEffect(() => {
    const templateData = componentSchema?.templates ?? {};
    let newDownloadData = {};
    Object.keys(templateData ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = templateData[templateKey];
      // if (selectedTemplateData?.origin !== selectedTable) {
      //   return;
      // }
      if (
        (selectedTemplateData?.title ?? '')
          .toLowerCase()
          ?.includes(templatesDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
      }
    });
    setTemplatesData(newDownloadData);
  }, [templatesDataSearch, componentSchema?.templates]);

  const copyTemplateID = ({ id }: any) => {
    const selectedTemplateData = templatesData[id];
    copyToClipBoard({
      text: id,
      customMsg: `${mls('Copied the Template ID of')}: ${selectedTemplateData?.title ?? id}`,
    });
  };

  const copyToClipBoard = ({
    text = '',
    isMsg = true,
    customMsg = null,
  }: {
    text: string;
    isMsg?: boolean;
    customMsg: null | string;
  }) => {
    navigator.clipboard.writeText(text);

    if (isMsg) {
      const tostMsg = customMsg ?? `${mls('Copied the text')}: ${text}`;
      // Alert the copied text
      toast.success(tostMsg, {
        theme: 'light',
      });
    }
  };
  const onCopy = ({ id }: { id: string }) => {
    copyTemplateID({ id: id });
    setCopiedID(id);
  };
  console.log('templatesData', templatesData);

  return (
    <div>
      <PopUpDivChild
        initialValue={isTemplateDataPopup}
        setPopUp={setIsTemplateDataPopup}
        popupTitle={mls('Templates')}
        className='templateDataContainerPopup'
      >
        <div className={styles.templateDataContainer}>
          <div className={styles.templateDataContainerLeft}>
            <div className={styles.actionSelectionContainer}>
              {/* <div className={styles.actionSelectionContainerTitle}>{mls('Templates')}</div> */}
              <div className={styles.actionSelectionContainerSubTitle}>
                <SearchInputDiv
                  value={templatesDataSearch}
                  onChange={setTemplatesDataSearch}
                  placeholder={mls('Search Templates')}
                  border
                  fullWidth
                />
              </div>
              <DataGridTableContainer
                onCopy={onCopy}
                tableData={templatesData}
                copiedID={copiedID}
              />
              {/* <div className={styles.actionSelectionInnerContainer}>
                {Object.keys(templatesData ?? {}).map((templateKey: string) => {
                  const selectedTemplateData = templatesData[templateKey];
                  const templateData = {
                    id: templateKey,
                    type: selectedTemplateData?.type,
                    title: selectedTemplateData?.title,
                  };
                  return (
                    <div
                      className={styles.actionSelectionInnerElem}
                      onClick={() => {
                        copyTemplateID({ templateID: templateKey });
                      }}
                    >
                      <FaRegCopy />
                      {templateData.title} ( {templateData.type} )
                      {isTemplateIDCopyed ? <>~ ( {templateKey} )</> : null}
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default TemplateData;

interface dataGridTableContainerProps {
  onCopy: (e: any) => void;
  tableData: { [key: string]: any };
  copiedID: string;
}
const DataGridTableContainer = ({ onCopy, tableData, copiedID }: dataGridTableContainerProps) => {
  return (
    <div className={styles.dataTableContainer}>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Template Name')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Template Type')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Template ID')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('')}
      </div>

      {Object.keys(tableData ?? {}).map((tableDataKey: string) => {
        const selectedTableDataData = tableData[tableDataKey];
        const tableDataData = {
          title: selectedTableDataData?.title,
          type: selectedTableDataData?.type,
          templateID: selectedTableDataData?.templateID ?? '',
        };
        const isCopied = copiedID === tableDataKey;

        return (
          <>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.title}</div>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.type}</div>
            <div className={`${styles.dataTableContainerElem}`}>
              <b>{tableDataData.templateID}</b>
            </div>
            <div className={`${styles.dataTableContainerElem}`}>
              {tableDataData.type !== 'MAIN' ? (
                <button
                  className={`primaryButton ${isCopied ? styles.buttonCopied : ''}`}
                  onClick={() => {
                    onCopy({ id: tableDataKey });
                  }}
                >
                  <FaRegCopy /> &nbsp;
                  {mls(isCopied ? 'Copied' : 'Copy')}
                </button>
              ) : null}
            </div>
          </>
        );
      })}
    </div>
  );
};
