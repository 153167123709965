import { _supistaApiDelete, _supistaApiPost } from 'lib/server-connection/connections';
import { actionTypes } from './componentUpdateFunction';
import { finalTableUpdate } from '../utilFunctions';
import { toast } from 'react-toastify';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
export const handleDatabaseUpdate = async ({
  affectedComponent,
  tableUpdates,
  appID,
}: {
  affectedComponent: { [key: string]: any };
  tableUpdates: { [key: string]: any };
  appID: string;
}) => {
  const componenetPromises: any[] = [];
  const tableUpdatePromises: any[] = [];
  Object.keys(affectedComponent ?? {}).forEach((componentID) => {
    const componentData = affectedComponent[componentID];
    const data = handleComponentsUpdate({ componentSchema: componentData, appID });
    componenetPromises.push(data);
  });
  Object.keys(tableUpdates ?? {}).forEach((tableID) => {
    const tableUpdatetData = tableUpdates[tableID];
    const data = handleTableUpdate({ tableUpdatetData: tableUpdatetData, appID });
    tableUpdatePromises.push(data);
  });
  await Promise.all(componenetPromises);
  await Promise.all(tableUpdatePromises);
  return { skip: false };
};
const handleComponentsUpdate = async ({
  componentSchema,
  appID,
}: {
  componentSchema: { [key: string]: any };
  appID: string;
}) => {
  const apiUrl = `settings/${appID}/Components`;
  const payloadData = {
    data: {
      ...componentSchema,
      updatedAt: Date.now(),
    },
  };
  const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
  if (componenetUpdateRes.__d3__success) {
    return { skip: false, response: componenetUpdateRes?.response };
  }
  return { skip: true };
};

const handleTableUpdate = async ({
  tableUpdatetData,
  appID,
}: {
  tableUpdatetData: { [key: string]: any };
  appID: string;
}) => {
  if (tableUpdatetData.action === actionTypes.DELETE.key) {
    const apiUrl = `settings/${appID}/DataTable`;
    const payloadData = {
      data: {
        tableID: tableUpdatetData?.tableData?.tableID,
        name: tableUpdatetData?.tableData?.name,
      },
    };
    const tableUpdateRes = await _supistaApiDelete(apiUrl, payloadData);
    if (tableUpdateRes.__d3__success) {
      return { skip: false, response: tableUpdateRes?.response };
    }
  }
  if (tableUpdatetData.action === actionTypes.UPDATE.key) {
    const apiUrl = `settings/${appID}/DataTable`;
    const currentTimestamp = Date.now();
    const payloadData = {
      data: {
        ...(tableUpdatetData.tableData ?? {}),
        updatedAt: currentTimestamp,
      },
    };
    const tableUpdateRes = await _supistaApiPost(apiUrl, payloadData);
    if (tableUpdateRes.__d3__success) {
      return { skip: false, response: tableUpdateRes?.response };
    }
  }
  return { skip: true };
};

interface handleSaveProps {
  setWarningLoading: Function;
  tableUpdates: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
  dataTableSchema: { [key: string]: any };
  affectedData: { [key: string]: any };
  handleCloseWarningPopup: Function;
  updateTableSchema: Function;
  appID: string;
  handleClose: Function;
}
export const handleTableSave = ({
  setWarningLoading,
  tableUpdates,
  originalDataTableSchema,
  dataTableSchema,
  appID,
  affectedData,
  updateTableSchema,
  handleClose,
  handleCloseWarningPopup,
}: handleSaveProps) => {
  setWarningLoading(true);
  const currentTimestamp = Date.now();
  const udpatedTableUpdate = finalTableUpdate({ tableUpdates, originalDataTableSchema });
  const sendToBackend: { [key: string]: any } = {
    ...dataTableSchema,
    updates: udpatedTableUpdate,
    updatedAt: currentTimestamp,
  };
  _supistaApiPost(`settings/${appID}/DataTable`, {
    data: sendToBackend,
  })
    .then(async (response) => {
      if (!response?.__d3__success) {
        toast.error('Not able to save please try again later!');
        return;
      }
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: 'Saved successfully',
            time: 5,
            isActive: true,
          },
        })
      );
      const databaseUpdateRes = await handleDatabaseUpdate({
        affectedComponent: affectedData?.affectedComponent ?? {},
        tableUpdates: affectedData?.tableUpdates ?? {},
        appID,
      });
      if (!databaseUpdateRes.skip) {
        await publishToProduction(appID);
        await updateTableSchema(
          false,
          null,
          sendToBackend,
          sendToBackend?.tableID ?? '',
          dataTableSchema.name
        );
        handleClose();
        handleCloseWarningPopup();
        setWarningLoading(false);
      }
    })
    .catch(() => {
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: 'Unable to save',
            time: 5,
            isActive: true,
          },
        })
      );
    });
};
interface handleDeleteTableProps {
  setWarningLoading: Function;
  dataTableSchema: { [key: string]: any };
  affectedData: { [key: string]: any };
  handleCloseWarningPopup: Function;
  updateTableSchema: Function;
  appID: string;
  handleClose: Function;
}
export const handleTableDelete = async ({
  setWarningLoading,
  dataTableSchema,
  appID,
  affectedData,
  updateTableSchema,
  handleClose,
  handleCloseWarningPopup,
}: handleDeleteTableProps) => {
  setWarningLoading(true);
  _supistaApiDelete(`settings/${appID}/DataTable`, {
    data: {
      tableID: dataTableSchema.tableID,
      name: dataTableSchema.name,
    },
  })
    .then(async (response) => {
      handleCloseWarningPopup();
      handleClose();
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: 'Table Deleted Successfully',
            time: 5,
            isActive: true,
          },
        })
      );

      const databaseUpdateRes = await handleDatabaseUpdate({
        affectedComponent: affectedData?.affectedComponent ?? {},
        tableUpdates: affectedData?.tableUpdates ?? {},
        appID,
      });
      if (!databaseUpdateRes.skip) {
        await publishToProduction(appID);
        updateTableSchema(true, dataTableSchema.tableID);
        setWarningLoading(false);
      }
    })
    .catch((err) => {
      setWarningLoading(false);
      console.error(err);
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: 'Failed To Delete Table',
            time: 5,
            isActive: true,
          },
        })
      );
    });
};
