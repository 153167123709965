import { getDefaultViewElem } from 'lib/appComponent/componentSettings/utils/componentSettingFunction';
import { udpateRoleAccessListElem } from './handleRoleAccessUpdate';
import { handleCodeCustomizationUpdate } from './handleCodeCustomizationUpdate';

export const handleTableListUpdate = ({
  tableColumnsUpdates,
  componentElem,
  targetedTableElem,
}: {
  tableColumnsUpdates: { [key: string]: any };
  componentElem: { [key: string]: any };
  targetedTableElem: { [key: string]: any };
}) => {
  const targatedTableID = targetedTableElem?.tableID;
  let updatedComponentElem = { ...componentElem };

  // tableList
  const { isTargetTableUpdated: isTargetTableUpdatedInner, updateTableList } = udpateTableListElem({
    tableList: componentElem?.tableList ?? [],
    targatedTableID,
    tableColumnsUpdates,
  });
  if (isTargetTableUpdatedInner) {
    updatedComponentElem = { ...updatedComponentElem, tableList: updateTableList };
  }

  // roleAccess
  const { isTargetTeamUpdated: isTargetTeamUpdatedInner, updatedRoleAccessData } =
    udpateRoleAccessListElem({
      roleAccess: updatedComponentElem?.roleAccess ?? {},
      tableList: updatedComponentElem?.tableList ?? [],
      targetedTableElem,
      tableColumnsUpdates,
    });
  if (isTargetTeamUpdatedInner) {
    updatedComponentElem = { ...updatedComponentElem, roleAccess: updatedRoleAccessData };
  }

  return { isTargetTableUpdated: isTargetTableUpdatedInner, updatedComponentElem };
};
export const udpateTableListElem = ({
  tableList,
  targatedTableID,
  tableColumnsUpdates,
}: {
  tableList: { [key: string]: any }[];
  targatedTableID: string;
  tableColumnsUpdates: { [key: string]: any };
}) => {
  let isTargetTableUpdated = false;
  const updateTableList = (tableList ?? []).map((tableElem: any, index: number) => {
    if (tableElem?.tableID === targatedTableID) {
      const { updatedTargetTable, isTargetTableUpdated: isTargetTableUpdatedInner } =
        updateTargetTableListData({
          selectedTargetTable: tableElem,
          tableColumnsUpdates,
        });
      if (isTargetTableUpdatedInner) {
        isTargetTableUpdated = true;
        return updatedTargetTable;
      }
    }
    return tableElem;
  });

  return { isTargetTableUpdated, updateTableList };
};
const updateTargetTableListData = ({
  selectedTargetTable,
  tableColumnsUpdates,
}: {
  selectedTargetTable: { [key: string]: any };
  tableColumnsUpdates: { [key: string]: any };
}) => {
  const addColumns = tableColumnsUpdates?.addColumns ?? [];
  const deleteColumns = tableColumnsUpdates?.deleteColumns ?? [];
  const updateColumns = tableColumnsUpdates?.updateColumns ?? [];
  const renameColumns = tableColumnsUpdates?.renameColumns ?? {};
  let selectedTargetTableToUpdate: any = { ...selectedTargetTable };
  let isTargetTableUpdated = false;

  // Handle Rename
  if (Object.keys(renameColumns ?? {})?.[0]) {
    const columnUpdatedData = renameColumnsInDefaultView({
      renameColumns,
      defaultView: selectedTargetTableToUpdate?.defaultView ?? [],
    });
    selectedTargetTableToUpdate = {
      ...selectedTargetTableToUpdate,
      defaultView: columnUpdatedData,
    };
    isTargetTableUpdated = true;
  }

  // Handle Delete
  if (deleteColumns?.[0]) {
    const columnUpdatedData = deleteColumnsInDefaultView({
      deleteColumns,
      defaultView: selectedTargetTableToUpdate?.defaultView ?? [],
    });

    selectedTargetTableToUpdate = {
      ...selectedTargetTableToUpdate,
      defaultView: columnUpdatedData,
    };
    isTargetTableUpdated = true;
  }

  // Handle Add
  if (addColumns?.[0]) {
    const columnUpdatedData = addColumnsInDefaultView({
      addColumns,
      defaultView: selectedTargetTableToUpdate?.defaultView ?? [],
    });
    selectedTargetTableToUpdate = {
      ...selectedTargetTableToUpdate,
      defaultView: columnUpdatedData,
    };
    isTargetTableUpdated = true;
  }

  // Handle Update
  if (updateColumns?.[0]) {
    const columnUpdatedData = updateColumnsInDefaultView({
      updateColumns,
      defaultView: selectedTargetTableToUpdate?.defaultView ?? [],
    });
    selectedTargetTableToUpdate = {
      ...selectedTargetTableToUpdate,
      defaultView: columnUpdatedData,
    };
    isTargetTableUpdated = true;
  }
  return { updatedTargetTable: selectedTargetTableToUpdate, isTargetTableUpdated };
};

const renameColumnsInDefaultView = ({
  renameColumns,
  defaultView,
}: {
  renameColumns: { [key: string]: any };
  defaultView: { [key: string]: any }[];
}) => {
  const updateColumnsDataInDefaultView = (defaultView ?? []).map((defaultViewElem) => {
    if (renameColumns[defaultViewElem.columnName]) {
      const selectedRenameColumnsData = renameColumns?.[defaultViewElem?.columnName];
      const finalDefaultViewData = {
        ...defaultViewElem,
        columnName: selectedRenameColumnsData ?? defaultViewElem?.columnName,
      };
      return finalDefaultViewData;
    }
    return defaultViewElem;
  });
  return updateColumnsDataInDefaultView;
};
const deleteColumnsInDefaultView = ({
  deleteColumns,
  defaultView,
}: {
  deleteColumns: { [key: string]: any };
  defaultView: { [key: string]: any }[];
}) => {
  const updateColumnsDataInDefaultView = (defaultView ?? []).filter((defaultViewElem) => {
    const isSkip = (deleteColumns ?? []).some((columnData: { [key: string]: any }) => {
      return columnData.columnName === defaultViewElem?.columnName;
    });
    return !isSkip;
  });
  return updateColumnsDataInDefaultView;
};
const addColumnsInDefaultView = ({
  addColumns,
  defaultView,
}: {
  addColumns: { [key: string]: any };
  defaultView: { [key: string]: any }[];
}) => {
  const updateColumnsDataInDefaultView = (addColumns ?? []).map(
    (columnData: { [key: string]: any }) => getDefaultViewElem({ columnData })
  );
  return [...(defaultView ?? []), ...updateColumnsDataInDefaultView];
};
const updateColumnsInDefaultView = ({
  updateColumns,
  defaultView,
}: {
  updateColumns: { [key: string]: any };
  defaultView: { [key: string]: any }[];
}) => {
  const updateColumnsDataInDefaultView = (defaultView ?? []).map((defaultViewElem) => {
    const updateColumnIndex = (updateColumns ?? []).findIndex(
      (columnData: { [key: string]: any }) => {
        return columnData.columnName === defaultViewElem?.columnName;
      }
    );
    if (updateColumnIndex >= 0) {
      const updateColumnData = updateColumns[updateColumnIndex];

      const defaultDataOfDefaultViewElem = getDefaultViewElem({ columnData: updateColumnData });
      const finalDefaultViewData = {
        ...defaultDataOfDefaultViewElem,
        filters: defaultViewElem?.filters ?? defaultDataOfDefaultViewElem?.filters ?? {},
        description:
          defaultViewElem?.description ?? defaultDataOfDefaultViewElem?.description ?? '',
      };
      return finalDefaultViewData;
    }
    return defaultViewElem;
  });
  return updateColumnsDataInDefaultView;
};
