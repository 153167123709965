import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useState } from 'react';
import { warningTypeObject } from './warningPopupData';
import {
  handleTableDelete,
  handleTableSave,
} from '../../util/ComponentUpdateFunctions/handleDatabaseUpdate';
import useUpdateTableSchema from 'lib/erp-data-table/hooks/useUpdateTableSchema';
import styles from './warningPopup.module.scss';
import './warningPopup.scss';
import { CircularProgress } from '@material-ui/core';
import RenderReadMDFile from './RenderReadMDFile';
import ReactDOM from 'react-dom';
interface warningPopupProps {
  warningPopupData: { [key: string]: any };
  handleCloseWarningPopup: Function;
  dataTableSchema: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
  tableUpdates: { [key: string]: any };
  appID: string;
  handleClose: Function;
}
const WarningPopup = ({
  warningPopupData,
  handleCloseWarningPopup,
  dataTableSchema,
  originalDataTableSchema,
  tableUpdates,
  appID,
  handleClose,
}: warningPopupProps) => {
  const updateTableSchema = useUpdateTableSchema();
  const affectedData = warningPopupData?.affectedData;
  const [warningLoading, setWarningLoading] = useState(false);
  const handleConfirm = () => {
    if (warningTypeObject.save.key === warningPopupData?.warningType) {
      handleTableSave({
        setWarningLoading,
        tableUpdates,
        originalDataTableSchema,
        dataTableSchema,
        appID,
        affectedData,
        updateTableSchema,
        handleClose,
        handleCloseWarningPopup,
      });
    }
    if (warningTypeObject.delete.key === warningPopupData?.warningType) {
      handleTableDelete({
        setWarningLoading,
        dataTableSchema,
        appID,
        affectedData,
        updateTableSchema,
        handleClose,
        handleCloseWarningPopup,
      });
    }
  };
  const openNewTab = () => {
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.document.write("<div id='new-tab-root'></div>");
      newTab.document.close();
      // Wait for the new tab to load before rendering React component
      setTimeout(() => {
        setTimeout(() => {
          const rootElement = newTab.document.getElementById('new-tab-root');
          if (rootElement) {
            ReactDOM.render(<RenderReadMDFile />, rootElement); // For React 17
          }
        }, 100);
      }, 100);
    }
  };
  return (
    <PopUpDivChild
      initialValue={warningPopupData.status}
      setPopUp={handleCloseWarningPopup}
      popupTitle={mls(
        `Are you sure you want to ${
          warningTypeObject?.[warningPopupData?.warningType as keyof typeof warningTypeObject]
            ?.title
        } this Table?`
      )}
      className='WarningPopup'
      // popupTitle={mls('Affected Components List')}
    >
      <div className={styles.WarningPopup}>
        <div className={styles.WarningPopupDiscription}>
          <div className={styles.WarningPopupDiscriptionText}>
            {mls(
              ` ${
                warningPopupData?.warningType === warningTypeObject.delete.key
                  ? 'Deleting'
                  : 'Updating'
              }  this table may cause changes in components and other tables.`
            )}
            {/* {mls(
              ` ${
                warningPopupData?.warningType === warningTypeObject.delete.key
                  ? 'Deleting'
                  : 'Updating'
              }  this table may cause changes in components and other tables. Please review the following list of affected components and tables before proceeding.`
            )} */}
          </div>
          <div className={styles.WarningPopupDiscriptionReadMore} onClick={() => openNewTab()}>
            {mls('Read more')}
          </div>
        </div>
        {/* <h5>{mls('Affected Components List')}</h5> */}
        <div className={styles.affectedDataDetailContainer}>
          {Object.keys(affectedData?.affectedComponent ?? {}).length > 0 ? (
            <div className={styles.affectedDataDetailElem}>
              <div className={styles.affectedDataDetailTitle}>
                {mls('Affected Components List')}
              </div>
              <ul>
                {Object.keys(affectedData?.affectedComponent ?? {}).map((componentKey) => {
                  const componentData = affectedData?.affectedComponent[componentKey];
                  return <li>{componentData?.name}</li>;
                })}
              </ul>
            </div>
          ) : null}
          {Object.keys(affectedData?.tableUpdates ?? {}).length > 0 ? (
            <div className={styles.affectedDataDetailElem}>
              <div className={styles.affectedDataDetailTitle}>{mls('Affected Table List')}</div>
              <ul>
                {Object.keys(affectedData?.tableUpdates ?? {}).map((componentKey) => {
                  const componentData = affectedData?.tableUpdates[componentKey];
                  if (componentData?.tableData?.tableType === 'junction') {
                    const columnSchema = componentData?.tableData?.columnSchema ?? [];
                    const targetTable1 = (columnSchema?.[0]?.name ?? '').slice(0, -3);
                    const targetTable2 = (columnSchema?.[1]?.name ?? '').slice(0, -3);
                    return (
                      <li
                        className={
                          styles?.[
                            `affectedDataDetailElem${
                              componentData?.action === 'UPDATE' ? 'CREATE' : componentData?.action
                            }`
                          ]
                        }
                      >
                        {mls('Junction Table between ')} <b>{targetTable1}</b> {mls('and')}{' '}
                        <b>{targetTable2}</b>{' '}
                        {/* {componentData?.tableData?.name ?? componentData?.tableID}  */}(
                        {componentData?.action === 'UPDATE' ? 'CREATE' : componentData?.action} )
                      </li>
                    );
                  }
                  return (
                    <li
                      className={
                        styles?.[
                          `affectedDataDetailElem${
                            componentData?.action === 'UPDATE' ? 'CREATE' : componentData?.action
                          }`
                        ]
                      }
                    >
                      {componentData?.tableData?.name ?? componentData?.tableID} (
                      {componentData?.action} )
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
        <div className={styles.confirmationButtonDiv}>
          <button
            className={`btn btn-sm  btn-primary mx-2 ${
              warningPopupData?.warningType === warningTypeObject.delete.key
                ? 'btn-light-danger'
                : ''
            }`}
            onClick={() => handleConfirm()}
          >
            {warningLoading ? (
              <>
                <CircularProgress color='inherit' size={10} />
                &nbsp;&nbsp;
              </>
            ) : null}
            {mls(
              warningTypeObject?.[warningPopupData?.warningType as keyof typeof warningTypeObject]
                ?.title ?? 'Save'
            )}
          </button>
          <button
            className='btn btn-sm  btn-light-primary mx-2'
            onClick={() => handleCloseWarningPopup()}
          >
            {mls('Cancel')}
          </button>
          {/* <button className='btn btn-sm  btn-primary btn-light-danger mx-2'>{mls('Cancel')}</button> */}
        </div>
      </div>
    </PopUpDivChild>
  );
};

export default WarningPopup;
