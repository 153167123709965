import { Accordion } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useState } from 'react';
import styles from './additionalSettings.module.scss';
import { mls } from 'lib/multilanguagesupport';
import SelectedColumnPopup from './SelectedColumnPopup';
import './additionalSettings.scss';
import { Form } from 'react-bootstrap';
import { MdDragIndicator, MdOutlineDragHandle } from 'react-icons/md';
import { specialDataType, specialDataTypeName } from '../../data/appComponentData';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { getFinalTableID } from '../../utils/componentSettingFunction';
interface relatatedTableListProps {
  appDatatable: any;
  relatedTableList: { [key: string]: any }[];
  setRelatedTableList: Function;
  filterData: any;
  relatedTable: { [key: string]: any };
  setFilterData: Function;
  relatedTableIndex: number;
  providedDrag?: any;
  connectedTablesData: any;
  finalTableID: string;
  updateRelatedTable: Function;
  nestedLevel: number;
  roleAccess: { [key: string]: any };
  setComponentSchema: Function;
}
const RelatatedTableList = ({
  appDatatable,
  relatedTableList,
  setRelatedTableList,
  filterData,
  setFilterData,
  relatedTable,
  relatedTableIndex,
  providedDrag = {},
  finalTableID,
  connectedTablesData,
  updateRelatedTable,
  nestedLevel,
  roleAccess,
  setComponentSchema,
}: relatatedTableListProps) => {
  const handleDragEnd = (result: any) => {
    const newTableList = Array.from(relatedTable?.tableList ?? []);
    const [selectedItem]: any[] = newTableList.splice(result.source.index, 1);
    newTableList.splice(result.destination.index, 0, selectedItem);
    console.log(newTableList);

    updateRelatedTable({
      ...relatedTable,
      tableList: newTableList,
    });
  };
  const handleInnerTableList = (newTableList: any) => {
    updateRelatedTable({
      ...relatedTable,
      tableList: newTableList,
    });
  };
  return (
    <div>
      <Accordion>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='RealtedTableListDroppableTable'>
            {(provided) => (
              <div
                className='accordion'
                id='kt_accordion_1'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {React.Children.toArray(
                  (relatedTable?.tableList ?? []).map(
                    (innerRelatedTable: { [key: string]: any }, innerRelatedTableIndex: number) => {
                      const innerFinalTableID = getFinalTableID({
                        tableID: innerRelatedTable?.tableID,
                        relationType: innerRelatedTable?.relationType,
                        relationID: innerRelatedTable?.relationID,
                      });

                      return (
                        <Draggable
                          key={innerFinalTableID}
                          draggableId={innerFinalTableID}
                          index={innerRelatedTableIndex}
                        >
                          {(providedDrag: any, _: any) => (
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              key={innerFinalTableID}
                            >
                              <ColumnDragableContainer
                                relatedTable={innerRelatedTable}
                                relatedTableIndex={innerRelatedTableIndex}
                                outerRelatedTableIndex={relatedTableIndex}
                                appDatatable={appDatatable}
                                setRelatedTableList={setRelatedTableList}
                                filterData={filterData}
                                finalTableID={innerFinalTableID}
                                outerFinalTableID={finalTableID}
                                setFilterData={setFilterData}
                                providedDrag={providedDrag}
                                relatedTableList={relatedTable?.tableList ?? []}
                                connectedTablesData={connectedTablesData}
                                handleInnerTableList={handleInnerTableList}
                                nestedLevel={nestedLevel}
                                roleAccess={roleAccess}
                                setComponentSchema={setComponentSchema}
                              />
                              {/* <hr /> */}
                              {/* <RelatatedTableList
                                    relatedTable={relatedTable}
                                    relatedTableIndex={relatedTableIndex}
                                    appDatatable={appDatatable}
                                    setRelatedTableList={setRelatedTableList}
                                    connectedTablesData={connectedTablesData}
                                    finalTableID={finalTableID}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    relatedTableList={relatedTableList}
                                    updateRelatedTable={updateRelatedTable}
                                  /> */}
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Accordion>
    </div>
  );
};

export default RelatatedTableList;

interface columnDragableContainerProps {
  appDatatable: any;
  relatedTableList: { [key: string]: any }[];
  setRelatedTableList: Function;
  filterData: any;
  relatedTable: { [key: string]: any };
  setFilterData: Function;
  relatedTableIndex: number;
  outerRelatedTableIndex: number;
  providedDrag?: any;
  connectedTablesData: any;
  outerFinalTableID: string;
  finalTableID: string;
  handleInnerTableList: Function;
  nestedLevel: number;
  roleAccess: { [key: string]: any };
  setComponentSchema: Function;
}
const ColumnDragableContainer = ({
  appDatatable,
  relatedTableList,
  setRelatedTableList,
  filterData,
  setFilterData,
  relatedTable,
  relatedTableIndex,
  outerRelatedTableIndex,
  providedDrag = {},
  finalTableID,
  connectedTablesData,
  handleInnerTableList,
  outerFinalTableID,
  nestedLevel,
  roleAccess,
  setComponentSchema,
}: columnDragableContainerProps) => {
  const tableID = relatedTable?.tableID;
  const tableData = appDatatable[tableID] ?? {};
  const defaultView = relatedTable?.defaultView ?? [];
  const updateRelatedTable = (
    updatedTableData: { [key: string]: any },
    updatedRelatedTableList = relatedTableList
  ) => {
    const reorderedTableListView = Array.from(updatedRelatedTableList);
    reorderedTableListView.splice(relatedTableIndex, 1);
    reorderedTableListView.splice(relatedTableIndex, 0, updatedTableData);
    handleInnerTableList(reorderedTableListView);
  };
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedDefaultView = Array.from(defaultView);
    const [movedScreen] = reorderedDefaultView.splice(result.source.index, 1);
    reorderedDefaultView.splice(result.destination.index, 0, movedScreen);
    updateRelatedTable({
      ...relatedTable,
      defaultView: reorderedDefaultView,
    });
  };
  const [isSelectedColumnPopup, setIsSelectedColumnPopup] = useState(false);
  const handleIsSelectedColumnPopup = () => {
    setIsSelectedColumnPopup(!isSelectedColumnPopup);
  };

  const handleScreenName = (e: any) => {
    updateRelatedTable({
      ...relatedTable,
      screenName: e.target.value,
    });
  };

  const handleDescription = (e: any) => {
    updateRelatedTable({
      ...relatedTable,
      description: e.target.value,
    });
  };

  const isSpecialDataType = specialDataType.some(
    (elem: string) => elem === relatedTable?.relationType
  );
  const selectedConnectedTable = connectedTablesData[finalTableID];

  return (
    <>
      <Accordion.Item eventKey={finalTableID} className='selectedColumnAccordion'>
        <Accordion.Header>
          <div className={styles.draggableItemHeader}>
            <div className={styles.dragIconOuter} {...(providedDrag?.dragHandleProps ?? {})}>
              <MdOutlineDragHandle className={styles.dragIcon} />
            </div>
            {tableData?.name ?? tableID} &nbsp; ({' '}
            {specialDataTypeName[relatedTable?.relationType as keyof typeof specialDataTypeName]
              ?.name ?? relatedTable?.relationType}{' '}
            ) &nbsp;
          </div>

          {isSelectedColumnPopup ? (
            <SelectedColumnPopup
              isSelectedColumnPopup={isSelectedColumnPopup}
              handleIsSelectedColumnPopup={handleIsSelectedColumnPopup}
              relatedTable={relatedTable}
              selectedConnectedTable={selectedConnectedTable}
              relatedTableList={relatedTableList}
              tableID={tableID}
              relationType={
                specialDataTypeName[relatedTable?.relationType as keyof typeof specialDataTypeName]
                  ?.name ?? relatedTable?.relationType
              }
              tableData={tableData}
              roleAccess={roleAccess}
              updateRelatedTable={updateRelatedTable}
              setRelatedTableList={setRelatedTableList}
              connectedTablesData={connectedTablesData}
              setComponentSchema={setComponentSchema}
            />
          ) : null}
        </Accordion.Header>
        <Accordion.Body>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='RealtedTableListDroppableColumn'>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '.5rem',
                    }}
                  >
                    {isSpecialDataType ? (
                      <>
                        <div className={styles.screenNameOuterContainer}>
                          <Form.Group
                            controlId='screenNameLabel'
                            className={styles.screenNameInputContainer}
                          >
                            <Form.Label className={styles.screenNameInputLabel}>
                              {relatedTable?.relationType === specialDataTypeName.belongsTo.key
                                ? mls('Column Display Name')
                                : mls('Screen Name')}
                              :
                            </Form.Label>
                            <Form.Control
                              type='text'
                              value={relatedTable?.screenName}
                              onChange={handleScreenName}
                            />
                          </Form.Group>
                          <hr className={styles.verticalLine} />
                          <Form.Group
                            controlId='screenDescription'
                            className={styles.screenNameInputContainer}
                          >
                            <Form.Label className={styles.screenNameInputLabel}>
                              {mls('Description')}:
                            </Form.Label>
                            <Form.Control
                              type='text'
                              value={relatedTable?.description ?? ''}
                              onChange={handleDescription}
                            />
                          </Form.Group>
                        </div>
                        <hr />
                      </>
                    ) : null}
                    {React.Children.toArray(
                      (defaultView ?? []).map((defaultViewElem: any, positonValue: number) => {
                        const columnName = defaultViewElem?.columnName;
                        const columnData = (tableData?.columnSchema ?? []).find(
                          (columnNameElem: { [key: string]: any }) =>
                            columnNameElem?.columnName === columnName
                        );

                        const handleFilter = ({
                          defaultViewElem,
                          columnName,
                          tableID,
                        }: {
                          defaultViewElem: any;
                          columnName: string;
                          tableID: string;
                        }) => {
                          setFilterData({
                            ...filterData,
                            popupStatus: true,
                            filters: defaultViewElem?.filters ?? {},
                            columnName: columnName,
                            tableID: tableID,
                            finalTableID: finalTableID,
                            tableData: {
                              isInnerTable: true,
                              outerTableID: outerFinalTableID,
                              innerTableIndex: relatedTableIndex,
                            },
                            relatedTableIndex: outerRelatedTableIndex,
                          });
                        };
                        const filterLength = ({ defaultViewElem }: { defaultViewElem: any }) => {
                          const filterLength = Object.keys(defaultViewElem?.filters ?? {})?.length;

                          if (filterLength > 0) {
                            return <div className={styles.filterNumber}>{filterLength}</div>;
                          }
                          return '';
                        };
                        const isDragDisabled = defaultViewElem?.columnName === 'id';
                        const handleDescription = (e: any) => {
                          const reorderedDefaultView = Array.from(defaultView);
                          const [movedScreen]: any[] = reorderedDefaultView.splice(positonValue, 1);
                          reorderedDefaultView.splice(positonValue, 0, {
                            ...movedScreen,
                            description: e.target.value,
                          });

                          updateRelatedTable({
                            ...relatedTable,
                            defaultView: reorderedDefaultView,
                          });
                        };
                        const handleName = (e: any) => {
                          const reorderedDefaultView = Array.from(defaultView);
                          const [movedScreen]: any[] = reorderedDefaultView.splice(positonValue, 1);
                          reorderedDefaultView.splice(positonValue, 0, {
                            ...movedScreen,
                            name: e.target.value,
                          });

                          updateRelatedTable({
                            ...relatedTable,
                            defaultView: reorderedDefaultView,
                          });
                        };
                        const handleColumnDisable = (e: any) => {
                          const reorderedDefaultView = Array.from(defaultView);
                          const [movedScreen]: any[] = reorderedDefaultView.splice(positonValue, 1);
                          reorderedDefaultView.splice(positonValue, 0, {
                            ...movedScreen,
                            columnDisabled: !e,
                          });

                          updateRelatedTable({
                            ...relatedTable,
                            defaultView: reorderedDefaultView,
                          });
                        };
                        if (isDragDisabled) {
                          return (
                            <div>
                              <div className={styles.draggableItemOuter}>
                                <div className={styles.dragIconOuter}>
                                  <MdDragIndicator className={styles.dragIcon} />
                                </div>
                                <div className={styles.draggableItem}>
                                  <div
                                    className={styles.draggableItemTitle}
                                    title={columnData?.name ?? columnName}
                                  >
                                    {columnData?.name ?? columnName}
                                  </div>
                                  <div className={styles.draggableItemNameOuterContainer}>
                                    <div className={styles.draggableItemDescription}>
                                      <Form.Group
                                        controlId='screenDescription'
                                        className={styles.draggableItemDescriptionInput}
                                      >
                                        <Form.Label
                                          className={styles.draggableItemDescriptionLabel}
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {mls('Show By Default')}:
                                        </Form.Label>
                                        <CheckBox
                                          // checked={!relatedTable?.tableDisabled}
                                          checked={!defaultViewElem?.columnDisabled}
                                          onClick={handleColumnDisable}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className={styles.draggableItemDescription}>
                                      <Form.Group
                                        controlId='screenDescription'
                                        className={styles.draggableItemDescriptionInput}
                                      >
                                        <Form.Label
                                          className={styles.draggableItemDescriptionLabel}
                                        >
                                          {mls('Name')}:
                                        </Form.Label>
                                        <Form.Control
                                          type='text'
                                          // placeholder={mls('Column Description')}
                                          value={defaultViewElem?.name ?? ''}
                                          onChange={handleName}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className={styles.draggableItemDescription}>
                                      <Form.Group
                                        controlId='screenDescription'
                                        className={styles.draggableItemDescriptionInput}
                                      >
                                        <Form.Label
                                          className={styles.draggableItemDescriptionLabel}
                                        >
                                          {mls('Description')}:
                                        </Form.Label>
                                        <Form.Control
                                          type='text'
                                          // placeholder={mls('Column Description')}
                                          value={defaultViewElem?.description ?? ''}
                                          onChange={handleDescription}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.filterIconContainer}>
                                  <button
                                    className='primaryButton secondaryButtonColor'
                                    onClick={() => {
                                      handleFilter({
                                        defaultViewElem,
                                        columnName,
                                        tableID,
                                      });
                                    }}
                                  >
                                    {mls('Filter')}
                                  </button>
                                  {filterLength({ defaultViewElem })}
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div>
                            <Draggable
                              key={columnName}
                              draggableId={columnData?.columnName}
                              index={positonValue}
                            >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <div className={styles.draggableItemOuter}>
                                    <div
                                      {...provided.dragHandleProps}
                                      className={styles.dragIconOuter}
                                    >
                                      <MdDragIndicator className={styles.dragIcon} />
                                    </div>
                                    <div className={styles.draggableItem}>
                                      <div className={styles.draggableItemTitle}>
                                        {columnData?.name ?? columnName} ( {columnData?.dataType} )
                                      </div>

                                      <div className={styles.draggableItemNameOuterContainer}>
                                        <div className={styles.draggableItemDescription}>
                                          <Form.Group
                                            controlId='screenDescription'
                                            className={styles.draggableItemDescriptionInput}
                                          >
                                            <Form.Label
                                              className={styles.draggableItemDescriptionLabel}
                                              style={{ whiteSpace: 'nowrap' }}
                                            >
                                              {mls('Show By Default')}:
                                            </Form.Label>
                                            <CheckBox
                                              // checked={!relatedTable?.tableDisabled}
                                              checked={!defaultViewElem?.columnDisabled}
                                              onClick={handleColumnDisable}
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className={styles.draggableItemDescription}>
                                          <Form.Group
                                            controlId='screenDescription'
                                            className={styles.draggableItemDescriptionInput}
                                          >
                                            <Form.Label
                                              className={styles.draggableItemDescriptionLabel}
                                            >
                                              {mls('Name')}:
                                            </Form.Label>
                                            <Form.Control
                                              type='text'
                                              // placeholder={mls('Column Description')}
                                              value={defaultViewElem?.name ?? ''}
                                              onChange={handleName}
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className={styles.draggableItemDescription}>
                                          <Form.Group
                                            controlId='screenDescription'
                                            className={styles.draggableItemDescriptionInput}
                                          >
                                            <Form.Label
                                              className={styles.draggableItemDescriptionLabel}
                                            >
                                              {mls('Description')}:
                                            </Form.Label>
                                            <Form.Control
                                              type='text'
                                              // placeholder={mls('Column Description')}
                                              value={defaultViewElem?.description ?? ''}
                                              onChange={handleDescription}
                                            />
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.filterIconContainer}>
                                      {filterLength({ defaultViewElem })}
                                      <button
                                        className='primaryButton secondaryButtonColor'
                                        onClick={() => {
                                          handleFilter({
                                            defaultViewElem,
                                            columnName,
                                            tableID,
                                          });
                                        }}
                                      >
                                        {mls('Filter')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })
                    )}
                    {/* TableList */}

                    {provided.placeholder}
                  </div>
                  {nestedLevel > 0 ? (
                    <>
                      <hr />
                      <RelatatedTableList
                        relatedTable={relatedTable}
                        relatedTableIndex={relatedTableIndex}
                        appDatatable={appDatatable}
                        setRelatedTableList={setRelatedTableList}
                        connectedTablesData={connectedTablesData}
                        finalTableID={finalTableID}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        relatedTableList={relatedTableList}
                        roleAccess={roleAccess}
                        setComponentSchema={setComponentSchema}
                        updateRelatedTable={updateRelatedTable}
                        nestedLevel={nestedLevel - 1}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!defaultView?.[0] ? `${mls('No column selected please select any column')}!` : null}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};
