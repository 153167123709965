/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import { KTSVG } from '../../../helpers';
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
type Props = {
  appID: string;
};

export const AsideMenuMain: React.FC<Props> = ({ appID }) => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      {/* <AsideMenuItem to='/builder' title='Roles & Settings Groups' fontIcon='bi-layers' />
      <AsideMenuItem to='/##' title='Organization Structure' fontIcon='bi-layers' /> */}
      <AsideMenuItemWithSub to={`/${appID}/settings`} title='Settings' hasBullet={false}>
        <AsideMenuItem
          to={`/${appID}/settings/general`}
          title='General Settings'
          hasBullet={true}
        />
        <AsideMenuItem to={`/${appID}/settings/assets`} title='Assets Settings' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem to={`/${appID}/modules`} title='Modules' fontIcon='bi-layers' />
      {/* <AsideMenuItem to={`/${appID}/manage-users`} title='Manage Users' fontIcon='bi-layers' /> */}
      <AsideMenuItem to={`/${appID}/data-table`} title='Data Table' fontIcon='bi-layers' />
      <AsideMenuItem to={`/${appID}/components`} title='Components' fontIcon='bi-layers' />
      {/* <AsideMenuItem to={`/${appID}/buttons`} title='Buttons' fontIcon='bi-layers' /> */}
      {/* <AsideMenuItem to={`/${appID}/access-group`} title='Access Group' fontIcon='bi-layers' /> */}
      <AsideMenuItemWithSub to={`/${appID}/role-access`} title='Teams & Users' hasBullet={false}>
        <AsideMenuItem to={`/${appID}/role-access/users`} title='Users' hasBullet={true} />
        <AsideMenuItem to={`/${appID}/role-access/teams`} title='Teams' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to={`/${appID}/mls`} title='Multi-Language Support' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>
      {/* <AsideMenuItem to='/##' title='Help' fontIcon='bi-layers' />
      <AsideMenuItem to='/##' title='Documentation' fontIcon='bi-layers' />
      <AsideMenuItem to='/##' title='Blog' fontIcon='bi-layers' />
      <AsideMenuItem to='/##' title='Contact Us' fontIcon='bi-layers' /> */}
      {/* -----break----- */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  );
};
