/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import * as auth from '../../modules/auth/redux/AuthRedux';
import { Link } from 'react-router-dom';
import { countries } from './countries';
import './auth.css';
import { mls } from 'lib/multilanguagesupport';
import TnCModal from './TnCModal';
import { useHistory } from 'react-router';
// import { supistaApiPost } from './network/connections';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
// import useSampleDatabase from './useSampleDatabase';
import { _supistaApiPost } from 'lib/server-connection/connections';
import './passwordField.css';
import useSupistaDatabase from './useSupistaDatabase';
// import companyInitialQuestions from './AI_ERP_Automation/companyInitialQuestions';
// import { CompanyDetails } from './AI_ERP_Automation/CompanyDetails';
// import { getCountryFromIP } from './getCountryFromIP';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changepassword: '',
  companyName: '',
  country: 'United States',
  companyurlid: '',
  companysize: '',
  phone: '(+1) ',
  acceptTerms: false,
};

const companysize = ['Less than 10', '10 - 50', '50 - 500', '500+'];

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  password: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password too short')
    .max(24, 'Password can be at most 24 characters')
    .test(
      'Password is strong',
      'Password must contain uppercase character, lowercase charcater, digit and special character',
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbole = /[^A-Za-z0-9]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        conditions.forEach((condition) => (condition ? validConditions++ : null));
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  country: Yup.string().required('Country is required'),
  companyName: Yup.string().required('Company name is required'),
  companyurlid: Yup.string(),
  companysize: Yup.string().required('Company size is required').oneOf(companysize),
  phone: Yup.string()
    .typeError('Enter valid mobile number')
    .min(10, 'Enter valid mobile number')
    .required('Mobile number is required')
    .test('Valid', 'Enter valid mobile number', (value, context) => {
      const mobileNumberRegex = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/;
      if (mobileNumberRegex.test(value)) return true;
      else return false;
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export function Registration() {
  const appID = 'supista';
  const companyNamecounter = useRef(0);
  const [loading, setLoading] = useState(false);
  const [nameAvailable, setNameAvailable] = useState(null);
  // const [companyname, setCompanyname] = useState('');
  const [passordShow, setPasswordShow] = useState(false);
  const [tncModalIsOpen, setTncModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  // const [signUpStep, setSignupStep] = useState(0);
  // const [companyInitialDetails, setCompanyInitialDetails] = useState(companyInitialQuestions);
  // const [companyAIDetails, SetCompanyAIDetails] = useState(companyInitialQuestions);
  // const [loginRes, setLoginRes] = useState(null);
  // const [appName, setAppName] = useState(null);

  const history = useHistory();

  // const setSampleDatabase = useSampleDatabase();
  const addSupistaDatabase = useSupistaDatabase();

  const handleTnCModalClose = () => {
    setTncModalIsOpen(false);
  };

  // const handleCompanyDetailsSubmit = (values) => {
  //   setCompanyInitialDetails(values);
  //   setLoading(true);
  //   const data = {
  //     data: {
  //       queryType: 'AISchema',
  //       promptType: 'moduleQnA',
  //       appName: appName,
  //       appArea: companyInitialDetails[0].Answer,
  //       moduleName: companyInitialDetails[1].Answer,
  //       modulePurpose: companyInitialDetails[2].Answer,
  //     },
  //   };
  //   _supistaApiPost(`aiQuery/${appName}`, data)
  //     .then((res) => {
  //       if (!res?.__d3__error) {
  //         SetCompanyAIDetails(res);
  //         setSignupStep(2);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       history.replace('/auth/login#');
  //       setSignupStep(0);
  //     });
  // };

  // const handleAICompanyDetailsSubmit = (values) => {
  //   setLoading(true);
  //   const data = {
  //     data: {
  //       queryType: 'AISchema',
  //       promptType: 'generateTable',
  //       appName: appName,
  //       appArea: companyInitialDetails[0].Answer,
  //       moduleName: companyInitialDetails[1].Answer,
  //       modulePurpose: companyInitialDetails[2].Answer,
  //       moduleQnA: values,
  //     },
  //   };
  //   _supistaApiPost(`aiQuery/${appName}`, data)
  //     .then((res) => {
  //       if (!res.__d3__error) {
  //         // all the steps are done and now setting details saved from login.
  //         dispatch(
  //           create({
  //             setPath: reduxConstants.config.LOGIN,
  //             // settingup is new user as true.
  //             value: {
  //               ...loginRes.response,
  //               isLoggedin: loginRes.response?.isLoggedin || false,
  //               isNewUser: true,
  //             },
  //           })
  //         );
  //         dispatch(
  //           create({
  //             setPath: reduxConstants.config.USER_SCHEMA,
  //             value: loginRes.response,
  //           })
  //         );
  //         loginRes.response?.appsConnected?.[0] &&
  //           addSupistaDatabase(loginRes.response?.appsConnected?.[0]);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       history.replace('/auth/login#');
  //       setSignupStep(0);
  //     });
  // };

  const createCompanyUrlID = (name) => {
    const uuid = nanoid(15);
    const companyUrl = `${name}${uuid}`.replace(/[^a-zA-Z0-9]/g, '');
    _supistaApiPost(`auth/${appID}/checkAppName`, {
      data: {
        appName: companyUrl,
      },
    })
      .then((res) => {
        companyNamecounter.current += 1;
        const isAvailable = res.response.appNameAvailable ? true : false;
        setNameAvailable(isAvailable);
      })
      .catch((err) => {
        if (companyNamecounter.current <= 5) createCompanyUrlID(name);
        setNameAvailable(false);
      });
    return companyUrl;
  };

  const loginUser = (values) => {
    const data = {
      data: {
        emailID: values.email,
        password: values.password,
      },
    };

    _supistaApiPost(`auth/${appID}/login`, data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        if (res.hasOwnProperty('__d3__success')) {
          dispatch(
            create({
              setPath: reduxConstants.config.LOGIN,
              value: {
                ...res.response,
                isLoggedin: res.response?.isLoggedin || false,
              },
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.USER_SCHEMA,
              value: res.response,
            })
          );

          // setLoginRes(res);
          // setSignupStep(1);
          // res.response?.appsConnected?.[0] && setSampleDatabase(res.response?.appsConnected?.[0]);
          // dispatch(auth.actions.login('access-token-8f3ae836da744329a6f93bf20594b5cc'));
        } else {
          history.replace('/auth/login');
        }
      })
      .catch(() => {
        setLoading(false);
        history.replace('/auth/login');
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const newAppName = values.companyurlid.replaceAll(' ', '').toLowerCase();
      // setAppName(newAppName);

      const data = {
        data: {
          emailID: values.email,
          country: values.country,
          companyName: values.companyName,
          appName: newAppName,
          password: values.password,
          lastName: values.lastName,
          firstName: values.firstName,
          phone: values.phone,
        },
      };

      _supistaApiPost(`auth/${appID}/signup`, data)
        .then((response) => {
          setLoading(false);
          if (response.hasOwnProperty('__d3__success')) {
            // add a team with Admin access on successful signup
            // const currentTimestamp = Date.now();
            // let userDetail = [];
            // if (response?.response?.userID) {
            //   // userDetail = [response?.response?.userID, values.email, values.email];
            // }
            // const AdminTeamData = {
            //   name: 'Admin Access',
            //   desc: 'Grants the user Admin Access',
            //   privilege: '',
            //   TABLESLIST: {},
            //   usersList: [userDetail],
            //   appID: newAppName,
            //   teamID: 'Admin',
            //   createdAt: currentTimestamp,
            //   updatedAt: currentTimestamp,
            // };
            // _supistaAnalyticsApi(`${newAppName}/TeamSettings/create`, AdminTeamData);
            // Login functionality
            loginUser(values);
            addSupistaDatabase(newAppName);
          } else {
            setSubmitting(false);
            setStatus(response.error);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setStatus('The login detail is incorrect ');
        });
    },
  });

  const handleCompanyUrl = (name) => {
    const companyUrl = createCompanyUrlID(name);
    formik.setFieldValue('companyurlid', companyUrl);
  };

  return (
    <>
      <TnCModal tncModalIsOpen={tncModalIsOpen} handleTnCModalClose={handleTnCModalClose} />
      {
        // signUpStep === 0 ?
        <form
          className='form w-100'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='mb-10 text-center'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>{mls('Create an Account')}</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              {mls('Already have an account?')}
              <Link
                to='/auth/login'
                className='link-primary fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                {mls('Login')}
              </Link>
            </div>
            {/* end::Link */}
          </div>
          {/* end::Heading */}

          <div className='d-flex align-items-center mb-10'>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            <span className='fw-bold text-gray-400 fs-7 mx-2'>{mls('OR')}</span>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          </div>

          {formik.status && (
            <div className='mb-10 pt-6 alert alert-danger'>
              <ul>
                {Object.keys(formik.status).map((err, key) => (
                  <li key={key} className='alert-text font-weight-bold'>
                    {mls(formik.status[err])}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* begin::Form group company name */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{mls('Company Name')}</label>
            <input
              placeholder='Company Name'
              type='text'
              autoComplete='off'
              onChange={(e) => {
                formik.values.companyName = e.target.value;
                // if (!formik.values.companyurlid)
                //   formik.values.companyurlid = e.target.value.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase();
                // setCompanyname(e.target.value);
              }}
              onBlur={(e) => {
                handleCompanyUrl(e.target.value);
                // if (formik.values.companyurlid.length === 0) {
                //   formik.values.companyurlid = formik.values.companyName
                //     .replaceAll(/[^a-zA-Z0-9]/g, '')
                //     .toLowerCase();
                // }
                // checkName(formik.values.companyurlid.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase());
                // setCompanyname(e.target.value);
              }}
              className='form-control form-control-lg form-control-solid'
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {mls(formik.errors.companyName)}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group company url id */}

          {/* end::Form group */}

          {/* begin::Form group company size */}
          <div className='mb-5 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>{mls('Company Size')}</label>
              <div className='position-relative mb-3'>
                <select
                  {...formik.getFieldProps('companysize')}
                  className='col-6 form-control form-control-lg form-control-solid'
                >
                  <option hidden selected={true}>
                    Select the company size
                  </option>
                  <option value='Less than 10'>{mls('Less than 10')}</option>
                  <option value='10 - 50'>{mls('10 - 50')}</option>
                  <option value='50 - 500'>{mls('50 - 500')}</option>
                  <option value='500+'>{mls('500+')}</option>
                </select>
                {formik.touched.companysize && formik.errors.companysize && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {mls(formik.errors.companysize)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Firstname */}
          <div className='row fv-row'>
            <div className='col-xl-6 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 mb-2'>
                {mls('First name')}
              </label>
              <input
                placeholder='First Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstName')}
                className='form-control form-control-lg form-control-solid'
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {mls(formik.errors.firstName)}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xl-6 mb-5'>
              {/* begin::Form group Lastname */}
              <div className='fv-row'>
                <label className='form-label fw-bolder text-dark fs-6'>{mls('Last name')}</label>
                <input
                  placeholder='Last Name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastName')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {mls(formik.errors.lastName)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group country */}
          <div className='mb-5 fv-row'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>{mls('Country')}</label>
              <div className='position-relative mb-3'>
                <select
                  {...formik.getFieldProps('country')}
                  className='col-6 form-control form-control-lg form-control-solid'
                  onChange={(e) => {
                    formik.setFieldValue('country', e.target.value);
                    formik.setFieldValue(
                      'phone',
                      '(' +
                        countries.find((countryData) => countryData.name === e.target.value)
                          ?.dial_code +
                        ') '
                    );
                  }}
                >
                  <option hidden>Select country</option>
                  {countries.map((country, key) => (
                    <option key={key} name={country.name} value={country.name}>
                      {mls(country.name)}
                    </option>
                  ))}
                </select>
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {mls(formik.errors.country)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group mobile number */}
          <div className='mb-5 fv-row'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>{mls('Mobile Number')}</label>
              <div className='position-relative mb-3'>
                {/* <input type='number' onBlur={e=>formik.values.phone=e.target.value} className='col-6 w-25 text-center form-control form-control-lg form-control-solid'/> */}

                <input
                  type='tel'
                  autoComplete='off'
                  placeholder='Mobile Number'
                  pattern='[0-9]{10}' // Regular expression to enforce 10 digits format
                  {...formik.getFieldProps('phone')}
                  className='col-6 form-control form-control-lg form-control-solid'
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {mls(formik.errors.phone)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Email */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{mls('Email')}</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='email'
              {...formik.getFieldProps('email')}
              className='form-control form-control-lg form-control-solid'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {mls(formik.errors.email)}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>{mls('Password')}</label>
              <div className='position-relative mb-3 password-input-container'>
                <input
                  placeholder='Password'
                  type={passordShow ? 'text' : 'password'}
                  autoComplete='new-password'
                  {...formik.getFieldProps('password')}
                  className='form-control form-control-lg form-control-solid'
                />
                <i
                  className={`bi ${passordShow ? 'bi-eye-slash' : 'bi-eye'} password-toggle`}
                  id='togglePassword'
                  onClick={() => setPasswordShow(!passordShow)}
                ></i>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-danger' role='alert'>
                        {mls(formik.errors.password)}
                      </span>
                    </div>
                  </div>
                )}
                {formik.touched.password && !formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-success' role='alert'>
                        {mls('Password is strong')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-5'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                {mls('I Agree the')}{' '}
                <Link
                  to='#'
                  style={{ zIndex: 999 }}
                  onClick={() => {
                    setTncModalIsOpen(true);
                  }}
                  className='ms-1 link-primary'
                >
                  {mls('terms and conditions')}
                </Link>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {mls(formik.errors.acceptTerms)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={!formik.values.acceptTerms || !nameAvailable}
            >
              {!loading && <span className='indicator-label'>{mls('Submit')}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {mls('Please wait...')}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
              onClick={(e) => {
                e.preventDefault();
                history.push('/auth/login');
              }}
            >
              {mls('Cancel')}
            </button>
          </div>
          {/* end::Form group */}
        </form>
        // : signUpStep === 1 ? (
        //   <CompanyDetails
        //     key='companyDetails'
        //     handleSubmit={handleCompanyDetailsSubmit}
        //     questionAnswer={companyInitialDetails}
        //     heading='Tell us more about your company'
        //     isLoading={loading}
        //   />
        // ) : (
        //   <CompanyDetails
        //     key='AIDetails'
        //     handleSubmit={handleAICompanyDetailsSubmit}
        //     questionAnswer={companyAIDetails}
        //     heading='Almost Ready! A bit more on what you wanna Build.'
        //     isLoading={loading}
        //   />
        // )
      }
    </>
  );
}
