module.exports = {
  'No App ID Defined': 'No App ID Defined',
  'Can not create new location to store your Files. Please try again later.': 'Can not create new location to store your Files. Please try again later.',
  'New location to store your Files have been Created. Please try again to store files now :)': 'New location to store your Files have been Created. Please try again to store files now :)',
  'BAD REQUEST': 'You are making a bad request! Please reload this webpage or app. If this problem persists, please email us at support@supista.com with screenshots and steps to replicate.',
  UNAUTHORIZED: 'You are Unauthorized to access this resource! Kindly contact your IT department to give you permission if you think its an error.',
  FORBIDDEN: 'You do not have access to this request.',
  'INTERNAL SERVER ERROR': 'Some nasty error occurred to the server. We are working to mitigate this issue as soon as possible. Please try again after sometime. If this error persists, kindly contact us at support@supista.com with screenshots and steps to replicate.',
  'SERVICE UNAVAILABLE': 'The service your are looking for is not available at the moment, please try again later!',
  'NOT FOUND': 'The resource you are looking for is not found.',
  'No app ID Defined!': 'No app ID Defined!',
  'Can not parse the JSON request. Make sure the request is in JSON format.': 'Can not parse the JSON request. Make sure the request is in JSON format.',
  'Company Name is a Required Field.': 'Company Name is a required Field.',
  'Company Name must be at least 3 characters': 'Company Name must be at least 3 characters',
  'Email ID is a Required Field.': 'Email ID is a Required Field.',
  'Invalid Email ID.': 'Invalid Email ID.',
  'Password is a Required Field.': 'Password is a Required Field.',
  'Please enter a strong password of at least 8 digits long.': 'Please enter a strong password of at least 8 digits long.',
  'Some error occured': 'Some error occured',
  'App Name must be a mixture of English lowercase Alphabets and Numbers!': 'Company URL ID must be a mixture of English lowercase Alphabets and Numbers!',
  'App Name must be at least 3 characters': 'Company URL ID must be at least 3 characters',
  'App Name is a Required Field.': 'Company URL ID is a Required Field.',
  'Country is a Required Field.': 'Country is a Required Field.',
  'App Name must be unique!': 'Company URL ID must be unique!',
  UnexpectedError: 'An unexpected error occured! Please try after some time.',
  'Your email ID has already been registered! Please Login to create a new App.': 'Your email ID has already been registered! Please Login to create a new App.',
  'Email Id or Password did not match!': 'Email Id or Password did not match!',
  'You will receive an Email containing the OTP to your registered Email ID.': 'You will receive an Email containing the OTP to your registered Email ID.',
  'Could not verify OTP.': 'Could not verify OTP.',
  'OTP Expired! Please try again later.': 'OTP Expired! Please try again later.',
  'New Password is a Required Field.': 'New Password is a Required Field.',
  'Confirm New Password is a Required Field.': 'Confirm New Password is a Required Field.',
  'Passwords did not Match.': 'Passwords did not Match.',
  'Could not update Password. Please re-verify OTP.': 'Could not update Password. Please re-verify OTP.',
  'Password Updated Successfully.': 'Password Updated Successfully.',
  'User is not logged in. Please login to update password or click on forgot password link in signup page.': 'User is not logged in. Please login to update password or click on forgot password link in signup page.',
  'User Not Logged in.': 'User Not Logged in.',
  'secretLoginKey is a Required Field.': 'secretLoginKey is a Required Field.',
  'ITStakeholder must be an array.': 'ITStakeholder must be an array.',
  'Role must be an array.': 'Role must be an array.',
  'Schema Object updated successfully.': 'Schema Object updated successfully.',
  'Invalid App ID.': 'Invalid App ID.',
  'App successfully published.': 'App successfully published.',
  'App is being updated| Please try again letter': 'App is being updated| Please try again letter',

  'No Data found to update': 'No Data found to update',
  'No such user found.': 'No such user found.',
  InvalidResourceUpdate: 'You are updating one or more resource(s) which you don\'t have access to!',
  'Please provide a columnName to find distinct values!': 'Please provide a columnName to find distinct values!',

  // version 2.
  'Current Password is a Required Field.': 'Current Password is a Required Field.',
  'Email Id does not exist!': 'Email Id does not exist!',
  'Current Password did not match!': 'Current Password did not match!',
  'Key of item not defined! Can not delete this item.': 'Key of item not defined! Can not delete this item.',
  'Your email ID has already been registered! Please Login.': 'This email ID has already been registered. Kindly go to Login!',
  'You don\'t have sufficient rights to perform this action!': 'You don\'t have sufficient rights to perform this action!',
  'You do not have the necessary authorization. Logging out...': 'You do not have the necessary authorization. Logging out...',
  'OTP cannot be sent as your email address is not registered. Please register/sign-up in the app first.': 'OTP cannot be sent as your email address is not registered. Please register/sign-up in the app first.',
};
