import { mls } from 'lib/multilanguagesupport';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import { _supistaApiPost } from 'lib/server-connection/connections';
import React, { useState } from 'react';

const AIGenerateModule = ({ showModal, setShowModal, appID }) => {
  const [moduleInstructions, setModuleInstructions] = useState('');
  const [tableInstructions, setTableInstructions] = useState('');
  const [loading, setLoading] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = () => {
    const requestBody = {
      moduleInstructions,
      tableInstructions,
    };
    console.log(requestBody, 'requestBody');

    const data = {
      data: {
        queryType: 'AISchema',
        promptType: 'generateModules',
        appName: appID,
        moduleInstructions,
        tableInstructions,
      },
    };

    setLoading(true);

    _supistaApiPost(`aiQuery/${appID}`, data)
      .then(async (res) => {
        if (!res.__d3__error) {
          // all the steps are done and now setting details saved from login.
          await publishToProduction(appID);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className='container mt-4'>
      <button className='btn btn-primary' onClick={handleOpenModal}>
        AI ERP Module
      </button>

      {showModal && (
        <div className='modal fade show d-block' tabIndex='-1' onClick={handleCloseModal}>
          <div className='modal-dialog modal-xl' onClick={(e) => e.stopPropagation()}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>AI-Powered ERP Module Creator</h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <div className='modal-body'>
                <p className='mb-3 fw-bold text-primary text-center'>
                  Provide details for the module and its database structure. AI will generate the
                  module, submodules, tables, and relationships based on your instructions.
                </p>
                <div className='mb-3'>
                  <label className='form-label'>Module Instructions</label>
                  <textarea
                    className='form-control'
                    rows='4'
                    value={moduleInstructions}
                    onChange={(e) => setModuleInstructions(e.target.value)}
                    placeholder='Enter the module name and description...'
                  ></textarea>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Table Instructions</label>
                  <textarea
                    className='form-control'
                    rows='8'
                    value={tableInstructions}
                    onChange={(e) => setTableInstructions(e.target.value)}
                    placeholder={mls('Enter the tables, columns, and relationships...')}
                  ></textarea>
                </div>
                <div className='text-center'>
                  <button className='btn btn-sm btn-primary' onClick={handleSubmit}>
                    {mls('Generate Module')}
                    {loading && (
                      <span
                        className='spinner-border spinner-border-sm ms-2'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIGenerateModule;
