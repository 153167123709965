import {
  getFinalTableID,
  getSelectTableFinalKey,
} from 'lib/appComponent/componentSettings/utils/componentSettingFunction';

export const udpateRoleAccessListElem = ({
  roleAccess,
  targetedTableElem,
  tableColumnsUpdates,
  tableList,
  tableListFilter = true,
}: {
  roleAccess: { [key: string]: any };
  tableList: { [key: string]: any }[];
  targetedTableElem: { [key: string]: any };
  tableColumnsUpdates: { [key: string]: any };
  tableListFilter?: boolean;
}) => {
  const targatedTableID = targetedTableElem?.tableID;
  const originalDataTableSchema = targetedTableElem?.originalDataTableSchema;

  let isTargetTeamUpdated = false;
  let updatedRoleAccessData = {};

  Object.keys(roleAccess ?? {}).forEach((roleAccessKey) => {
    const roleAccessElem = roleAccess[roleAccessKey];
    const splitBeforeTilde = (input: string) => {
      return input.split('~')[0];
    };
    const tableID = splitBeforeTilde(roleAccessKey);
    const selecteTableList =
      (tableList ?? [])?.find((tableListElem: any) => {
        const finalTableID = getSelectTableFinalKey({
          tableId: tableListElem?.tableID,
          innerRelationID: tableListElem?.innerRelationID,
          relationID: tableListElem?.relationID,
          parentRelationIDList: tableListElem?.parentRelationIDList,
        });

        return roleAccessKey === finalTableID;
      }) ?? {};
    if (tableID === targatedTableID) {
      let { updatedRoleAccesssElem, isTargetTeamUpdated: isInnerTargetTeamUpdated } =
        updateRoleAccessElem({
          roleAccessElem,
          tableColumnsUpdates,
          originalDataTableSchema,
          selecteTableList,
        });
      if (isInnerTargetTeamUpdated) {
        isTargetTeamUpdated = true;
        updatedRoleAccessData = {
          ...updatedRoleAccessData,
          [roleAccessKey]: updatedRoleAccesssElem,
        };
        return;
      }
    }

    const isRoleAcessElemPresentInTableList = getIsRoleAcessElemPresentInTableList({
      tableList,
      roleAccessKey,
    });
    if (tableListFilter) {
      if (isRoleAcessElemPresentInTableList.isPresent) {
        updatedRoleAccessData = {
          ...updatedRoleAccessData,
          [roleAccessKey]: roleAccessElem,
        };
      }
    } else {
      updatedRoleAccessData = {
        ...updatedRoleAccessData,
        [roleAccessKey]: roleAccessElem,
      };
    }
  });
  return { isTargetTeamUpdated, updatedRoleAccessData };
};

const getIsRoleAcessElemPresentInTableList = ({
  tableList,
  roleAccessKey,
}: {
  tableList: { [key: string]: any }[];
  roleAccessKey: string;
}) => {
  const isPresent = (tableList ?? []).some((tableListElem: any) => {
    const finalTableID = getSelectTableFinalKey({
      tableId: tableListElem?.tableID,
      innerRelationID: tableListElem?.innerRelationID,
      relationID: tableListElem?.relationID,
      parentRelationIDList: tableListElem?.parentRelationIDList,
    });

    return roleAccessKey === finalTableID;
  });
  return { isPresent: isPresent };
};
const updateRoleAccessElem = ({
  roleAccessElem,
  tableColumnsUpdates,
  originalDataTableSchema,
  selecteTableList,
}: {
  roleAccessElem: { [key: string]: any };
  tableColumnsUpdates: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
  selecteTableList: { [key: string]: any };
}) => {
  let updatedRoleAccesssElem = {};
  let isTargetTeamUpdated = false;
  Object.keys(roleAccessElem ?? {}).forEach((teamElemKey) => {
    const teamElem = roleAccessElem[teamElemKey];
    const { isTargetTeamUpdated: isInnerTargetTeamUpdated, updatedTargetTeam } =
      updateTargetTeamElemsData({
        selectedTeamElem: teamElem,
        tableColumnsUpdates,
        originalDataTableSchema,
        selecteTableList,
      });
    if (isInnerTargetTeamUpdated) {
      isTargetTeamUpdated = true;
      updatedRoleAccesssElem = { ...updatedRoleAccesssElem, [teamElemKey]: updatedTargetTeam };
    } else {
      updatedRoleAccesssElem = { ...updatedRoleAccesssElem, [teamElemKey]: teamElem };
    }
  });
  return { updatedRoleAccesssElem, isTargetTeamUpdated };
};

const updateTargetTeamElemsData = ({
  selectedTeamElem,
  tableColumnsUpdates,
  originalDataTableSchema,
  selecteTableList,
}: {
  selectedTeamElem: { [key: string]: any };
  tableColumnsUpdates: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
  selecteTableList: { [key: string]: any };
}) => {
  const addColumns = tableColumnsUpdates?.addColumns ?? [];
  const deleteColumns = tableColumnsUpdates?.deleteColumns ?? [];
  const renameColumns = tableColumnsUpdates?.renameColumns ?? {};

  let selectedTeamElemToUpdate: any = { ...selectedTeamElem };
  let isTargetTeamUpdated = false;

  // Handle Rename
  if (Object.keys(renameColumns ?? {})?.[0]) {
    const { isTeamsChange: isInnerTargetTeamUpdated, udpatedSeletedTeamElem } =
      renameColumnsInTeams({
        renameColumns,
        selectedTeamElem: selectedTeamElemToUpdate,
      });
    if (isInnerTargetTeamUpdated) {
      selectedTeamElemToUpdate = udpatedSeletedTeamElem;
      isTargetTeamUpdated = true;
    }
  }

  // Handle Delete
  if (deleteColumns?.[0]) {
    const { isTeamsChange: isInnerTargetTeamUpdated, udpatedSeletedTeamElem } =
      deleteColumnsInTeams({
        deleteColumns,
        selectedTeamElem: selectedTeamElemToUpdate,
      });
    if (isInnerTargetTeamUpdated) {
      selectedTeamElemToUpdate = udpatedSeletedTeamElem;
      isTargetTeamUpdated = true;
    }
  }

  // Handle Add
  if (addColumns?.[0]) {
    const { isTeamsChange: isInnerTargetTeamUpdated, udpatedSeletedTeamElem } = addColumnsInTeams({
      addColumns,
      originalDataTableSchema,
      originalSelectedTeamElem: selectedTeamElem,
      selectedTeamElem: selectedTeamElemToUpdate,
      selecteTableList,
    });
    if (isInnerTargetTeamUpdated) {
      selectedTeamElemToUpdate = udpatedSeletedTeamElem;
      isTargetTeamUpdated = true;
    }
  }
  // No Need of Handling Update

  return { updatedTargetTeam: selectedTeamElemToUpdate, isTargetTeamUpdated };
};

const renameColumnsInTeams = ({
  renameColumns,
  selectedTeamElem,
}: {
  renameColumns: { [key: string]: any };
  selectedTeamElem: { [key: string]: any };
}) => {
  let udpatedSeletedTeamElem = {};
  let isTeamsChange = false;
  Object.keys(selectedTeamElem ?? {}).forEach((teamElemKey) => {
    const teamElem = selectedTeamElem[teamElemKey];
    const updatedColumnWiseRoles = (teamElem?.ColumnWiseRoles ?? []).map(
      (ColumnWiseRolesElem: any) => {
        if (renameColumns[ColumnWiseRolesElem]) {
          isTeamsChange = true;
          return renameColumns[ColumnWiseRolesElem];
        }
        return ColumnWiseRolesElem;
      }
    );
    const finalTeamElem = {
      ...teamElem,
      ColumnWiseRoles: updatedColumnWiseRoles,
    };
    udpatedSeletedTeamElem = {
      ...udpatedSeletedTeamElem,
      [teamElemKey]: finalTeamElem,
    };
  });
  return { udpatedSeletedTeamElem, isTeamsChange };
};
const deleteColumnsInTeams = ({
  deleteColumns,
  selectedTeamElem,
}: {
  deleteColumns: { [key: string]: any }[];
  selectedTeamElem: { [key: string]: any };
}) => {
  let udpatedSeletedTeamElem = {};
  let isTeamsChange = false;
  Object.keys(selectedTeamElem ?? {}).forEach((teamElemKey) => {
    const teamElem = selectedTeamElem[teamElemKey];
    const updatedColumnWiseRoles = (teamElem?.ColumnWiseRoles ?? []).filter(
      (ColumnWiseRolesElem: any) => {
        const isSkip = (deleteColumns ?? []).some((columnData: { [key: string]: any }) => {
          return columnData.columnName === ColumnWiseRolesElem;
        });
        if (isSkip) {
          isTeamsChange = true;
        }
        return !isSkip;
      }
    );
    const finalTeamElem = {
      ...teamElem,
      ColumnWiseRoles: updatedColumnWiseRoles,
    };
    udpatedSeletedTeamElem = {
      ...udpatedSeletedTeamElem,
      [teamElemKey]: finalTeamElem,
    };
  });
  return { udpatedSeletedTeamElem, isTeamsChange };
};
const addColumnsInTeams = ({
  addColumns,
  originalDataTableSchema,
  selectedTeamElem,
  originalSelectedTeamElem,
  selecteTableList,
}: {
  addColumns: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
  selectedTeamElem: { [key: string]: any };
  originalSelectedTeamElem: { [key: string]: any };
  selecteTableList: { [key: string]: any };
}) => {
  const originalDefaultViewColumnLength = (selecteTableList?.defaultView ?? [])?.length;
  const addColumnLength = (addColumns ?? []).length;
  const originalColumnSchemaLength = originalDefaultViewColumnLength - addColumnLength;
  // const originalColumnSchemaLength = (originalDataTableSchema?.columnSchema ?? []).length;

  let udpatedSeletedTeamElem = {};
  let isTeamsChange = false;
  Object.keys(selectedTeamElem ?? {}).forEach((teamElemKey) => {
    const teamElem = selectedTeamElem[teamElemKey];
    const originalColumnWiseRolesLength = (
      originalSelectedTeamElem?.[teamElemKey]?.ColumnWiseRoles ?? []
    )?.length;
    const isAllColumnAdded = originalColumnWiseRolesLength >= originalColumnSchemaLength;
    let updatedColumnWiseRoles = [...(teamElem?.ColumnWiseRoles ?? [])];
    if (isAllColumnAdded) {
      const updateColumnsDataToAddInTeam = (addColumns ?? []).map(
        (columnData: { [key: string]: any }) => columnData?.columnName
      );

      updatedColumnWiseRoles = [...updatedColumnWiseRoles, ...updateColumnsDataToAddInTeam];
      isTeamsChange = true;
    }
    const finalTeamElem = {
      ...teamElem,
      ColumnWiseRoles: updatedColumnWiseRoles,
    };
    udpatedSeletedTeamElem = {
      ...udpatedSeletedTeamElem,
      [teamElemKey]: finalTeamElem,
    };
  });
  return { udpatedSeletedTeamElem, isTeamsChange };
};
