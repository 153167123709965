import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './sheetToTable.module.scss';
import { charactersNotAllowedInTableAndColumnName } from 'lib/reusable-components/reusableData/dataTableDependencyConstants';
import MulitTableTabsContainer from './MulitTableTabsContainer';
import { columnTypeChecker } from '../../helper/columnTypeChecker';

interface multipleTableUploadProps {
  allTableData: { [key: string]: any };
  setOpen: (value: boolean) => void;
  appID: string;
}
const getDefaultSelectedValue = ({ allTableData }: { allTableData: { [key: string]: any } }) => {
  let defaultSelectedValue = {};
  Object.keys(allTableData).forEach((selectedTableName) => {
    defaultSelectedValue = {
      ...defaultSelectedValue,
      [selectedTableName]: { tableName: selectedTableName },
    };
  });
  return defaultSelectedValue;
};
const getDefaultMultipleTableData = ({
  allTableData,
  appID,
  selectedTables,
}: {
  allTableData: { [key: string]: any };
  appID: string;
  selectedTables: { [key: string]: any };
}) => {
  let defaultMultipleTableData = {};

  Object.keys(selectedTables ?? {}).forEach((originalTableKey: string) => {
    const selectedTableData = selectedTables[originalTableKey];
    const tableName = selectedTableData?.tableName ?? '';
    const tableData = allTableData[originalTableKey];
    const columnData = columnTypeChecker(tableData[0] ?? {});
    const dataTableSchema = {
      version: 2.0,
      appID: appID,
      tableID: tableName,
      name: tableName,
      relations: {},
      columnSchema: columnData,
      updates: {
        addColumns: [],
        updateColumns: [],
        deleteColumns: [],
        renameColumns: {},
        dropTable: false,
        createTable: true,
      },
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };

    defaultMultipleTableData = {
      ...defaultMultipleTableData,
      [tableName]: {
        originalTableKey,
        tableName: tableName,
        tableData,
        dataTableSchema,
        originalTableName: tableName,
      },
    };
  });
  return defaultMultipleTableData;
};
const MultipleTableUpload = ({ allTableData, setOpen, appID }: multipleTableUploadProps) => {
  const [selectedTables, setSelectedTables] = useState(getDefaultSelectedValue({ allTableData }));
  const [isTableNameConfirmed, setIsTableNameConfirmed] = useState(true);
  const [multipleTableData, setMultipleTableData] = useState(
    getDefaultMultipleTableData({ allTableData, appID, selectedTables })
  );

  if (isTableNameConfirmed) {
    return (
      <MulitTableTabsContainer
        setOpen={setOpen}
        selectedTables={selectedTables}
        allTableData={allTableData}
        multipleTableData={multipleTableData}
        setSelectedTables={setSelectedTables}
        setMultipleTableData={setMultipleTableData}
        appID={appID}
      />
    );
  }
  const handleTableConfirmed = () => {
    const multipleTableData = getDefaultMultipleTableData({ allTableData, appID, selectedTables });
    setMultipleTableData(multipleTableData);
    setIsTableNameConfirmed(true);
  };
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center p-5 ${styles.MultipleTableUpload}`}
    >
      <h1 className='mb-5 mt-10'>{'XLSX Data'}</h1>

      <div style={{ width: '80vw' }}>
        {React.Children.toArray(
          Object.keys(allTableData).map((selectedTableName) => {
            const tableData = allTableData[selectedTableName];
            return (
              <TableSelection
                tableData={tableData}
                selectedTableName={selectedTableName}
                selectedTables={selectedTables}
                setSelectedTables={setSelectedTables}
              />
            );
          })
        )}
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: '-16px',
        }}
        className='d-flex justify-content-end w-100 py-5 px-5 bg-white'
      >
        <div className='me-4'>
          <button
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            data-bs-dismiss='modal'
            onClick={() => setOpen(false)}
          >
            {mls('Cancel')}
          </button>
        </div>
        <button className='btn btn-sm btn-primary' onClick={() => handleTableConfirmed()}>
          {mls('Next')}
        </button>
      </div>
    </div>
  );
};

export default MultipleTableUpload;

interface tableSelectionProps {
  selectedTableName: string;
  tableData: { [key: string]: any };
  selectedTables: { [key: string]: any };
  setSelectedTables: (value: { [key: string]: any }) => void;
}

const maxCharactersAllowed = 30;

const TableSelection = ({
  selectedTableName,
  tableData,
  selectedTables,
  setSelectedTables,
}: tableSelectionProps) => {
  const [tableNameError, setTableNameError] = useState<boolean | string>(false);
  const [tableName, setTableName] = useState(selectedTableName?.split('.csv')[0] || '');
  const appDatatable = useSelector((state: any) => state.config_store.appSchema.appDatatable);
  const isSelected = selectedTables?.[selectedTableName] ? true : false;
  const handleTableNameError = (tableName: string) => {
    // const isTableNameLenghtLessThenLimit = tableName.length > maxCharactersAllowed;
    // if (isTableNameLenghtLessThenLimit) {
    //   setTableNameError('Max 30 characters allowed');
    //   return { error: true };
    // } else {
    //   setTableNameError(false);
    //   return { error: false };
    // }

    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return tableName.includes(char);
      }
    );
    const getIsTableNameInSelectedTable = ({
      selectedTableName,
      selectedTables,
    }: {
      selectedTableName: string;
      selectedTables: { [key: string]: any };
    }) => {
      let isTableNameInSelectedTable = false;
      Object.keys(selectedTables).forEach((tempSelectedTableName) => {
        if (
          selectedTables[tempSelectedTableName].tableName === tableName &&
          selectedTables?.[selectedTableName].tableName !== tableName
        ) {
          isTableNameInSelectedTable = true;
        }
      });
      return isTableNameInSelectedTable;
    };
    const tableWithThisNameAlreadyExists = appDatatable?.[tableName] ? true : false;
    const isTableNameLenghtLessThenLimit = tableName.length > maxCharactersAllowed;
    const isTableNameInSelectedTable = getIsTableNameInSelectedTable({
      selectedTableName,
      selectedTables,
    });
    (tableWithThisNameAlreadyExists || isTableNameInSelectedTable) &&
      setTableNameError('Table name must be unique');
    containsCharacterWhichIsNotAllowed &&
      setTableNameError(
        "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
    isTableNameLenghtLessThenLimit && setTableNameError('Max 30 characters allowed');
    if (
      !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      !isTableNameInSelectedTable &&
      !isTableNameLenghtLessThenLimit
    ) {
      setTableNameError(false);
    }
    if (!isTableNameLenghtLessThenLimit) {
      return { error: false };
    }
    console.log('tableNameError', tableNameError);

    return { error: true };
  };
  useEffect(() => {
    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return tableName.includes(char);
      }
    );
    const getIsTableNameInSelectedTable = ({
      selectedTableName,
      selectedTables,
    }: {
      selectedTableName: string;
      selectedTables: { [key: string]: any };
    }) => {
      let isTableNameInSelectedTable = false;
      Object.keys(selectedTables).forEach((tempSelectedTableName) => {
        if (
          selectedTables?.[tempSelectedTableName]?.tableName === tableName &&
          selectedTables?.[selectedTableName]?.tableName !== tableName
        ) {
          isTableNameInSelectedTable = true;
        }
      });
      return isTableNameInSelectedTable;
    };
    const tableWithThisNameAlreadyExists = appDatatable?.[tableName] ? true : false;
    const isTableNameLenghtLessThenLimit = tableName.length > maxCharactersAllowed;
    const isTableNameInSelectedTable = getIsTableNameInSelectedTable({
      selectedTableName,
      selectedTables,
    });
    (tableWithThisNameAlreadyExists || isTableNameInSelectedTable) &&
      setTableNameError('Table name must be unique');
    containsCharacterWhichIsNotAllowed &&
      setTableNameError(
        "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
    isTableNameLenghtLessThenLimit && setTableNameError('Max 30 characters allowed');
    if (
      !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      !isTableNameInSelectedTable &&
      !isTableNameLenghtLessThenLimit
    ) {
      setTableNameError(false);
    }
    if (
      !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      !isTableNameInSelectedTable
    ) {
      const selectedTableData = selectedTables[selectedTableName];
      // if (selectedTableData) {
      //   if (selectedTableData?.isError !== false) {
      //     const updatedSelectedTable = { ...selectedTable };
      //     updatedSelectedTable[selectedTableName] = { tableName, isError: false };
      //     setSelectedTable(updatedSelectedTable);
      //   }
      // }
    } else {
      const selectedTableData = selectedTables[selectedTableName];
      // if (selectedTableData) {
      //   if (selectedTableData?.isError !== true) {
      //     const updatedSelectedTable = { ...selectedTable };
      //     updatedSelectedTable[selectedTableName] = { tableName, isError: true };
      //     setSelectedTable(updatedSelectedTable);
      //   }
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, selectedTables]);
  const handleTableName = (tableName: string) => {
    const tableNameError = handleTableNameError(tableName);
    if (!tableNameError.error) {
      setTableName(tableName);
      const updatedSelectedTable = {
        ...selectedTables,
        [selectedTableName]: { ...(selectedTables?.[selectedTableName] ?? {}), tableName },
      };
      setSelectedTables(updatedSelectedTable);
    }
  };
  const handleCheckBox = ({
    selectedTableName,
    isSelected,
  }: {
    selectedTableName: string;
    isSelected: boolean;
  }) => {
    setTableName(selectedTableName);
    const updatedSelectedTable = { ...selectedTables };
    if (isSelected) {
      delete updatedSelectedTable[selectedTableName];
    } else {
      updatedSelectedTable[selectedTableName] = { tableName };
    }
    setSelectedTables(updatedSelectedTable);
  };
  return (
    <div className={`${styles.tableSelectionContainer} ${isSelected ? '' : styles.tableDisabled}`}>
      <div className={styles.tableNameContainer}>
        <CheckBox
          checked={isSelected}
          onClick={() => handleCheckBox({ isSelected, selectedTableName })}
        />
        <label className='col-md-5 fs-5 fw-bold'>
          {mls('Table Name')} ( {selectedTableName} )
        </label>
      </div>
      <input
        type='text'
        className='checkbox form-control form-control-solid'
        placeholder='Table Name'
        value={tableName}
        disabled={!isSelected}
        onBlur={() => handleTableNameError(tableName)}
        onChange={(e) => handleTableName(e.target.value)}
      />
      {tableNameError && (
        <div className='fv-plugins-message-container'>
          <span className='text-danger' role='alert'>
            {tableNameError}
          </span>
        </div>
      )}
    </div>
  );
};
