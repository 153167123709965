import React, { useState } from 'react';
import styles from './relatedFields.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { mls } from 'lib/multilanguagesupport';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { useSelector } from 'react-redux';
// import { relationTypeObjects } from 'lib/appComponent/componentSettings/data/appComponentData';
// import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { generateShortUUID } from 'lib/erp-data-table/components/util/utilFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ChooseColumn from './ChooseColumn';
import RelatedFiledDropDown from './RelatedFiledDropDown';
import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';

interface relatedFieldsPopupProps {
  isRelatedFieldPopup: boolean;
  handleIsRelatedFieldPopup: Function;
  relatedTable: any;
  selectedConnectedTable: any;
  relatedTableList: any;
  tableID: string;
  relationType: string;
  tableData: any;
  updateRelatedTable: Function;
  setRelatedTableList: Function;
  connectedTablesData: any;
  componentSchema: { [key: string]: any };
}
const defaultRelatedField = {
  relatedFieldArray: [],
  name: '',
  defaultView: [],
  relatedFieldId: uuidv4(),
};
const RelatedFieldsPopup = ({
  isRelatedFieldPopup,
  handleIsRelatedFieldPopup,
  relatedTable,
  selectedConnectedTable,
  relatedTableList,
  tableID,
  relationType,
  tableData,
  updateRelatedTable,
  connectedTablesData,
  componentSchema,
}: relatedFieldsPopupProps) => {
  const mainTable = componentSchema.table;

  const allDataTableSchema = useSelector((state: any) => {
    return (
      state.config_store?.[reduxConstants.config.APP_SCHEMA]?.[reduxConstants.config.DATA_TABLE] ||
      {}
    );
  });
  const [newRelationData, setNewRelationData] = useState<{
    status: boolean;
    isNew: boolean;
    relatedFieldIndex: null | number;
    relatedFieldId: string;
    data: {
      relatedFieldArray?: Array<any>;
      name?: string;
      defaultView?: Array<any>;
      relatedFieldId?: string;
    };
  }>({
    status: false,
    isNew: false,
    relatedFieldIndex: null,
    relatedFieldId: uuidv4(),
    data: defaultRelatedField,
  });
  const relationData = newRelationData?.data;
  const handleNewRelation = () => {
    let data: any = {
      relatedFieldArray: [],
      name: `Group - ${generateShortUUID({ uuidLength: 10 })}`,
      relatedFieldId: uuidv4(),
    };
    // if (
    //   relationType === relationTypeObjects.hasMany.key ||
    //   relationType === relationTypeObjects.belongsToMany.key ||
    //   relationType === relationTypeObjects.hasOne.key
    // ) {
    //   const getMainTableData: any = Object.values(tableData?.relations ?? {}).find(
    //     (elem: any) =>
    //       elem?.type === relationTypeObjects.belongsTo.key && elem?.target === mainTable
    //   );
    //   let finalValue = [...(data?.relatedFieldArray ?? {})];
    //   if (getMainTableData) {
    //     const hasManyValue = {
    //       relationId: getMainTableData.as,
    //       columnName: getMainTableData.relationName,
    //       dataType: relationTypeObjects.belongsTo.key,
    //       tableName: getMainTableData.target,
    //     };
    //     finalValue = [...finalValue, hasManyValue];
    //   }
    //   data = { ...data, relatedFieldArray: finalValue };
    // }
    setNewRelationData({
      ...newRelationData,
      status: true,
      data: data,
      isNew: true,
      relatedFieldIndex: null,
      relatedFieldId: uuidv4(),
    });
  };
  const handleRelatedFieldGroupName = (action: any) => {
    setNewRelationData({
      ...newRelationData,
      data: { ...(newRelationData?.data ?? {}), name: action },
    });
  };
  const handlerelatedFieldValue = (action: any) => {
    setNewRelationData({
      ...newRelationData,
      data: { ...(newRelationData?.data ?? {}), relatedFieldArray: action, defaultView: [] },
    });
  };
  const handleColumnValue = (action: any) => {
    setNewRelationData({
      ...newRelationData,
      data: { ...(newRelationData?.data ?? {}), defaultView: action },
    });
  };
  const tableArray: any[] = newRelationData?.data?.relatedFieldArray ?? [];
  const lastTablename = tableArray?.[tableArray?.length - 1]?.tableName;
  const selecteTable = allDataTableSchema?.[lastTablename];
  console.log('lastTablename', lastTablename);
  console.log('tableArray', tableArray);

  const handleDone = () => {
    const validation = ({
      relationData,
      relatedTable,
    }: {
      relationData: { [key: string]: any };
      relatedTable: { [key: string]: any };
    }) => {
      // const isRelatedFieldNamePresent = (relatedTable?.relatedFields ?? []).some(
      //   (elem: any) => elem?.name === relationData?.name
      // );
      // if (isRelatedFieldNamePresent) {
      //   toast.warn(mls('Related field group Name already present!'));
      //   return { skip: true };
      // }
      if (!(relationData?.name ?? '').trim()) {
        toast.warn(mls('Please add group name!'));
        return { skip: true };
      }
      if (!relationData.defaultView?.[0]) {
        toast.warn(mls('Please add columns!'));
        return { skip: true };
      }
      return { skip: false };
    };
    const validationData = validation({ relationData: newRelationData.data, relatedTable });
    if (!validationData.skip) {
      let finaleRelatedFields = Array.from([...(relatedTable?.relatedFields ?? [])]);
      if (newRelationData.isNew) {
        finaleRelatedFields = [...finaleRelatedFields, relationData];
      } else {
        finaleRelatedFields.splice(newRelationData.relatedFieldIndex ?? 0, 1);
        finaleRelatedFields.splice(newRelationData?.relatedFieldIndex ?? 0, 0, relationData);
      }
      const newColumn = {
        name: relationData.name,
        description: '',
        filters: {},
        columnName: relationData.relatedFieldId,
        // columnName: relationData.name,
        dataType: erpAllDataType.relatedField.key,
      };
      let newDefaultView = [...(relatedTable?.defaultView ?? [])];
      newDefaultView = [...newDefaultView, newColumn];
      updateRelatedTable({
        ...relatedTable,
        relatedFields: finaleRelatedFields,
        defaultView: newDefaultView,
      });
      setNewRelationData({
        ...newRelationData,
        status: false,
        data: { ...defaultRelatedField, relatedFieldId: uuidv4() },
        isNew: false,
        relatedFieldIndex: null,
        relatedFieldId: uuidv4(),
      });
    }
  };
  const handleDelete = () => {
    if (!newRelationData.isNew) {
      let finaleRelatedFields = Array.from([...(relatedTable?.relatedFields ?? [])]);
      finaleRelatedFields.splice(newRelationData.relatedFieldIndex ?? 0, 1);
      let newDefaultView = [...(relatedTable?.defaultView ?? [])];
      newDefaultView = (newDefaultView ?? []).filter(
        (elem) => elem.columnName !== relationData.relatedFieldId
      );
      updateRelatedTable({
        ...relatedTable,
        defaultView: newDefaultView,
        relatedFields: finaleRelatedFields,
      });
    }
    setNewRelationData({
      ...newRelationData,
      status: false,
      data: { ...defaultRelatedField, relatedFieldId: uuidv4() },
      isNew: false,
      relatedFieldIndex: null,
      relatedFieldId: uuidv4(),
    });
  };
  const handleSelection = ({ relatedFieldIndex }: { relatedFieldIndex: number }) => {
    const relatedFieldElem = relatedTable?.relatedFields[relatedFieldIndex];
    setNewRelationData({
      ...newRelationData,
      status: true,
      data: relatedFieldElem,
      isNew: false,
      relatedFieldIndex: relatedFieldIndex,
    });
  };
  return (
    <div className={styles.RelatedFieldsOuterContainer}>
      <PopUpDivChild
        initialValue={isRelatedFieldPopup}
        setPopUp={handleIsRelatedFieldPopup}
        // popupTitle={`${tableData?.name ?? tableID} (${relationType})`}
        popupTitle={mls('Related Fields')}
        // fullScreenMode
      >
        <div className={styles.relatedFieldsInnerContainer}>
          <div className={styles.relatedFieldsLeftContainer}>
            <div className={styles.relatedFieldsAddContainer}>
              <button
                className='primaryButton secondaryButtonColor'
                onClick={() => handleNewRelation()}
              >
                {mls('Add Related Field')} +
              </button>
            </div>
            <div className={styles.relatedFieldsListContainer}>
              {React.Children.toArray(
                (relatedTable?.relatedFields ?? []).map(
                  (relatedFieldElem: any, relatedFieldIndex: number) => {
                    const selected = newRelationData?.relatedFieldIndex === relatedFieldIndex;
                    return (
                      <div
                        className={`${styles.relatedFieldsListElem} ${
                          selected ? styles.relatedFieldsListElemSelected : ''
                        }`}
                        onClick={() => {
                          handleSelection({ relatedFieldIndex });
                        }}
                      >
                        {relatedFieldElem?.name}
                      </div>
                    );
                  }
                )
              )}
            </div>
          </div>
          <div className={styles.relatedFieldsRightContainer}>
            {newRelationData.status ? (
              <>
                <div className={styles.relatedFieldButtonContainer}>
                  <button
                    className='primaryButton secondaryButtonColor'
                    onClick={() => handleDone()}
                  >
                    {mls('Done')}
                  </button>
                  {newRelationData.isNew ? (
                    <button className='primaryButton secondaryColor' onClick={() => handleDelete()}>
                      {mls('Cancel')}
                    </button>
                  ) : (
                    <button className='primaryButton secondaryColor' onClick={() => handleDelete()}>
                      {mls('Delete')}
                    </button>
                  )}
                </div>
                <div>
                  <b>{mls('Related Field Group Name')}</b>
                </div>
                <input
                  className='primaryInput'
                  type='text'
                  placeholder={mls('Related Field Group Name')}
                  onChange={(e) => {
                    handleRelatedFieldGroupName(e.target.value);
                  }}
                  value={newRelationData?.data?.name ?? ''}
                />
                <div className={styles.chooserelatedFieldContainer}>
                  <div>
                    <b>{mls('Related Field')}</b>
                  </div>
                  <RelatedFiledDropDown
                    tableName={tableID}
                    elem={newRelationData.data}
                    nestIndex={0}
                    allDataTableSchema={allDataTableSchema}
                    relationType={relationType}
                    handleColumnValue={handlerelatedFieldValue}
                    mainTable={mainTable}
                  />
                </div>
                <ChooseColumn
                  selecteTable={selecteTable}
                  relationData={relationData}
                  handleColumnValue={handleColumnValue}
                />
              </>
            ) : null}
          </div>
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default RelatedFieldsPopup;
