import React, { useMemo, useState } from 'react';
import styles from './sheetToTable.module.scss';
import { mls } from 'lib/multilanguagesupport';
import NewDataTableSetting from '../NewDataTableSetting';
import EditTableSetting from 'lib/erp-data-table/components/EditTableSettingsPopup/EditTableSetting';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { toast } from 'react-toastify';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import store from 'lib/reusable-components/reusableUtils/redux/store';
interface mulitTableTabsContainerProps {
  setOpen: (e: boolean) => void;
  allTableData: { [key: string]: any };
  selectedTables: { [key: string]: any };
  multipleTableData: { [key: string]: any };
  setSelectedTables: (e: { [key: string]: any }) => void;
  setMultipleTableData: (e: { [key: string]: any }) => void;
  appID: string;
}

const MulitTableTabsContainer = ({
  allTableData,
  selectedTables,
  setOpen,
  multipleTableData,
  setSelectedTables,
  setMultipleTableData,
  appID,
}: mulitTableTabsContainerProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const addNewTable = async ({
    dataTableSchema,
    csvData,
    tableName,
  }: {
    dataTableSchema: any;
    csvData: any;
    tableName: string;
  }) => {
    const currentTimestamp = Date.now();
    const finalDataTableSchema = {
      ...dataTableSchema,
      appID: appID,
      version: 2.0,
      // updates: udpatedTableUpdate,
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };
    const res = _supistaApiPost(`settings/${appID}/DataTable`, {
      data: finalDataTableSchema,
    })
      .then(async (response) => {
        if (!response?.__d3__success) {
          toast.error('Not able to save please try again later!');
          return { skip: true };
        }
        const rowDataUploadFunction = async () => {
          // Sending the csv rows data to backend only if CSV upload is done
          // Size of chunk of rows' data to be sent at once
          const CHUNK_SIZE = 1000;
          const csvDataKeysSpacesRemoved = csvData;
          // var success = true;
          const rowsDataUploadPromises = [];
          for (let i = 0; i < csvDataKeysSpacesRemoved.length; i += CHUNK_SIZE) {
            const rowsData = csvDataKeysSpacesRemoved.slice(i, i + CHUNK_SIZE);
            rowsDataUploadPromises.push(
              _supistaApiPost(`app/${appID}/TABLE_SOURCE/${tableName}/uploadCSV`, {
                data: {
                  __d3__csvData: rowsData,
                },
              })
            );
          }

          const rowDataRes = Promise.all(rowsDataUploadPromises)
            .then((csvDataUploadResponses) => {
              let success = false;
              for (let i = 0; i < csvDataUploadResponses.length; i++) {
                const oneChunkRowUploadResponse = csvDataUploadResponses[i];
                if (oneChunkRowUploadResponse?.result?.bulkCreated) {
                  success = true;
                } else {
                  success = false;
                }
                if (success === false) throw new Error('Rows not uploaded');
              }
              return { skip: !success, msg: 'yes' };
            })
            .catch((error) => {
              store.dispatch(
                create({
                  setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
                  value: {
                    message:
                      'Table created but rows cannot be uploaded. Check the column data types properly.',
                    time: 5,
                    isActive: true,
                  },
                })
              );
              return { skip: true };
            });
          return await rowDataRes;
        };
        function delay(t: number, val?: any) {
          return new Promise((resolve) => setTimeout(resolve, t, val));
        }

        const rowData = await delay(1000).then(() => rowDataUploadFunction());
        return rowData;
      })
      .catch((err) => {
        // console.log(err);
        return { skip: true };
      });
    return await res;
  };
  const save = async () => {
    setIsSaving(true);
    let saveError = { error: false };
    const selectTablesPromises = Object.keys(selectedTables ?? {}).map(async (multipleTableKey) => {
      const multipleTableElem = multipleTableData[multipleTableKey];
      const tableName = multipleTableElem?.tableName;
      const dataTableSchema = multipleTableElem?.dataTableSchema ?? {};
      const csvData = multipleTableElem?.tableData ?? [];
      const errorData: any = await addNewTable({
        dataTableSchema,
        csvData,
        tableName,
      });
      if (errorData?.error) {
        saveError = errorData;
      }
      return errorData;
    });
    await Promise.all(selectTablesPromises);
    if (saveError.error) {
      publishToProduction(appID);
    } else {
      setOpen(false);
      publishToProduction(appID);
    }
    setIsSaving(false);
  };
  const [tabSelectedTable, setTabSelectedTable] = useState(
    Object.keys(multipleTableData ?? {})?.[0] ?? ''
  );

  const selectedTableData = multipleTableData?.[tabSelectedTable] ?? {};

  const handleDataTableSchema = (updateState: any) => {
    setMultipleTableData((oldMutipleTableData: any) => {
      const selectedTableData = oldMutipleTableData?.[tabSelectedTable] ?? {};
      const state = updateState(selectedTableData?.dataTableSchema ?? {});
      return {
        ...(multipleTableData ?? {}),
        [tabSelectedTable]: {
          ...selectedTableData,
          dataTableSchema: state,
          tableName: state?.tableID,
        },
      };
    });
  };
  const handleTableUpdates = (updates: any) => {
    setMultipleTableData((oldMutipleTableData: any) => {
      const selectedTableData = oldMutipleTableData?.[tabSelectedTable] ?? {};
      return {
        ...(multipleTableData ?? {}),
        [tabSelectedTable]: {
          ...selectedTableData,
          dataTableSchema: { ...(selectedTableData?.dataTableSchema ?? {}), updates },
        },
      };
    });
  };
  const extraDataTable = useMemo(() => {
    let extraDataTable = {};
    Object.keys(multipleTableData ?? {}).forEach((multipleTableDatakey) => {
      const multipleTableDataElem = multipleTableData[multipleTableDatakey];
      extraDataTable = {
        ...extraDataTable,
        [multipleTableDataElem?.tableName]: multipleTableDataElem?.dataTableSchema,
      };
    });
    return extraDataTable;
  }, [multipleTableData]);

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center p-5 ${styles.MultipleTableUpload}`}
    >
      <h1 className='mb-5 mt-10'>{'XLSX Data'}</h1>
      <>
        <div style={{ width: '80vw' }}>
          <div className={styles.multiTableContainer}>
            <div className={styles.tableTabHeader}>
              {React.Children.toArray(
                Object.keys(multipleTableData).map((tableName) => {
                  const multipleTableDataElem = multipleTableData[tableName];
                  const handleCheckBox = ({
                    selectedTableName,
                    isSelected,
                  }: {
                    selectedTableName: string;
                    isSelected: boolean;
                  }) => {
                    //  setTableName(selectedTableName);
                    const updatedSelectedTable = { ...selectedTables };
                    if (isSelected) {
                      delete updatedSelectedTable[selectedTableName];
                    } else {
                      updatedSelectedTable[selectedTableName] = { tableName };
                    }
                    setSelectedTables(updatedSelectedTable);
                  };
                  const isSelected = selectedTables?.[multipleTableDataElem?.originalTableKey ?? '']
                    ? true
                    : false;
                  return (
                    <div
                      className={`${styles.tableTabElem} ${
                        tabSelectedTable === tableName ? styles.active : ''
                      }`}
                      title={tableName}
                      onClick={() => setTabSelectedTable(tableName)}
                    >
                      <div className={styles.tableTabElemCheckBoxArea}>
                        <CheckBox
                          checked={isSelected}
                          onClick={() =>
                            handleCheckBox({
                              isSelected,
                              selectedTableName: multipleTableDataElem?.originalTableKey ?? '',
                            })
                          }
                        />
                      </div>
                      <div className={styles.tableTabElemTableNameArea}>
                        {multipleTableDataElem?.tableName ?? tableName}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div
              className={styles.tableTabInnerContainer}
              key={selectedTableData?.dataTableSchema?.name}
            >
              <EditTableSetting
                dataTableSchema={selectedTableData?.dataTableSchema ?? {}}
                setDataTableSchema={handleDataTableSchema}
                tableUpdates={selectedTableData?.dataTableSchema?.updates ?? {}}
                setTableUpdates={handleTableUpdates}
                allDataTableData={{}}
                relatedTableList={{}}
                setRelatedTableList={() => {}}
                isEditRealtion={false}
                isEditTableName={true}
                extraDataTable={extraDataTable}
              />
              <br />
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'sticky',
            bottom: '-16px',
          }}
          className='d-flex justify-content-end w-100 py-5 px-5 bg-white'
        >
          <div className='me-4'>
            <button
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
              data-bs-dismiss='modal'
              onClick={() => setOpen(false)}
            >
              {mls('Cancel')}
            </button>
          </div>
          <button
            className='btn btn-sm btn-primary'
            // disabled={
            //   tableName.trim() === '' || tableNameError || invalidColumnNameIndices.size > 0
            // }
            onClick={() => save()}
          >
            {mls('Save Table')}
          </button>
        </div>
      </>
    </div>
  );
};

export default MulitTableTabsContainer;
