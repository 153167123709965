import { _supistaApiGetRawResponse } from 'lib/server-connection/connections';
import { useState, useEffect } from 'react';

const useFetchAssets = (assets: Record<string, any>, appID: string) => {
  const [assetBlobs, setAssetBlobs] = useState<Record<string, any>>({});

  useEffect(() => {
    const fetchAssets = async () => {
      const assetEntries = Object.entries(assets ?? {});
      if (assetEntries.length === 0) return;

      const assetPromises = assetEntries.map(async ([key, asset]) => {
        const endpoint = `manageFile/${appID}/assets/${appID}/${asset.fileUrl}`;
        const response: any = await _supistaApiGetRawResponse(endpoint);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        return {
          [key]: {
            assetName: asset.assetName,
            assetUrl: blobUrl,
            assetID: key,
          },
        };
      });

      const resolvedAssets = await Promise.all(assetPromises);
      const assetBlobData = Object.assign({}, ...resolvedAssets);
      setAssetBlobs(assetBlobData);
    };

    fetchAssets();
  }, [assets, appID]);

  return assetBlobs;
};

export default useFetchAssets;
