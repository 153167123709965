import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './templatesWrapper.module.scss';
import EmailTemplate from './EmailTemplate';
import { mls } from 'lib/multilanguagesupport';
import { getConnectedTables } from './helperFuntion';
import { CircularProgress } from '@mui/material';
import { Row, Col, Form } from 'react-bootstrap';
import CreateTemplateHeader from './CreateTemplateHeader';
import { defaultHtmlContent } from './defaultHtmlContentData';
import {
  _supistaApiGet,
  _supistaApiGetRawResponse,
  _supistaApiPost,
} from 'lib/server-connection/connections';
import { allTemplateTypes, templateTypeObject } from './templateData';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import { relationTypeObjects } from '../../data/appComponentData';
import { getSelectTableFinalKey } from '../../utils/componentSettingFunction';
import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useFetchAssets from './AssetsSelection/useFetchAssets';

const fetchComponentData = async ({
  componentID,
  appID,
}: {
  componentID: string;
  appID: string;
}) => {
  const baseUrl = `app/${appID}/CRUD/${componentID}`;
  const filter = `data=%7B%22__d3__filterdata%22%3A%7B%22where%22%3A%7B%7D%7D%2C%22__d3__screenFilter%22%3A%7B%22where%22%3A%7B%7D%7D%7D&lang=English`;
  const finalUrl = `${baseUrl}?${filter}`;
  const componentRes = await _supistaApiGet(finalUrl);
  return componentRes;
};
interface createTemplateProps {
  setIsNewTemplate: Function;
  isNewTemplate: boolean;
  isTemplateSetting: boolean;
  setIsTemplateSetting: Function;
  appDatatable: any;
  appComponent: any;
  componentSchema: any;
  appID: string;
  selectedTemplateID: string;
  setSelectedTemplateID: Function;
  setComponentSchema: Function;
  componentRowList: any;
}

const CreateTemplate = ({
  isNewTemplate,
  setIsTemplateSetting,
  appDatatable,
  setComponentSchema,
  componentSchema,
  appID,
  selectedTemplateID,
}: createTemplateProps) => {
  const [isTemplateForm, setIsTemplateForm] = useState(true);
  const [componentRowList, setComponentRowList] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  // Assets Start
  const assetBlobs = useFetchAssets(appSchema?.app?.assets, appID);
  const assetsObject = useMemo(() => {
    let assetsList: { [key: string]: any } = {};
    Object.keys(appSchema?.app?.assets ?? {}).forEach((assetKey: any) => {
      const assetElem = appSchema?.app?.assets?.[assetKey];
      assetsList = {
        ...assetsList,
        [assetElem?.assetName]: assetElem,
      };
    });
    return assetsList;
  }, [appSchema?.app?.assets]);
  const [selectedId, setSelectedId] = useState<string | number>('');
  const [rowData, setRowData] = useState({});
  const [inputData, setInputData] = useState(
    JSON.stringify({ row: rowData, assets: assetsObject })
  );
  const [sampleData, setSampleData] = useState(assetsObject);

  const [selectedAssets, setSelectedAssets] = useState({});

  useEffect(() => {
    setInputData(JSON.stringify({ row: rowData, assets: selectedAssets }));
  }, [assetBlobs, selectedAssets, rowData]);

  const [assestLoading, setAssestLoading] = useState({ isLoading: false, assetData: {} });
  const downloadPdfButtonRef = useRef(null);
  const connectedTablesData = useMemo(
    () =>
      getConnectedTables({
        tableId: componentSchema?.table ?? '',
        appDatatable,
      }),
    [componentSchema?.table, appDatatable]
  );

  const defaultTemplateData = {
    htmlString: defaultHtmlContent,
    title: '',
    description: '',
    templateID: selectedTemplateID,
    type: templateTypeObject.email.key,
    variables: {
      name: '',
    },
  };

  const [templateData, setTemplateData] = useState(
    !isNewTemplate
      ? componentSchema?.templates?.[selectedTemplateID] ?? defaultTemplateData
      : defaultTemplateData
  );

  // const [templateData, setTemplateData] = useState(defaultTemplateData);
  const updateRowListData = async ({
    componentID,
    appID,
  }: {
    componentID: string;
    appID: string;
  }) => {
    const componentRes = await fetchComponentData({ componentID, appID });
    setComponentRowList(componentRes?.result?.rows ?? []);
    if ((componentRes?.result?.rows ?? [])[0]) {
      setSelectedId((componentRes?.result?.rows ?? [])[0]?.id ?? '');
    }
  };
  useEffect(() => {
    updateRowListData({ componentID: componentSchema?.componentID, appID });
  }, [componentSchema?.componentID, appID]);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const handleComponentChange = (e: any) => {
    setSelectedId(e.target.value);
  };

  useEffect(() => {
    (async () => {
      setAssestLoading({ ...assestLoading, isLoading: true });
      let urlData: { [key: string]: any } = {};
      urlData = {
        data: {
          actionType: 'getRowData',
          description: '',
          filterMainComponent: {
            __d3__filterdata: {
              where: {},
            },
          },
          origin: templateData?.origin ?? '',
          title: 'pdf',
          type: 'pdf',
        },
      };
      if (selectedId || selectedId === '0' || selectedId === 0) {
        urlData = {
          ...urlData,
          data: {
            ...(urlData?.data ?? {}),
            filterMainComponent: {
              ...(urlData?.data?.filterMainComponent ?? {}),
              __d3__filterdata: {
                ...(urlData?.data?.filterMainComponent?.__d3__filterdata ?? {}),
                where: {
                  ...(urlData?.data?.filterMainComponent?.__d3__filterdata?.where ?? {}),
                  id: selectedId,
                },
              },
            },
          },
        };
      }
      const componentID = componentSchema?.componentID;
      const baseUrl = `app/${appID}/Action_Button/${componentID}`;
      const finalUrl = `${baseUrl}`;
      const componentRes = await _supistaApiPost(finalUrl, urlData);

      // setSampleData({ ...sampleData, row: (componentRes?.result?.rows ?? [])?.[0] ?? {} });
      setSampleData((componentRes?.result?.rows ?? [])?.[0] ?? {});
      // setSampleData(componentRes?.result?.rows ?? []);
      if (true) {
        let urlData = {};
        urlData = {
          __d3__filterdata: {
            where: {},
          },
        };
        if (selectedId || selectedId === '0' || selectedId === 0) {
          urlData = {
            __d3__filterdata: {
              where: { id: selectedId },
            },
          };
        }
        // if (selectedTableData?.relationType !== 'MAIN') {
        //   urlData = {
        //     ...urlData,
        //     __d3__filterdata: {
        //       where: {},
        //     },
        //     __d3__parentId: selectedId ?? null,
        //     __d3__relationId: selectedTableData?.relationID,
        //   };
        // }
        // templateData?.origin;
        const filterUrl = `?data=${JSON.stringify(urlData)}`;
        const componentID = componentSchema?.componentID;
        const baseUrl = `app/${appID}/CRUD/${componentID}`;
        const finalUrl = `${baseUrl}${filterUrl}`;
        const componentRes = await _supistaApiGet(finalUrl);
        setRowData((componentRes?.result?.rows ?? [])?.[0] ?? {});
      }

      setAssestLoading({ ...assestLoading, isLoading: false });
    })();
  }, [selectedId, componentSchema?.componentID, appID]);
  const handleTemplateName = (e: any) => {
    setTemplateData({
      ...templateData,
      title: e.target.value,
      variables: { ...(templateData?.variables ?? {}), name: e.target.value },
    });
  };
  const handleTemplateDescription = (e: any) => {
    setTemplateData({
      ...templateData,
      description: e.target.value,
      variables: { ...(templateData?.variables ?? {}), description: e.target.value },
    });
  };
  const handleTemplateType = (e: any) => {
    const defaultHtml =
      allTemplateTypes?.[e.target.value as keyof typeof allTemplateTypes]?.defaultHtml;
    const extraData = defaultHtml ? { htmlString: defaultHtml } : {};
    setTemplateData({
      ...templateData,
      type: e.target.value,
      ...extraData,
      variables: { ...(templateData?.variables ?? {}), type: e.target.value },
    });
  };
  const handleAttachment = (attachments: any) => {
    setTemplateData({
      ...templateData,
      attachment: attachments,
    });
  };

  const handleSubject = (e: any) => {
    setTemplateData({
      ...templateData,
      subject: e.target.value,
    });
  };
  const handleOrigin = (e: any) => {
    let newTemplateData = {
      ...templateData,
      origin: e.target.value,
    };
    delete newTemplateData.attachment;
    delete newTemplateData.emailList;
    setTemplateData(newTemplateData);
  };
  const handleEmailTo = (emailTo: any) => {
    setTemplateData({
      ...templateData,
      emailList: { to: emailTo },
    });
  };

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!(templateData?.title ?? '')?.trim()) {
      errors.title = 'Label is required.';
    }
    if (!(templateData?.origin ?? '')?.trim()) {
      errors.origin = 'Origin is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleBlur = (e: any) => {
    validateFields();
  };

  const getAssetsData = async ({ assetsKeyArray }: { assetsKeyArray: string[] }) => {
    let assetDataObject: { [key: string]: { assetName: string; assetUrl: string } } = {};
    if (assetsKeyArray) {
      for (const appAssetsKey of assetsKeyArray) {
        try {
          const appAssetsElem = appSchema?.app?.assets?.[appAssetsKey];
          if (!appAssetsElem) {
            console.warn(`Asset not found for key: ${appAssetsKey}`);
            continue;
          }

          const endpoint = `manageFile/${appID}/assets/${appID}/${appAssetsElem.fileUrl}`;
          const response: any = await _supistaApiGetRawResponse(endpoint);

          if (!response?.ok) {
            console.error(`Failed to fetch asset from ${endpoint}`);
            continue;
          }

          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);
          assetDataObject[appAssetsElem.assetName] = {
            assetName: appAssetsElem.fileName || 'Sample.png',
            assetUrl: blobUrl,
          };
        } catch (error) {
          console.error(`Error processing asset key: ${appAssetsKey}`, error);
        }
      }
    }
    return assetDataObject;
  };
  const handleNext = async () => {
    if (validateFields()) {
      getAssetsData({ assetsKeyArray: templateData.assets ?? [] });
      setIsTemplateForm(false);
    }
  };
  const emailColumnArray = useMemo(() => {
    let columnList: { [key: string]: any }[] = [];
    let groupedColumnList: { [key: string]: any } = {};
    const connectedTableList = Object.keys(connectedTablesData ?? {}) ?? [];
    connectedTableList?.forEach((connectedTableKey: string) => {
      const connectedTableElem = connectedTablesData[connectedTableKey];
      const columnSchema = connectedTableElem?.columnSchema ?? [];
      const tableID = connectedTableElem?.tableID;
      let newColumnSchema: { [key: string]: any }[] = [];
      columnSchema.forEach((columnElem: any) => {
        if (columnElem?.dataType === erpAllDataType.text.key) {
          const finalColumnData = {
            name: columnElem.name,
            // type: columnElem.type,
            columnName: columnElem.columnName,
            uniqueColumnName: `${columnElem.columnName}.${tableID}`,
          };
          groupedColumnList = {
            ...groupedColumnList,
            [connectedTableKey]: [...(groupedColumnList[connectedTableKey] ?? []), finalColumnData],
          };
          newColumnSchema = [...newColumnSchema, finalColumnData];
        }
      });
      columnList = [...columnList, ...newColumnSchema];
    });

    return columnList;
  }, [connectedTablesData, templateData?.origin]);
  const attachmentArray = useMemo(() => {
    let columnList: { [key: string]: any }[] = [];
    Object.keys(componentSchema?.templates ?? {}).forEach((tamplateKey: any) => {
      const tamplateElem =
        componentSchema?.templates[tamplateKey as keyof typeof templateTypeObject];
      if (
        tamplateElem?.type !== templateTypeObject.pdf.key ||
        tamplateElem?.origin !== templateData?.origin
      ) {
        return;
      }

      columnList = [...columnList, { name: tamplateElem?.title, tamplateKey: tamplateKey }];
    });
    return columnList;
  }, [componentSchema?.templates, templateData?.origin]);

  const attachmentValidation = (attachmentElem: any) => {
    const isValidAttachment = attachmentArray?.some((attachment: any) => {
      return attachment?.tamplateKey === attachmentElem?.tamplateKey;
    });
    return { skip: !isValidAttachment };
  };
  const emailColumnValidation = (emailElem: any) => {
    const isValidAttachment = emailColumnArray?.some((attachment: any) => {
      return attachment?.uniqueColumnName === emailElem?.uniqueColumnName;
    });
    return { skip: !isValidAttachment };
  };

  return (
    <div className={styles.createTemplate}>
      <CreateTemplateHeader
        setIsTemplateSetting={setIsTemplateSetting}
        isNewTemplate={isNewTemplate}
        setIsTemplateForm={setIsTemplateForm}
        isTemplateForm={isTemplateForm}
        downloadPdfButtonRef={downloadPdfButtonRef}
        templateData={templateData}
        selectedTemplateID={selectedTemplateID}
        componentSchema={componentSchema}
        appDatatable={appDatatable}
        connectedTablesData={connectedTablesData}
        setTemplateData={setTemplateData}
        setComponentSchema={setComponentSchema}
        componentRowList={componentRowList}
        selectedId={selectedId}
        appSchema={appSchema}
        handleComponentChange={handleComponentChange}
        appID={appID}
        setSelectedAssets={setSelectedAssets}
        selectedAssets={selectedAssets}
        assetBlobs={assetBlobs}
      />
      <div className={styles.createTemplateContent}>
        {isTemplateForm ? (
          <>
            <div className={styles.createTemplateFormOuter}>
              <div className={styles.createTemplateForm}>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='formName'
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <Form.Label column sm={3}>
                    {mls('Name')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      name='name'
                      value={templateData?.title || ''}
                      onChange={handleTemplateName}
                      onBlur={handleBlur}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='formName'
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <Form.Label column sm={3}>
                    {mls('Description')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      name='name'
                      value={templateData?.description || ''}
                      onChange={handleTemplateDescription}
                      // onBlur={handleBlur}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {/* 
                <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                  <Form.Label column sm={3}>
                    {mls('Id')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as='select'
                      name='selectId'
                      value={selectedId}
                      onChange={(e) => handleComponentChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      isInvalid={!!errors.componentID}
                    >
                      <option value='' disabled>
                        {mls('Select Id')}
                      </option>
                      {React.Children.toArray(
                        (componentRowList ?? []).map((componentRowData: any) => {
                          return (
                            <option value={componentRowData?.id ?? ''} disabled={false}>
                              {componentRowData?.id ?? ''}
                            </option>
                          );
                        })
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.componentID}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group> */}
                <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                  <Form.Label column sm={3}>
                    {mls('Origin')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as='select'
                      name='origin'
                      value={templateData?.origin || ''}
                      onChange={(e) => handleOrigin(e)}
                      onBlur={(e) => handleBlur(e)}
                      isInvalid={!!errors.origin}
                    >
                      <option value='' disabled>
                        {mls('Select Origin')}
                      </option>
                      {React.Children.toArray(
                        Object.keys(connectedTablesData ?? {}).map((connectedTableKey: any) => {
                          const connectedTableElem =
                            connectedTablesData[
                              connectedTableKey as keyof typeof templateTypeObject
                            ];
                          if (
                            connectedTableElem?.relationType === relationTypeObjects.belongsTo.key
                          ) {
                            return null;
                          }
                          const finalTableKey = getSelectTableFinalKey({
                            tableId: connectedTableElem?.tableID,
                            relationID: connectedTableElem?.relationID,
                            innerRelationID: connectedTableElem?.innerRelationID,
                          });

                          return (
                            <option value={finalTableKey ?? ''} disabled={false}>
                              {connectedTableElem?.name ?? ''}
                            </option>
                          );
                        })
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>{errors.origin}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                  <Form.Label column sm={3}>
                    {mls('Type')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as='select'
                      name='type'
                      value={templateData?.type || ''}
                      onChange={(e) => handleTemplateType(e)}
                      onBlur={(e) => handleBlur(e)}
                      isInvalid={!!errors.type}
                    >
                      <option value='' disabled>
                        {mls('Select Type')}
                      </option>
                      {React.Children.toArray(
                        Object.keys(templateTypeObject ?? {}).map((tamplateKey: any) => {
                          const tamplateElem =
                            templateTypeObject[tamplateKey as keyof typeof templateTypeObject];
                          return (
                            <option value={tamplateElem?.key ?? ''} disabled={false}>
                              {tamplateElem?.name ?? ''}
                            </option>
                          );
                        })
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>{errors.type}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {templateTypeObject.email.key === templateData.type ? (
                  <>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='formName'
                      style={{ marginLeft: 0, marginRight: 0 }}
                    >
                      <Form.Label column sm={3}>
                        {mls('Subject')}:
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type='text'
                          name='subject'
                          value={templateData?.subject || ''}
                          onChange={(e) => handleSubject(e)}
                          onBlur={handleBlur}
                          isInvalid={!!errors.subject}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                      <Form.Label column sm={3}>
                        {mls('Attachment')}:
                      </Form.Label>
                      <Col sm={9}>
                        <MultiSelect
                          options={attachmentArray}
                          onSelect={handleAttachment}
                          onRemove={handleAttachment}
                          validation={attachmentValidation}
                          selectedValues={templateData?.attachment || []}
                          optionType={'objectInArray'}
                          mainKey={'tamplateKey'}
                          isSearch
                        />
                        <Form.Control.Feedback type='invalid'>{errors.type}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                      <Form.Label column sm={3}>
                        {mls('Email List')}:
                      </Form.Label>
                      <Col sm={9}>
                        <MultiSelect
                          options={emailColumnArray}
                          onSelect={handleEmailTo}
                          onRemove={handleEmailTo}
                          validation={emailColumnValidation}
                          selectedValues={templateData?.emailList?.to || []}
                          optionType={'objectInArray'}
                          mainKey={'uniqueColumnName'}
                          isSearch
                        />
                      </Col>
                    </Form.Group> */}
                  </>
                ) : null}

                <div className={styles.nextButtonDiv}>
                  <button
                    type='button'
                    className='primaryButton secondaryButtonColor'
                    onClick={() => handleNext()}
                  >
                    {isDataFetching ? (
                      <>
                        <CircularProgress color='inherit' size={10} /> &nbsp;
                      </>
                    ) : null}
                    {mls('Next')}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <EmailTemplate
            downloadPdfButtonRef={downloadPdfButtonRef}
            connectedTablesData={connectedTablesData}
            templateData={templateData}
            componentSchema={componentSchema}
            setTemplateData={setTemplateData}
            appID={appID}
            sampleData={sampleData}
            selectedId={selectedId}
            appSchema={appSchema}
            assetsObject={assetsObject}
            inputData={inputData}
            setInputData={setInputData}
          />
        )}
      </div>
    </div>
  );
};

export default CreateTemplate;
