import React, { useEffect, useState } from 'react';
import Board from './Board';
import { _supistaApiGet, _supistaApiPost, _supistaApiPut } from 'lib/server-connection/connections';
import styles from './kanban.module.scss';
import KanbanHeader from './KanbanHeader';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import { convertFilter } from 'lib/reusable-components/reusableFunction/convertFilter';
import useOperations from 'lib/reusable-components/hooks/fetchERPdata/useOperations';

interface kanbanCompProps {
  component: any;
  appID: string;
  parentRowID?: any;
  relationSchema?: any;
}
const initialData = {
  tasks: {},
  columns: {},
  columnOrder: [],
};
const idValue = null;
const isCreate = false;
const KanbanComp = ({
  component,
  appID,
  parentRowID = null,
  relationSchema = {},
}: kanbanCompProps) => {
  const [boardData, setBoardData] = useState<any>(initialData);
  const [finalFilter, setFinalFilter] = useState({});

  const {
    // isLoading,
    // error,
    data,
    // filterData,
    // refetch,
    setFilterData,
    // createData,
    // updateData,
    // deleteData,
    // downloadCSV,
    // uploadCSV,
  } = useOperations(component, idValue, isCreate, relationSchema, parentRowID);

  const [paginationData, setPaginationData] = useState({
    limit: 10,
    offset: 0,
    count: 0,
  });
  useEffect(() => {
    const kanbanSetting = component?.kanbanSetting ?? {};
    const boardField = kanbanSetting?.boardField ?? '';
    const titleField = kanbanSetting?.titleField ?? '';
    const boardOrder = kanbanSetting?.boardOrder ?? [];
    let initialData: { [key: string]: any } = {
      tasks: {},
      columns: {},
      columnOrder: [],
    };
    if (data?.result?.rows) {
      setPaginationData((currentData: any) => ({
        ...currentData,
        count: data?.result?.count,
      }));
      const rowData = data?.result?.rows ?? [];
      if (component?.kanbanSetting?.boardField) {
        initialData = rowDataToKanbanData({
          rowData: rowData,
          boardField: boardField,
          titleField: titleField,
          boardOrder: boardOrder,
        });
      }
      setBoardData(initialData);
    }
  }, [data]);

  useEffect(() => {
    const convertedFilter = convertFilter(finalFilter, '');
    let urlData: any = {
      __d3__filterdata: {
        where: convertedFilter,
        offset: paginationData.offset,
        limit: paginationData.limit,
      },
    };
    setFilterData(urlData.__d3__filterdata);
  }, [paginationData.limit, paginationData.offset, finalFilter, setFilterData]);

  const updateData = async ({
    id,
    updatedData,
  }: {
    id: number;
    updatedData: { [key: string]: any };
  }) => {
    const componentID = component?.componentID;
    const baseUrl = `app/${appID}/CRUD/${componentID}`;
    const currentTimestamp = Date.now();
    const data = {
      data: {
        __d3__newData: {
          ...updatedData,
          __d3__updatedAt: currentTimestamp,
        },
        __d3__id: id,
      },
      lang: 'English',
    };
    const componentRes = await _supistaApiPut(baseUrl, data);
  };
  const handleComponentsUpdate = async ({ componentSchema }: { componentSchema: any }) => {
    const apiUrl = `settings/${appID}/Components`;
    const payloadData = {
      data: {
        ...componentSchema,
        updatedAt: Date.now(),
      },
    };
    const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
    if (componenetUpdateRes.__d3__success) {
      await publishToProduction(appID);
      return { skip: false, response: componenetUpdateRes?.response };
    }
    return { skip: true };
  };

  return (
    <div className={styles.KanbanComp}>
      <KanbanHeader
        component={component}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        setFinalFilter={setFinalFilter}
      />
      <Board
        boardData={boardData}
        setBoardData={setBoardData}
        component={component}
        updateData={updateData}
        handleComponentsUpdate={handleComponentsUpdate}
      />
    </div>
  );
};

export default KanbanComp;
const rowDataToKanbanData = ({
  rowData,
  boardField,
  boardOrder,
}: {
  rowData: any[];
  boardField: string;
  titleField: string;
  boardOrder: any[];
}) => {
  let tasks = {};
  let columns: { [key: string]: any } = {};
  (rowData ?? []).forEach((rowDataElem) => {
    tasks = {
      ...tasks,
      [rowDataElem?.id]: { ...rowDataElem },
    };
    if (rowDataElem[boardField]) {
      columns = {
        ...columns,
        [rowDataElem[boardField]]: {
          id: rowDataElem[boardField],
          title: rowDataElem[boardField],
          taskIds: [...(columns?.[rowDataElem[boardField]]?.taskIds ?? []), rowDataElem?.id],
        },
      };
    }
  });
  const initialData = {
    tasks: tasks,
    columns: columns,
    columnOrder: boardOrder,
  };
  return initialData;
};
