import React, { useState } from 'react';
import { Drawer, IconButton } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import CloseIcon from '@mui/icons-material/Close';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';

function ProjectOrder({ show, setShow, projects, apps, appID }) {
  const toastMsg = useMessageHandling();
  const [projectsList, setProjectsList] = useState(projects || []);
  // a little function to help us with reordering the result

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(projectsList, result.source.index, result.destination.index);
    setProjectsList(items);
  };

  const handleDelete = (id, ix) => {
    const newChartIDs = [...projectsList];
    newChartIDs.splice(ix, 1);
    setProjectsList(newChartIDs);
  };

  const handleSave = async () => {
    try {
      const res = await _supistaApiPost(`settings/${appID}/App`, {
        data: {
          ...apps,
          projects: projectsList,
        },
      });
      if (!res.__d3__error) {
        await publishToProduction(appID);
      }
    } catch (err) {
      toastMsg(
        reduxConstants.config.APP_STATUS.ERROR_TOAST,
        mls('There was a problem while updating modules order, please try again later!'),
        5,
        true
      );
    }
    setShow(false);
    toastMsg(
      reduxConstants.config.APP_STATUS.TOAST,
      `${mls('Project order updated successfully')}`,
      5,
      true
    );
  };
  return (
    <>
      <Drawer anchor='right' open={show} onClose={() => setShow(false)}>
        {/* begin::Card  */}
        <div className='card shadow-none w-100' style={{ minWidth: '50vh' }}>
          {/* begin::Header */}
          <div className='card-header' id='kt_explore_header'>
            <h5 className='card-title fw-bold text-gray-600'>{mls('Edit Modules List')}</h5>
            <div className='d-flex'>
              <div className='d-flex align-items-stretch flex-shrink-0'>
                <div className='d-flex flex-stack'>
                  <button className='btn btn-sm btn-light-primary me-3' onClick={handleSave}>
                    {mls('Save')}
                  </button>

                  <IconButton aria-label='close' onClick={() => setShow(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>

            {/* <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_explore_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div> */}
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body' id='kt_explore_body'>
            {/* begin::Content */}
            <div
              className='scroll-y me-n5 pe-5 pt-4'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-offset='5px'
              style={{ height: '90vh' }}
            >
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {projectsList.map((projectSchema, index) => (
                        <>
                          {
                            <Draggable
                              key={projectSchema.projectID}
                              draggableId={projectSchema.projectID}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style
                                  // )}
                                >
                                  <div key={projectSchema.projectID}>
                                    <div className='d-flex flex-stack mb-5'>
                                      <span className='d-flex align-items-center me-2'>
                                        <span
                                          className='symbol symbol-50px me-6'
                                          style={{ cursor: 'grab' }}
                                        >
                                          {/* <i className='bi bi-distribute-vertical fs-2x'></i> */}
                                          <img
                                            src='/media/icons/drag.svg'
                                            style={{ width: 32 }}
                                            alt='Drag and rearrange projects'
                                          />
                                        </span>

                                        <span className='d-flex flex-column me-6'>
                                          <span className='fw-bolder fs-6'>
                                            {mls(projectSchema?.name)}
                                          </span>
                                        </span>
                                      </span>
                                      <IconButton
                                        onClick={() =>
                                          handleDelete(projectSchema?.projectID, index)
                                        }
                                      >
                                        {/* <span> */}
                                        <i
                                          className='bi bi-trash-fill symbol-50 text-hover-danger'
                                          style={{ fontSize: '1.1rem' }}
                                        ></i>
                                        {/* </span> */}
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          }
                        </>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {/* end::Content */}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Card */}
      </Drawer>
    </>
  );
}

export default ProjectOrder;
