import {
  generateShortUUID,
  getIDColumnName,
} from 'lib/erp-data-table/components/util/utilFunctions';
import { relationTypesObject } from 'lib/erp-data-table/components/data/erpData';
import { mls } from 'lib/multilanguagesupport';

interface updateRelatedTableSchemaProps {
  allDataTableData: any;
  appID: string;
  mainTableName: string;
  tableUpdates: any;
  setTableUpdates: Function;
  setRelatedTableList: Function;
  relatedTableList: any;
  relationData: any;
  action: any;
  dataTableSchema: any;
  updatedRelationData: any;
  setRelations: Function;
  setDataTableSchema: Function;
}

export const updateRelatedTableSchema = ({
  allDataTableData,
  appID,
  mainTableName,
  tableUpdates,
  setTableUpdates,
  setRelatedTableList,
  relatedTableList,
  relationData,
  action,
  dataTableSchema,
  updatedRelationData,
  setRelations,
  setDataTableSchema,
}: updateRelatedTableSchemaProps) => {
  let newUuidForSameRelation = generateShortUUID({});
  let newColumnSchema: { [key: string]: any }[] = [];
  let junctionTable: { [key: string]: any } = {};
  let inverseType = '';
  let inverseForeignKey = '';

  let extraRelation = {}; // For BelongsToMany
  let errorData = { error: false, errorMsg: '' }; // For Handling Error

  let skipRelation = false; // For Skip Inverse Relation Creation
  let newTableUpdate = { ...tableUpdates };
  let isTableUpdateChange = false;
  let newDataTableSchema = { ...dataTableSchema };
  let isDataTableSchemaChange = false;
  let newUpdatedRelationData = { ...updatedRelationData };
  let isUpdatedRelationDataChange = false;

  const targetTableName = relationData.target;
  const relationName = relationData?.relationName;
  const sameTableRelation = dataTableSchema?.tableID === targetTableName;
  let targetTableSchema = {
    ...(relatedTableList?.[targetTableName] || allDataTableData?.[targetTableName]),
  };
  const relations = { ...targetTableSchema.relations };

  if (
    relationData.type === relationTypesObject.hasMany.value ||
    relationData.type === relationTypesObject.hasOne.value
  ) {
    inverseType = relationTypesObject.belongsTo.value;
    inverseForeignKey = relationName;
    newColumnSchema = [
      {
        name: `${inverseForeignKey}`,
        columnName: inverseForeignKey,
        dataType: relationTypesObject.belongsTo.value,
        relationId: sameTableRelation ? newUuidForSameRelation : relationData.as,
      },
    ];
  }
  if (relationData.type === relationTypesObject.belongsTo.value) {
    inverseType = relationTypesObject.hasMany.value;
    inverseForeignKey = `id`;
  }
  if (relationData.type === relationTypesObject.belongsToMany.value) {
    inverseType = relationTypesObject.belongsToMany.value;
    inverseForeignKey = getIDColumnName(targetTableName);
    const tableName = relationData.through;
    junctionTable = {
      tableID: relationData.through,
      appID,
      tableType: 'junction',
      description: `Junction Table for Many to Many Relation form Between Tables ${mainTableName} and ${relationData.target}. with relation name ${relationData.relationName}`,
      name: tableName,
      updates: {
        renameColumns: {},
        createTable: true,
        addColumns: [],
        updateColumns: [],
        deleteColumns: [],
        dropTable: false,
      },
      columnSchema: [
        {
          name: `${relationData.otherKey}`,
          columnName: relationData.otherKey,
          dataType: 'number',
        },
      ],
    };
    if (!sameTableRelation) {
      junctionTable = {
        ...junctionTable,
        columnSchema: [
          ...(junctionTable?.columnSchema ?? []),
          {
            name: `${relationData.foreignKey}`,
            columnName: relationData.foreignKey,
            dataType: 'number',
          },
        ],
      };
    }
  }
  // Determine the inverse relation key and data
  let inverseRelationKey = relationData.as;
  if (sameTableRelation) {
    if (
      relationData?.type === relationTypesObject.hasMany.value ||
      relationData?.type === relationTypesObject.hasOne.value
    ) {
      inverseRelationKey = newUuidForSameRelation;
    }
    if (relationData?.type === relationTypesObject.belongsTo.value) {
      inverseRelationKey = newUuidForSameRelation;
    }
  }
  const inverseRelationData: { [key: string]: any } = {
    as: inverseRelationKey,
    type: inverseType,
    foreignKey: inverseForeignKey,
    relationName: relationData.relationName,
    target: mainTableName,
  };

  // If the relation type is belongsToMany, add extra properties
  if (relationData.type === relationTypesObject.belongsToMany.value) {
    inverseRelationData.through = relationData.through;
    inverseRelationData.otherKey = getIDColumnName(mainTableName);
    inverseForeignKey = relationName;
  }
  // Update the target table schema
  if (action === 'add') {
    relations[inverseRelationKey] = inverseRelationData;

    if (
      relationData?.type === relationTypesObject.hasMany.value ||
      relationData?.type === relationTypesObject.hasOne.value
    ) {
      if (dataTableSchema?.tableID === targetTableSchema?.tableID) {
        if (
          !(dataTableSchema?.columnSchema || []).some(
            (obj: { [key: string]: any }) => obj.columnName === newColumnSchema[0].columnName
          )
        ) {
          const updatedColumnSchema = [
            ...newColumnSchema,
            ...(dataTableSchema?.columnSchema ?? {}),
          ];

          const updatedAddColumns = [...(tableUpdates?.addColumns ?? []), ...newColumnSchema];

          newTableUpdate = { ...newTableUpdate, addColumns: updatedAddColumns };
          isTableUpdateChange = true;
          newDataTableSchema = { ...newDataTableSchema, columnSchema: updatedColumnSchema };
          isDataTableSchemaChange = true;
        } else {
          skipRelation = true;
          errorData = { error: true, errorMsg: mls("Couldn't able to create Reation!") };
        }
      } else if (
        !(targetTableSchema?.columnSchema || []).some(
          (obj: { [key: string]: any }) => obj.columnName === newColumnSchema[0].columnName
        )
      ) {
        const updatedColumnSchema = [
          ...(targetTableSchema?.columnSchema ?? {}),
          ...newColumnSchema,
        ];

        const updatedAddColumns = [
          ...(targetTableSchema?.updates?.addColumns ?? []),
          ...newColumnSchema,
        ];

        targetTableSchema = {
          ...targetTableSchema,
          columnSchema: updatedColumnSchema,
          updates: {
            ...(targetTableSchema?.updates ?? {}),
            createTable: false,
            addColumns: updatedAddColumns,
          },
        };
      } else {
        skipRelation = true;
        errorData = { error: true, errorMsg: mls("Couldn't able to create Reation!") };
      }
    }
    if (
      relationData?.type === relationTypesObject.belongsTo.value ||
      relationData?.type === relationTypesObject.belongsToMany.value
    ) {
      targetTableSchema = {
        ...targetTableSchema,
        updates: {
          ...(targetTableSchema?.updates ?? {}),
          createTable: false,
        },
      };
    }
  } else if (action === 'update') {
    relations[inverseRelationKey] = inverseRelationData;
  } else if (action === 'remove') {
    delete relations[inverseRelationKey];
    let isAddColumnDelete = false;
    let isNormalColumnDelete = false;
    if (
      relationData?.type === relationTypesObject.hasMany.value ||
      relationData?.type === relationTypesObject.hasOne.value
    ) {
      const updatedColumnSchema = (targetTableSchema?.columnSchema || []).filter(
        (obj: { [key: string]: any }) => {
          const isNotFound = obj.columnName !== newColumnSchema[0].columnName;
          if (!isNotFound) {
            isNormalColumnDelete = true;
          }
          return isNotFound;
        }
      );
      const updatedAddColumns = (targetTableSchema?.updates?.addColumns || []).filter(
        (obj: { [key: string]: any }) => {
          const isNotFound = obj.columnName !== newColumnSchema[0].columnName;
          if (!isNotFound) {
            isAddColumnDelete = true;
          }
          return isNotFound;
        }
      );
      let updatedDeleteColumns = targetTableSchema?.updates?.deleteColumns ?? [];

      if (!isAddColumnDelete && isNormalColumnDelete) {
        updatedDeleteColumns = [...updatedDeleteColumns, ...newColumnSchema];
      }
      targetTableSchema = {
        ...targetTableSchema,
        columnSchema: updatedColumnSchema,
        updates: {
          ...(targetTableSchema?.updates ?? {}),
          createTable: false,
          addColumns: updatedAddColumns,
          deleteColumns: updatedDeleteColumns,
        },
      };
    }
  }
  targetTableSchema = {
    ...targetTableSchema,
    relations: relations,
  };
  if (sameTableRelation) {
    if (
      relationData?.type === relationTypesObject.hasMany.value ||
      relationData?.type === relationTypesObject.hasOne.value ||
      relationData?.type === relationTypesObject.belongsTo.value
    ) {
      newUpdatedRelationData = {
        ...newUpdatedRelationData,
        [inverseRelationKey]: inverseRelationData,
      };
      isUpdatedRelationDataChange = true;
      skipRelation = true;
    }
    if (relationData?.type === relationTypesObject.belongsToMany.value) {
      skipRelation = true;
    }
  }
  if (relationData.type === relationTypesObject.belongsToMany.value) {
    if (!Object.keys(allDataTableData).includes(junctionTable.name)) {
      extraRelation = { ...extraRelation, [junctionTable.tableID]: junctionTable };
    } else {
      skipRelation = true;
      errorData = {
        error: true,
        errorMsg: mls("Couldn't able to create Reation JunctionTable Already Exist!"),
      };
    }
  }
  if (!skipRelation) {
    setRelatedTableList((prevSchema: { [key: string]: any }) => {
      return {
        ...prevSchema,
        [targetTableName]: targetTableSchema,
        ...extraRelation,
      };
    });
  }
  if (isTableUpdateChange) {
    setTableUpdates(newTableUpdate);
  }
  if (isDataTableSchemaChange) {
    setDataTableSchema(newDataTableSchema);
  }
  if (isUpdatedRelationDataChange) {
    setRelations(newUpdatedRelationData);
  }

  return { skipRelation, errorData };
};
