import React, { useState } from 'react';

import clsx from 'clsx';
import { selectOptionErrorCheck } from './selectMenuUtils';
// import SolidColorSelector, { SolidColorPopupWrapper } from './SolidColorSelector';
// import styles from './solidColorSelecor.module.scss';
import { SolidColorPopupWrapper } from 'lib/reusable-components/Components/SolidColorSelector/SolidColorSelector';
import { selectMenuColorPalettes } from './selectMenuColors';
import { MdOutlineDragHandle } from 'react-icons/md';
import styles from './selectMenu.module.scss';

const typeOption = {
  text: {
    key: 'text',
    name: 'Text',
  },
  number: {
    key: 'number',
    name: 'Number',
  },
  decimal: {
    key: 'decimal',
    name: 'Decimal',
  },
};
const OptionField = ({
  optionData,
  handleChangeOption,
  updateOptionData,
  handleDelete = () => {},
  isDeleteOption = false,
  selectMenu,
  provided,
  dragable = false,
  columnData,
}: {
  optionData: any;
  handleChangeOption: Function;
  updateOptionData: Function;
  handleDelete?: Function;
  isDeleteOption?: boolean;
  selectMenu?: any;
  provided?: any;
  dragable?: boolean;
  columnData: { [key: string]: any };
}) => {
  const [tempValue, seTempValue] = useState(optionData.value);
  const [isColorPopup, setIsColorPopup] = useState(false);
  const type = columnData?.options?.type ?? typeOption.text.key;

  const updateValue = () => {
    //  handleChangeOption(e, 'label');
    const validationErrorData = selectOptionErrorCheck({
      field: 'value',
      value: tempValue,
      validation: optionData,
      selectMenu,
      originalValue: optionData.value,
    });
    let isError = false;
    isError = validationErrorData.isError;
    let udpatedOptionData = optionData;
    if (validationErrorData.isError) {
      udpatedOptionData = validationErrorData.validtionData;
    } else {
      udpatedOptionData = validationErrorData.validtionData;
    }

    if (isError) {
      seTempValue(optionData.value);
      updateOptionData({ optionData: { ...udpatedOptionData, value: optionData.value } });
    } else {
      const updatedValue =
        type === typeOption.text.key ? udpatedOptionData.value : Number(udpatedOptionData.value);
      updateOptionData({
        optionData: { ...udpatedOptionData, label: udpatedOptionData.value, value: updatedValue },
      });
    }
  };

  return (
    <div
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      style={{
        ...provided?.draggableProps?.style,
      }}
      className={styles.optionFieldContainer}
    >
      <div className={styles.optionFieldInnerContainer}>
        {dragable ? (
          <div className={styles.dragIconOuter} {...(provided?.dragHandleProps ?? {})}>
            <MdOutlineDragHandle className={styles.dragIcon} />
          </div>
        ) : null}
        <div className={styles.optionFieldInputContainer}>
          <div className={isDeleteOption ? 'd-flex justify-content-between mt-1' : 'row mt-1'}>
            <div
              className='col-4'
              style={isDeleteOption ? { marginRight: 20, marginLeft: -10 } : {}}
            >
              <div className='d-flex align-items-center'>
                {isDeleteOption ? (
                  <i
                    onClick={() => handleDelete()}
                    className='fas fa-trash-alt text-danger'
                    style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
                  ></i>
                ) : null}
                <input
                  // onChange={(e) => handleChangeOption(e, 'message')}
                  onChange={(e) => seTempValue(e.target.value)}
                  onBlur={() => {
                    updateValue();
                  }}
                  placeholder='Value'
                  className={clsx('form-control form-control-lg ')}
                  type={type === typeOption.text.key ? 'text' : 'number'}
                  name='value'
                  autoComplete='off'
                  value={tempValue}
                  // defaultValue={optionData.value}
                />
              </div>
            </div>
            <div
              className='col-4'
              style={isDeleteOption ? { marginRight: 10, marginLeft: -10 } : {}}
            >
              <input
                onChange={(e) => handleChangeOption(e, 'label')}
                placeholder='Label'
                className={clsx('form-control form-control-lg')}
                type='text'
                name='label'
                autoComplete='off'
                defaultValue={optionData.label}
              />
            </div>
            <div
              className='col-4'
              onClick={() => {
                setIsColorPopup(!isColorPopup);
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: ' 100%',
                    justifyContent: 'center',
                    padding: 0,
                    background: '#F5F8FA',
                  }}
                  className={`${clsx('form-control form-control-lg ')}`}
                >
                  <SolidColorPopupWrapper
                    color={optionData}
                    colorPalette={selectMenuColorPalettes}
                    setColor={(e: any) => {
                      handleChangeOption({ target: { value: e?.color } }, 'color');
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {optionData.error && <div className='col-12 text-danger mt-1'>{optionData.error}</div>}
    </div>
  );
};

export default OptionField;
