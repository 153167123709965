import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  dateConditionData,
  defaultDataType,
  defaultDataTypeStyles,
  defaultDateArray,
  defaultFilterIDData,
  fiterIdConditon,
  fiterIdConditonArray,
  numberConditionData,
  textConditionData,
} from './data/defaultFilterSchema';
import useDebounce from './hooks/useDebounce';
import useUpdateEffect from './hooks/useUpdateEffect';

import PopUpDiv from './features/PopUpDiv/PopUpDiv';
import MultiselectWithApi from './features/MultiselectWithApi/MultiselectWithApi';

import boxDropDownIcon from './assets/boxDropDownIcon.svg';

import {
  chartFilterPopulation,
  filterByTableName,
  mergeDashboardSchema,
  specialCaseSchema,
  tableFilterFuntion,
} from './utils/filterHelperFunctions';

import { AllTables } from './FilterSelection';
import DatePicker from './DatePicker';
import { RiFilter3Fill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';

import './filterDiv.scss';
import './scroll.scss';
import NestedDropDownObject from './features/DropDownObject/NestedDropDownObject';
import { filtersSchema } from './utils/filterHelperFunctions';
import { AiFillInfoCircle } from 'react-icons/ai';
import { mls } from 'lib/multilanguagesupport';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import UserLevelAccessTable from './UserLevelAccessTable';
import { useSelector } from 'react-redux';
import { relationTypeObjects } from '../data/appComponentData';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';
const filterConditionType = {
  static: {
    name: 'Static',
    key: 'static',
  },
  dynamic: {
    name: 'Dynamic',
    key: 'dynamic',
  },
};
const RoleComponentFilter = (props) => {
  const {
    setChartFilter,
    allTableData,
    chartFilter,
    appID,
    isJoinedTable = false,
    sheetID = '',
    isPopUp,
    setIsPopUp,
    isDashboard = false,
    isDataSource = false,
    specialCase = false,
    isUserLevelAccess = false,
    filterDescription = mls('Filter Description'),
  } = props;

  const finalTableData = useMemo(() => {
    if (isDashboard) {
      return mergeDashboardSchema(allTableData);
    }
    if (specialCase) {
      return specialCaseSchema(allTableData);
    }

    return allTableData;
  }, [allTableData]);

  const [selectedColumns, setSelectedColumns] = useState(
    chartFilterPopulation({
      chartWidthObject: chartFilter,
      allTableData: finalTableData,
      isDashboard,
    })
  );

  const [tableFilter, settableFilter] = useState({ search: '', type: defaultDataType.ALL });
  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const userInfoArray = Object.keys(userSchema?.allRoles?.[appID]?.userInfo ?? {});

  const [filterdTableSchema, setFilterdTableSchema] = useState(finalTableData);
  const toggleDiv = useRef(null);
  const filterselection = useRef(null);
  const handleToggle = () => {
    toggleDiv?.current.classList.toggle('open');
    filterselection?.current.classList.toggle('open');
  };
  const handlePopUp = (action) => {
    setFilterdTableSchema(finalTableData);
    settableFilter({ search: '', type: defaultDataType.ALL });
    setIsPopUp(action);
  };

  const handleSearch = (value) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: finalTableData,
        setFilterdTableSchema,
        ...currentValue,
        search: value,
      });

      return { ...currentValue, search: value };
    });
  };
  const handleTypeFilter = (e) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: finalTableData,
        setFilterdTableSchema,
        ...currentValue,
        type: e,
      });
      return { ...currentValue, type: e };
    });
  };

  const { newAllTableData, mainTableID } = useMemo(() => {
    const { mainTableID, groupedArray } = filterByTableName(filterdTableSchema, isDashboard);
    return { newAllTableData: groupedArray, mainTableID: mainTableID };
  }, [filterdTableSchema]);

  const handleColumnSelection = (selectedItems) => {
    const newSelectedData = [...selectedColumns, selectedItems];
    setSelectedColumns(newSelectedData);
  };

  const handleRemoveColumnSelection = (item) => {
    const newArray = selectedColumns.filter((obj) => {
      if (isDashboard) {
        return obj.uniqueDashboardID !== item.uniqueDashboardID;
      }
      return `${obj.tableName},${obj.columnName}` !== `${item.tableName},${item.columnName}`;
    });
    setSelectedColumns(newArray);
  };
  const clearFilter = () => {
    setChartFilter({});
    setSelectedColumns([]);
  };

  useUpdateEffect(() => {
    const filters = filtersSchema({
      filterArray: selectedColumns,
      sheetID,
      isDashboard,
      isDataSource,
    });

    setChartFilter(filters);
  }, [selectedColumns]);
  useEffect(() => {
    setFilterdTableSchema(finalTableData);
  }, [finalTableData]);
  return (
    <>
      <PopUpDivChild
        initialValue={isPopUp}
        setPopUp={handlePopUp}
        className={'AppComponentFilter'}
        popupTitle={mls('Filters')}
      >
        {/* <div className='filterPopupDescription'>{mls('Filter Description')}</div> */}
        {filterDescription ? (
          <div className='filterPopupDescription'>{filterDescription}</div>
        ) : null}
        <div className='toggleDiv'>
          <div className='toggleIcon' onClick={() => handleToggle()} ref={toggleDiv}>
            <img src={boxDropDownIcon} alt='' />
          </div>
          <div className='toggleFilterName'>{mls('Filters')}</div>
        </div>
        <div className='filterDivContainer'>
          <div className='filterselection' ref={filterselection}>
            <div className='staticSection'>
              <div className='mainTitleDiv'>
                <div className='toggleFilterName'>{mls('Columns')}</div>
                <span onClick={() => clearFilter()}>{mls('Clear Filters')}</span>
              </div>
              <div className='serachBoxOuter'>
                <div className='searchBox'>
                  <SearchInputDiv value={tableFilter.search} onChange={handleSearch} />
                </div>
                <NestedDropDownObject
                  object={defaultDataTypeStyles}
                  dropDownValue={tableFilter.type}
                  setdropDownValue={handleTypeFilter}
                  swithToIcon={true}
                />
              </div>
            </div>
            <div className='allTable'>
              {isUserLevelAccess ? (
                <UserLevelAccessTable
                  selectedColumns={selectedColumns}
                  mainTableID={mainTableID}
                  setSelectedColumns={setSelectedColumns}
                />
              ) : null}
              <AllTables
                isDashboard={isDashboard}
                newAllTableData={newAllTableData}
                tableFilter={tableFilter}
                selectedColumns={selectedColumns}
                handleColumnSelection={handleColumnSelection}
                handleRemoveColumnSelection={handleRemoveColumnSelection}
              />
            </div>
          </div>
          <div className='filterEditing'>
            {selectedColumns[0] == null ? (
              <div className='selectAnyFilter'>
                <div className='innerBox'>{mls('Select Any Filter')}</div>
              </div>
            ) : null}
            {React.Children.toArray(
              selectedColumns.map((elem, i) => {
                if (elem.dataType === defaultDataType.number) {
                  return (
                    <FilterItemsNUM
                      // elem={{
                      //   ...elem,
                      //   value: { ...elem.value, min: 50, max: 50 },
                      //   filterData: {
                      //     ...elem.filterData,
                      //     range: { ...elem.filterData.range, min: 50, max: 50 },
                      //   },
                      // }}
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.text) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.select) {
                  return (
                    <FilterItemsSelect
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }

                if (elem.dataType === defaultDataType.datePicker) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.NUM) {
                  return (
                    <FilterItemsNUM
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }

                if (elem.dataType === defaultDataType.CAT) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }

                if (elem.dataType === defaultDataType.DATETIME) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.date) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.time) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.dateTime) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.ID_TEXT) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.ID_NUM) {
                  return (
                    <FilterItemsNUM
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.ID) {
                  return (
                    <FilterItemsID
                      elem={elem}
                      selectedColumns={selectedColumns}
                      userInfoArray={userInfoArray}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.belongsTo) {
                  return (
                    <FilterItemsID
                      elem={elem}
                      selectedColumns={selectedColumns}
                      userInfoArray={userInfoArray}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (
                  elem.dataType === defaultDataType.user ||
                  elem.dataType === defaultDataType.multipleUsers
                ) {
                  return (
                    <FilterItemsUser
                      elem={elem}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.userLevelAccess) {
                  return (
                    <FilterItemsUserLevelAccess
                      elem={elem}
                      tableData={newAllTableData}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      index={i}
                    />
                  );
                }

                if (
                  (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType) ===
                  defaultDataType.text
                ) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }

                if (
                  (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType) ===
                  defaultDataType.date
                ) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (
                  (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType) ===
                  defaultDataType.number
                ) {
                  return (
                    <FilterItemsNUM
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (
                  (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType) ===
                  defaultDataType.boolean
                ) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }
                if (
                  (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType) ===
                  defaultDataType.ID
                ) {
                  return (
                    <FilterItemsID
                      elem={elem}
                      selectedColumns={selectedColumns}
                      userInfoArray={userInfoArray}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                return null;
              })
            )}
          </div>
        </div>
      </PopUpDivChild>
    </>
  );
};
export default RoleComponentFilter;

const FilterItemsID = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  handleRemoveColumnSelection,
  userInfoArray,
}) => {
  const [conditionData, setConditionData] = useState(elem?.value ?? defaultFilterIDData);
  const [idConditions, setIdConditions] = useState(
    Object.keys(elem?.value?.condition ?? defaultFilterIDData)
  );

  useUpdateEffect(() => {
    const getData = updateData(selectedColumns, index, conditionData);
    setSelectedColumns(getData);
  }, [conditionData]);

  useEffect(() => {
    setConditionData(elem?.value ?? defaultFilterIDData);
    setIdConditions(Object.keys(elem?.value ?? defaultFilterIDData));
  }, [`${elem?.columnName}.${elem?.tableName}`]);

  const handleIdCondition = (action) => {
    const isRemove = action?.isRemove;
    const target = action?.target;
    if (!isRemove) {
      if (target?.key !== fiterIdConditon?.accessTo?.key) {
        const targetData = {
          key: target?.key,
          value: [1],
        };
        setConditionData({ ...conditionData, [target?.key]: targetData });
      } else {
        const targetData = {
          key: target?.key,
          value: [],
        };
        setConditionData({ ...conditionData, [target?.key]: targetData });
      }
    } else {
      const newData = { ...conditionData };
      delete newData[target?.key];
      setConditionData(newData);
    }
  };
  const updateConditionDataValue = ({ conditionKey, newValue }) => {
    const targetData = {
      ...(conditionData?.[conditionKey] ?? {}),
      value: newValue,
    };
    setConditionData({ ...conditionData, [conditionKey]: targetData });
  };
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <MultiSelect
        options={fiterIdConditonArray}
        selectedValues={idConditions}
        onSelect={setIdConditions}
        onRemove={setIdConditions}
        onUpdate={handleIdCondition}
        optionType={'objectInArray'}
      />
      <hr />
      <div className='idTypeOuterContainer'>
        {React.Children.toArray(
          Object.keys(conditionData ?? {})?.map((conditionKey) => {
            const idConditionData = fiterIdConditon[conditionKey];
            const conditionElem = conditionData?.[conditionKey];
            if (conditionElem?.key === fiterIdConditon?.accessTo?.key) {
              return (
                <div className='idTypeContainer'>
                  <div className='idTypeContainerTitle'>{mls(idConditionData.subName ?? '')}</div>
                  <MultiSelect
                    options={userInfoArray}
                    selectedValues={conditionElem?.value ?? []}
                    onSelect={(newValue) => {
                      updateConditionDataValue({ conditionKey: conditionKey, newValue: newValue });
                    }}
                    onRemove={(newValue) => {
                      updateConditionDataValue({ conditionKey: conditionKey, newValue: newValue });
                    }}
                  />
                </div>
              );
            }
            const handleChecked = ({ checked, value }) => {
              let newValue = [...(conditionElem?.value ?? [])];
              if (checked) {
                newValue = [...(conditionElem?.value ?? []), value];
              } else {
                newValue = (conditionElem?.value ?? [])?.filter((elem) => elem !== value);
              }
              updateConditionDataValue({ conditionKey: conditionKey, newValue: newValue });
            };
            return (
              <div className='idTypeContainer'>
                <div className='idTypeContainerTitle'>{idConditionData.subName}</div>
                <div className='idTypeContainerCheckBoxContainer'>
                  {[...new Array(5)].map((_, i) => {
                    const isChecked = (conditionElem?.value ?? [])?.some((elem) => elem === i + 1);
                    return (
                      <div
                        className='idTypeContainerCheckBoxElem'
                        onClick={() => handleChecked({ checked: !isChecked, value: i + 1 })}
                      >
                        <CheckBox checked={isChecked} onClick={() => {}} />
                        {i + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
const FilterItemsNUM = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  handleRemoveColumnSelection,
}) => {
  const [filterValueNUM, setFilterValueNUM] = React.useState([
    elem?.value?.min ?? elem?.filterData?.range?.min ?? 0,
    elem?.value?.max ?? elem?.filterData?.range?.max ?? 0,
  ]);
  const [conditionData, setConditionData] = useState(
    elem?.value?.condition ?? numberConditionData.inRange.key
  );
  const [selectedData, setSelectedData] = useState(elem?.value ?? {});
  const [numberValue, setNumberValue] = useState(elem?.value?.value ?? 0);
  const debounceValue = useDebounce(filterValueNUM);
  const isRangeTypeCondition = conditionData === numberConditionData.inRange.key;
  const handleChange = (event, newValue) => {
    setFilterValueNUM(newValue);
  };
  const handleConditon = (action) => {
    setFilterValueNUM([0, 0]);
    setNumberValue(0);
    setConditionData(action);
  };
  useUpdateEffect(() => {
    const isRangeTypeCondition = conditionData === numberConditionData.inRange.key;
    let value = {};

    if (isRangeTypeCondition) {
      value = {
        min: filterValueNUM[0],
        max: filterValueNUM[1],
        condition: conditionData,
      };
    } else {
      value = {
        value: numberValue,
        condition: conditionData,
      };
    }
    if (selectedData?.valueType === filterConditionType.dynamic.key) {
      value = {
        valueType: selectedData?.valueType,
        value: selectedData?.value,
        condition: conditionData,
      };
    }
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
  }, [debounceValue, conditionData, numberValue, selectedData]);

  const handleValueType = (action) => {
    let newSelectedData = { ...selectedData, valueType: action };
    if (action === filterConditionType.static.key) {
      newSelectedData = { ...newSelectedData, value: [] };
    }
    setSelectedData(newSelectedData);
  };

  const isNotCorrect = filterValueNUM[0] > filterValueNUM[1];
  const errorMsg = mls('Max must be  greater than Min');
  const handleColumnValue = (e) => {
    setNumberValue(Number(e.target.value ?? ''));
  };
  const handleSelectedDataValue = (selectedItems) => {
    const newSelectedData = { ...selectedData, value: selectedItems };
    setSelectedData(newSelectedData);
  };

  useEffect(() => {
    setFilterValueNUM([
      elem?.value?.min ?? elem?.filterData?.range?.min,
      elem?.value?.max ?? elem?.filterData?.range?.max,
    ]);
    setConditionData(elem?.value?.condition ?? numberConditionData.inRange.key);
    setSelectedData(
      elem?.value ?? {
        value: elem?.value?.value,
        min: elem?.value?.min ?? elem?.filterData?.range?.min ?? 0,
        max: elem?.value?.max ?? elem?.filterData?.range?.max ?? 0,
        condition: elem?.value?.condition ?? numberConditionData.inRange.key,
      }
    );
  }, [`${elem?.columnName}.${elem?.tableName}`]);
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <div className='titleDiv'>{mls('Condition')}</div>
      <ObjectDropDown
        object={numberConditionData}
        dropDownValue={conditionData ?? numberConditionData.inRange.key}
        setdropDownValue={handleConditon}
        ObjectInObject
      />
      <div className='titleDiv'>{mls('Value Type')}</div>
      <ObjectDropDown
        object={filterConditionType}
        dropDownValue={selectedData?.valueType ?? filterConditionType.static.key}
        setdropDownValue={handleValueType}
        ObjectInObject
      />
      {(selectedData?.valueType ?? filterConditionType.static.key) ===
      filterConditionType.static.key ? (
        <>
          {isRangeTypeCondition ? (
            <div className='numberInputDivContainer'>
              <div className='leftNumberInputDiv innerNumberInputDiv'>
                <div className='titleDiv'>{mls('Min')}:</div>
                <input
                  type='number'
                  className='primaryInput'
                  value={filterValueNUM[0]}
                  onChange={(e) => handleChange(e, [Number(e.target.value), filterValueNUM[1]])}
                />
              </div>
              <div className='rightNumberInputDiv innerNumberInputDiv'>
                <div className='titleDiv'>{mls('Max')}:</div>
                <input
                  type='number'
                  className='primaryInput'
                  style={isNotCorrect ? { background: '#ffc1c1' } : {}}
                  value={filterValueNUM[1]}
                  onChange={(e) => handleChange(e, [filterValueNUM[0], Number(e.target.value)])}
                  title={isNotCorrect ? errorMsg : ''}
                />
                {isNotCorrect ? (
                  <div className='errorInfo' title={errorMsg}>
                    <AiFillInfoCircle />
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <div className='titleDiv'>{mls('Value')}</div>
              <input
                className='primaryInput'
                type='number'
                placeholder={mls('Select')}
                value={numberValue ?? ''}
                onChange={(e) => handleColumnValue(e)}
              />
            </>
          )}
        </>
      ) : null}
      {selectedData?.valueType === filterConditionType.dynamic.key ? (
        <>
          <DynamicFilter
            tableName={elem?.tableName}
            elem={elem}
            handleColumnValue={handleSelectedDataValue}
          />
        </>
      ) : null}
      {isNotCorrect ? <div className='errorDisplay'></div> : null}
    </div>
  );
};
const primitiveValues = [
  {
    name: 'null',
    key: null,
  },
  {
    name: 'false',
    key: false,
  },
  {
    name: 'true',
    key: true,
  },
];

const FilterItemsCAT = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  appID,
  sheetID,
  isJoinedTable,
  handleRemoveColumnSelection,
  extraFilterData,
}) => {
  const [selectedData, setSelectedData] = useState(
    elem?.value ?? {
      value: [],
      condition: textConditionData.contains.key,
    }
  );
  const debounceValue = useDebounce(selectedData);
  const handleColumnValue = (selectedItems) => {
    const newSelectedData = { ...selectedData, value: selectedItems };
    setSelectedData(newSelectedData);
  };
  const handleColumnSelection = (selectedItems) => {
    handleColumnValue([...selectedItems]);
  };

  const selectedConditionType =
    textConditionData?.[selectedData?.condition ?? textConditionData.contains.key];
  const isValueTypeArray = selectedConditionType?.valueTypeArray ?? false;
  const handleConditon = (action) => {
    const newSelectedData = {
      ...selectedData,
      condition: action,
      value: textConditionData?.[action]?.defaultValue ?? '',
    };

    setSelectedData(newSelectedData);
  };
  const newUniqueValues = useMemo(() => {
    return (elem?.filterData?.uniqueValues || []).map((data) => {
      return `${data}`;
    });
  }, [elem]);
  useUpdateEffect(() => {
    const value = debounceValue;

    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setSelectedData(
      elem?.value ?? {
        value: '',
        condition: textConditionData.contains.key,
      }
    );
  }, [`${elem?.columnName}.${elem?.tableName}`]);

  // const handleColumnValue = (selectedItems) => {
  //   setSelectedData(selectedItems);
  // };
  const handleValueType = (action) => {
    let newSelectedData = { ...selectedData, valueType: action };
    if (action === filterConditionType.static.key) {
      newSelectedData = { ...newSelectedData, value: [] };
    }
    setSelectedData(newSelectedData);
  };
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <div className='titleDiv'>{mls('Condition')}</div>
      <ObjectDropDown
        object={textConditionData}
        dropDownValue={selectedData?.condition ?? textConditionData.contains.key}
        setdropDownValue={handleConditon}
        ObjectInObject
      />

      {selectedConditionType.key !== textConditionData.regexp.key ? (
        <div className='primitiveValuesContainer'>
          {React.Children.toArray(
            primitiveValues.map((primitiveElem) => {
              const isSelected = isValueTypeArray
                ? (selectedData?.value ?? []).some((elem) => elem === primitiveElem?.key)
                : selectedData?.value === primitiveElem?.key;
              const handlePrimitiveSelection = ({ isSelected, key }) => {
                if (isSelected) {
                  if (isValueTypeArray) {
                    const filterdData = (selectedData?.value ?? []).filter((item) => item !== key);
                    handleColumnValue(filterdData);
                  } else {
                    handleColumnValue('');
                  }
                  return;
                }
                if (isValueTypeArray) {
                  handleColumnValue([...selectedData?.value, key]);
                } else {
                  handleColumnValue(key);
                }
              };
              return (
                <div
                  className={`primitiveElem ${isSelected ? 'selectedPrimitiveElem' : ''}`}
                  onClick={() => handlePrimitiveSelection({ isSelected, key: primitiveElem?.key })}
                >
                  {primitiveElem.name}
                </div>
              );
            })
          )}
        </div>
      ) : null}

      <div className='titleDiv'>{mls('Value Type')}</div>
      <ObjectDropDown
        object={filterConditionType}
        dropDownValue={selectedData?.valueType ?? filterConditionType.static.key}
        setdropDownValue={handleValueType}
        ObjectInObject
      />
      {(selectedData?.valueType ?? filterConditionType.static.key) ===
      filterConditionType.static.key ? (
        <>
          <div className='titleDiv'>{mls('Value')}</div>
          <div className='multiSelectColumn'>
            {isValueTypeArray ? (
              <MultiselectWithApi
                targetElem={elem}
                appID={appID}
                sheetID={sheetID}
                isJoinedTable={isJoinedTable}
                options={newUniqueValues} // Options to display in the dropdown
                onSelect={handleColumnSelection} // Function will trigger on select event
                onRemove={handleColumnSelection} // Function will trigger on remove event
                // selectedValues={[...filteredSelectedData]}
                selectedValues={selectedData?.value ?? []}
                // displayValue="name" // Property name to display in the dropdown options
                showCheckbox
                isObject={false}
                inputPlaceholder={mls('Select')}
              />
            ) : (
              <input
                className='primaryInput'
                type='text'
                placeholder={mls('Select')}
                value={`${selectedData?.value}` ?? ''}
                onChange={(e) => handleColumnValue(e.target.value)}
              />
            )}
          </div>
        </>
      ) : null}
      {selectedData?.valueType === filterConditionType.dynamic.key ? (
        <>
          <DynamicFilter
            tableName={elem?.tableName}
            elem={elem}
            handleColumnValue={handleColumnValue}
          />
        </>
      ) : null}
    </div>
  );
};
const DynamicFilter = ({ tableName, elem, handleColumnValue }) => {
  const allDataTableSchema = useSelector((state) => {
    return (
      state.config_store?.[reduxConstants.config.APP_SCHEMA]?.[reduxConstants.config.DATA_TABLE] ||
      {}
    );
  });
  const getRelationData = ({ tableName }) => {
    const selecteTable = allDataTableSchema[tableName];
    let selectedColumns = [];
    let relationColumns = [];
    let relationColumns2 = {};
    relationColumns2 = {
      ...relationColumns2,
      [tableName]: {
        name: selecteTable?.name,
        tableName: tableName,
      },
    };
    Object.keys(selecteTable?.relations ?? {}).forEach((relationKey) => {
      const realationData = selecteTable?.relations[relationKey];
      const selectedTableName =
        realationData?.type === relationTypeObjects.belongsToMany.key
          ? realationData?.target
          : realationData?.target;
      relationColumns = [
        ...relationColumns,
        {
          name: `${realationData?.relationName} ( ${realationData?.type} )`,
          relationId: realationData?.as,
          columnName: `${realationData?.relationName}`,
          dataType: realationData?.type,
          tableName: selectedTableName,
        },
      ];
      // relationColumns2 = {
      //   ...relationColumns2,
      //   [realationData?.as]: {
      //     name: `${realationData?.relationName} ( ${realationData?.type} )`,
      //     relationId: realationData?.as,
      //     columnName: `${realationData?.relationName}`,
      //     dataType: realationData?.type,
      //     tableName: selectedTableName,
      //   },
      // };
      const finalSelecteTable = allDataTableSchema[selectedTableName];
      relationColumns2 = {
        ...relationColumns2,
        [selectedTableName]: {
          name: finalSelecteTable?.name,
          tableName: selectedTableName,
        },
      };
    });
    (selecteTable?.columnSchema ?? []).forEach((columnElem) => {
      if (
        (erpAllDataType?.[columnElem?.dataType]?.simpleDataType?.key ?? columnElem?.dataType) ===
        (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType)
      ) {
        selectedColumns = [
          ...selectedColumns,
          { ...columnElem, name: `${columnElem?.name} ( ${columnElem?.dataType} )` },
        ];
      }
      // if (
      //   // columnElem?.dataType === defaultDataType.belongsTo ||
      //   columnElem?.dataType === defaultDataType.user ||
      //   columnElem?.dataType === defaultDataType.multipleUsers
      // ) {
      //   selectedColumns = [
      //     ...selectedColumns,
      //     { ...columnElem, name: `${columnElem?.name} ( ${columnElem?.dataType} )` },
      //   ];
      // }
    });

    return {
      selecteTable: selecteTable,
      columnData: [...relationColumns, ...selectedColumns],
      relationColumns: relationColumns2,
      selectedColumns,
    };
  };
  const { relationColumns } = getRelationData({
    tableName: tableName,
  });

  const selectedValue = elem?.value ?? [];

  const selectedTable = selectedValue?.value?.[0]?.selectedTable ?? '';
  const selectedColumnsData = useMemo(() => {
    const selectedValue = elem?.value ?? [];
    const selectedTable = selectedValue?.value?.[0]?.selectedTable ?? '';
    const getColumnData = ({ tableName }) => {
      const selecteTable = allDataTableSchema[tableName];
      let selectedColumns = [];
      (selecteTable?.columnSchema ?? []).forEach((columnElem) => {
        if (
          (erpAllDataType?.[columnElem?.dataType]?.simpleDataType?.key ?? columnElem?.dataType) ===
          (erpAllDataType?.[elem?.dataType]?.simpleDataType?.key ?? elem?.dataType)
        ) {
          selectedColumns = [
            ...selectedColumns,
            { ...columnElem, name: `${columnElem?.name} ( ${columnElem?.dataType} )` },
          ];
        }

        return {
          selectedColumns,
        };
      });
      return { selectedColumns };
    };
    const { selectedColumns } = getColumnData({
      tableName: selectedTable,
    });
    return selectedColumns;
  }, [allDataTableSchema, elem?.value, elem?.dataType]);

  const handleTableName = (tableName) => {
    const selectedTableName = relationColumns[tableName]?.tableName;
    handleColumnValue([
      {
        ...(selectedValue?.value?.[0] ?? {}),
        selectedTable: selectedTableName,
        selectedColumn: '',
      },
    ]);
  };
  const handleColumnName = (columnName) => {
    handleColumnValue([{ ...(selectedValue?.value?.[0] ?? {}), selectedColumn: columnName }]);
  };
  return (
    <>
      <div className='titleDiv'>{mls('Selected Table')}</div>
      <ObjectDropDown
        object={relationColumns}
        dropDownValue={selectedTable}
        setdropDownValue={handleTableName}
        nameKey={'name'}
        mainKey={'tableName'}
        inputPlaceHolder={mls('Select Table')}
        ObjectInObject
      />
      {selectedTable ? (
        <>
          <div className='titleDiv'>{mls('Selected Column')}</div>
          <ArrayDropDown
            array={selectedColumnsData}
            dropDownValue={selectedValue?.value?.[0]?.selectedColumn ?? ''}
            setdropDownValue={handleColumnName}
            nameKey={'name'}
            mainKey={'columnName'}
            inputPlaceHolder={mls('Select Column')}
            ObjectInArray
          />
        </>
      ) : null}
    </>
  );
};
const FilterItemsSelect = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  appID,
  sheetID,
  isJoinedTable,
  handleRemoveColumnSelection,
}) => {
  const [selectedData, setSelectedData] = useState(
    elem?.value ?? {
      value: [],
      condition: textConditionData.contains.key,
    }
  );
  const debounceValue = useDebounce(selectedData);
  const handleColumnValue = (selectedItems) => {
    const newSelectedData = { ...selectedData, value: selectedItems };
    setSelectedData(newSelectedData);
  };
  const handleColumnSelection = (selectedItems) => {
    handleColumnValue([...selectedItems]);
  };
  // const selectedConditionType = selectedData?.condition === textConditionData.regexp.key;
  const selectedConditionType =
    textConditionData?.[selectedData?.condition ?? textConditionData.regexp.key];
  const isValueTypeArray = selectedConditionType?.valueTypeArray ?? false;
  // const isValueTypeArray = false;
  // const isValueTypeArray2 = selectedData?.condition === textConditionData.regexp.key;

  const handleConditon = (action) => {
    const newSelectedData = {
      ...selectedData,
      condition: action,
      value: textConditionData?.[action]?.defaultValue ?? '',
    };

    setSelectedData(newSelectedData);
  };

  const newUniqueValues = useMemo(() => {
    return (elem?.filterData?.uniqueValues || []).map((data) => {
      return `${data}`;
    });
  }, [elem]);
  useUpdateEffect(() => {
    const value = debounceValue;

    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setSelectedData(
      elem?.value ?? {
        value: '',
        condition: textConditionData.contains.key,
      }
    );
  }, [`${elem?.columnName}.${elem?.tableName}`]);
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <ObjectDropDown
        object={textConditionData}
        dropDownValue={selectedData?.condition ?? textConditionData.contains.key}
        setdropDownValue={handleConditon}
        ObjectInObject
      />
      {selectedConditionType.key !== textConditionData.regexp.key ? (
        <div className='primitiveValuesContainer'>
          {React.Children.toArray(
            [primitiveValues[0]].map((primitiveElem) => {
              const isSelected = isValueTypeArray
                ? (selectedData?.value ?? []).some((elem) => elem === primitiveElem?.key)
                : selectedData?.value === primitiveElem?.key;
              const handlePrimitiveSelection = ({ isSelected, key }) => {
                if (isSelected) {
                  if (isValueTypeArray) {
                    const filterdData = (selectedData?.value ?? []).filter((item) => item !== key);
                    handleColumnValue(filterdData);
                  } else {
                    handleColumnValue('');
                  }
                  return;
                }
                if (isValueTypeArray) {
                  handleColumnValue([...selectedData?.value, key]);
                } else {
                  handleColumnValue(key);
                }
              };
              return (
                <div
                  className={`primitiveElem ${isSelected ? 'selectedPrimitiveElem' : ''}`}
                  onClick={() => handlePrimitiveSelection({ isSelected, key: primitiveElem?.key })}
                >
                  {primitiveElem.name}
                </div>
              );
            })
          )}
        </div>
      ) : null}
      <div className='multiSelectColumn'>
        {isValueTypeArray ? (
          <>
            <MultiSelect
              selectedValues={selectedData?.value ?? []}
              options={[
                ...(elem?.options?.selectMenu ?? []),
                { value: null, label: 'null', __d3__nonDisplayableValue: true },
              ]}
              onSelect={handleColumnSelection}
              onRemove={handleColumnSelection}
              optionType={'objectInArray'}
              nameKey={'label'}
              mainKey={'value'}
            />
            {/* <MultiselectWithApi
              targetElem={elem?.options?.selectMenu ?? []}
              appID={appID}
              sheetID={sheetID}
              isJoinedTable={isJoinedTable}
              options={elem?.options?.selectMenu} // Options to display in the dropdown
              options={newUniqueValues} // Options to display in the dropdown
              onSelect={handleColumnSelection} // Function will trigger on select event
              onRemove={handleColumnSelection} // Function will trigger on remove event
              // selectedValues={[...filteredSelectedData]}
              selectedValues={selectedData?.value ?? []}
              // displayValue="name" // Property name to display in the dropdown options
              showCheckbox
              isObject={false}
              inputPlaceholder={mls('Select')}
            /> */}
          </>
        ) : (
          <>
            <ArrayDropDown
              array={elem?.options?.selectMenu ?? []}
              dropDownValue={selectedData?.value}
              setdropDownValue={(e) => handleColumnValue(e)}
              ObjectInArray={true}
              mainKey={'value'}
              nameKey={'label'}
              inputPlaceHolder={'Select'}
            />
            {/* <input
              className='primaryInput'
              type='text'
              placeholder={mls('Select')}
              value={`${selectedData?.value}` ?? ''}
              onChange={(e) => handleColumnValue(e.target.value)}
            /> */}
          </>
        )}
      </div>
    </div>
  );
};
const FilterItemsUser = ({ elem, handleRemoveColumnSelection }) => {
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
    </div>
  );
};
const FilterItemsUserLevelAccess = ({
  elem,
  handleRemoveColumnSelection,
  tableData,
  selectedColumns,
  setSelectedColumns,
  index,
}) => {
  const [selectedData, setSelectedData] = useState(elem?.value ?? []);
  const debounceValue = useDebounce(selectedData);

  const handleColumnValue = (selectedItems) => {
    setSelectedData(selectedItems);
  };

  useUpdateEffect(() => {
    const value = debounceValue;
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>

      <ChooseColumns
        tableName={elem?.tableName}
        elem={elem}
        nestIndex={0}
        handleColumnValue={handleColumnValue}
      />
    </div>
  );
};
const ChooseColumns = ({ tableName, elem, nestIndex, handleColumnValue }) => {
  const allDataTableSchema = useSelector((state) => {
    return (
      state.config_store?.[reduxConstants.config.APP_SCHEMA]?.[reduxConstants.config.DATA_TABLE] ||
      {}
    );
  });
  const getColumnData = ({ tableName }) => {
    const selecteTable = allDataTableSchema[tableName];
    let selectedColumns = [];
    let relationColumns = [];
    Object.keys(selecteTable?.relations ?? {}).forEach((relationKey) => {
      const realationData = selecteTable?.relations[relationKey];
      console.log('realationData?.relationName', realationData?.relationName);
      console.log('realationData', realationData);
      relationColumns = [
        ...relationColumns,
        {
          name: `${realationData?.relationName} ( ${realationData?.type} )`,
          relationId: realationData?.as,
          columnName: `${realationData?.relationName}`,
          dataType: realationData?.type,
        },
      ];
    });
    (selecteTable?.columnSchema ?? []).forEach((columnElem) => {
      if (
        // columnElem?.dataType === defaultDataType.belongsTo ||
        columnElem?.dataType === defaultDataType.user ||
        columnElem?.dataType === defaultDataType.multipleUsers
      ) {
        selectedColumns = [
          ...selectedColumns,
          { ...columnElem, name: `${columnElem?.name} ( ${columnElem?.dataType} )` },
        ];
      }
    });

    return { selecteTable: selecteTable, columnData: [...relationColumns, ...selectedColumns] };
  };
  const { selecteTable, columnData } = getColumnData({ tableName: tableName });

  const value = elem?.value ?? [];
  const setdropDownValue = (columnName) => {
    const selectedColumnData = columnData.find((elem) => elem?.columnName === columnName);
    if (
      selectedColumnData?.dataType === defaultDataType.belongsTo ||
      selectedColumnData?.dataType === relationTypeObjects.belongsTo.key ||
      selectedColumnData?.dataType === relationTypeObjects.belongsToMany.key ||
      selectedColumnData?.dataType === relationTypeObjects.hasMany.key ||
      selectedColumnData?.dataType === relationTypeObjects.hasOne.key
    ) {
      const valueElem = {
        relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        tableName,
      };
      const items = Array.from(value);
      items.splice(nestIndex);

      handleColumnValue([...items, valueElem]);
    } else if (selectedColumnData?.dataType === defaultDataType.user) {
      const valueElem = {
        // relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        isUserID: true,
        tableName,
        // userIdColumnKey: '',
      };
      const items = Array.from(value);
      items.splice(nestIndex);
      handleColumnValue([...items, valueElem]);
    } else if (selectedColumnData?.dataType === defaultDataType.multipleUsers) {
      const valueElem = {
        relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        isUserID: true,
        userIdColumnKey: selectedColumnData?.idField,
      };
      const items = Array.from(value);
      items.splice(nestIndex);
      handleColumnValue([...items, valueElem]);
    }
  };
  const selectedValue = value[nestIndex];
  // console.log(selectedValue);
  // console.log(tableName);
  // const selectedTableName =
  //   selecteTable?.relations?.[selectedValue?.relationId]?.type ===
  //   relationTypeObjects.belongsToMany.key
  //     ? selecteTable?.relations?.[selectedValue?.relationId]?.through
  //     : selecteTable?.relations?.[selectedValue?.relationId]?.target;
  const selectedTableName =
    selecteTable?.relations?.[selectedValue?.relationId]?.type ===
    relationTypeObjects.belongsToMany.key
      ? selecteTable?.relations?.[selectedValue?.relationId]?.target
      : selecteTable?.relations?.[selectedValue?.relationId]?.target;
  return (
    <>
      <ArrayDropDown
        array={columnData}
        dropDownValue={selectedValue?.columnName ?? ''}
        setdropDownValue={setdropDownValue}
        nameKey={'name'}
        mainKey={'columnName'}
        inputPlaceHolder={mls('Select Column')}
        ObjectInArray
      />
      {selectedValue?.relationId ? (
        <>
          {selectedValue?.isUserID ? (
            <></>
          ) : (
            <>
              <ChooseColumns
                tableName={selectedTableName}
                elem={elem}
                nestIndex={nestIndex + 1}
                handleColumnValue={handleColumnValue}
              />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

const FilterItemsDATE = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  handleRemoveColumnSelection,
}) => {
  const [selectedData, setSelectedData] = useState(elem?.value || defaultDateArray[4]);
  const [conditionData, setConditionData] = useState(
    elem?.value?.condition ?? dateConditionData.equals.key
  );
  const debounceValue = useDebounce(selectedData);
  const handleConditon = (action) => {
    setConditionData(action);
  };
  useUpdateEffect(() => {
    const value = { ...selectedData, condition: conditionData };
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, conditionData]);

  useEffect(() => {
    setSelectedData(elem?.value ?? { ...defaultDateArray[4], condition: conditionData });
  }, [`${elem?.columnName}.${elem?.tableName}`]);

  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <ObjectDropDown
        object={dateConditionData}
        dropDownValue={conditionData ?? dateConditionData.equals.key}
        setdropDownValue={handleConditon}
        ObjectInObject
      />
      <DatePicker dateData={selectedData} setDateData={setSelectedData} />
    </div>
  );
};

const updateData = (sourceData, index, value) => {
  const items = Array.from(sourceData);
  const [selectedItem] = items.splice(index, 1);
  const newSelectedItem = {
    ...selectedItem,
    value,
  };
  items.splice(index, 0, newSelectedItem);

  return items;
};
