import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import styles from '../codeCustomization.module.scss';

import { _supistaApiGet } from 'lib/server-connection/connections';

import QueryContainer from '../QueryContainer';
import { getDefaultInput } from '../../../utils/componentSettingFunction';
import QueryContainerPermissionContainer from '../QueryContainerPermissionContainer';
import { mls } from 'lib/multilanguagesupport';

interface AICustomizationEditorContainerProps {
  errors: any;
  componentRowList: any;
  componentSchema: any;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  title: string;
  setErrors: Function;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  updateCodeCustomization: Function;
  buttonType: string;
  codeContainerType: string;
  setCodeContainerType: Function;
  outputData: any;
  code: any;
  handleCodeChange: any;
  inputData: any;
  setInputData: Function;
  setOutputData: Function;
  appID: any;
  selectedId: any;
  setSelectedId: Function;
  setIsInputChanged: Function;
  selectedParentId: string;
  setSelectedParentId: Function;
  selectedTableData: { [key: string]: any };
  connectedTablesData: { [key: string]: any };
  groupedConnectedTablesData: { [key: string]: any };
  isInputChanged: boolean;
  setCode: Function;
  setButtonType: Function;
  setActiveButtonKey: Function;
  codePresent: boolean;
  isCodeDataChange: boolean;
  setIsCodeDataChange: Function;
}
const AICustomizationEditorContainer = ({
  errors,
  componentRowList,
  componentSchema,
  setTitle,
  activeButtonKey,
  setComponentSchema,
  title,
  setErrors,
  selectedCode,
  selectedTable,
  updateCodeCustomization,
  buttonType,
  codeContainerType,
  setCodeContainerType,
  outputData,
  setOutputData,
  code,
  handleCodeChange,
  inputData,
  setInputData,
  appID,
  selectedId,
  setSelectedId,
  selectedParentId,
  setSelectedParentId,
  selectedTableData,
  connectedTablesData,
  groupedConnectedTablesData,
  isInputChanged,
  setIsInputChanged,
  setCode,
  setButtonType,
  setActiveButtonKey,
  codePresent,
  isCodeDataChange,
  setIsCodeDataChange,
}: AICustomizationEditorContainerProps) => {
  const [isSampleDataPopup, setIsSampleDataPopup] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  const handleSelectedId = (e: any) => {
    setSelectedId(e.target.value);
    if (!isInputChanged) {
      setInputData(
        getDefaultInput({
          connectedTablesData: connectedTablesData,
          selectedTable,
          sampleData,
          actionButtonKey: activeButtonKey,
          buttonType: buttonType,
          selectedId: e.target.value ?? null,
          parentId: selectedParentId ?? null,
        }) ?? '{}'
      );
    }
  };
  const handleSelectedParentId = (e: any) => {
    setSelectedParentId(e.target.value);
    if (!isInputChanged) {
      setInputData(
        getDefaultInput({
          connectedTablesData: connectedTablesData,
          selectedTable,
          sampleData,
          actionButtonKey: activeButtonKey,
          buttonType: buttonType,
          selectedId: selectedId ?? null,
          parentId: e.target.value ?? null,
        }) ?? '{}'
      );
    }
  };

  useEffect(() => {
    const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';
    if (!isRelatedTypeTable) {
      (async () => {
        let urlData = {};
        urlData = {
          __d3__filterdata: {
            where: {},
          },
        };
        if (selectedId || selectedId === '0' || selectedId === 0) {
          urlData = {
            __d3__filterdata: {
              where: { id: selectedId },
            },
          };
        }
        const filterUrl = `?data=${JSON.stringify(urlData)}`;
        const componentID = componentSchema?.componentID;
        const baseUrl = `app/${appID}/CRUD/${componentID}`;
        const finalUrl = `${baseUrl}${filterUrl}`;
        const componentRes = await _supistaApiGet(finalUrl);
        setSampleData(componentRes?.result?.rows ?? []);
      })();
    }
  }, [
    selectedId,
    componentSchema?.componentID,
    appID,
    selectedTableData?.relationID,
    selectedTableData?.relationType,
  ]);

  useEffect(() => {
    const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';

    if (isRelatedTypeTable) {
      (async () => {
        let urlData = {};
        urlData = {
          __d3__filterdata: {
            where: {},
          },
        };
        urlData = {
          ...urlData,
          __d3__filterdata: {
            where: {},
          },
          __d3__parentId: selectedParentId ?? null,
          __d3__relationId: selectedTableData?.relationID,
        };
        const filterUrl = `?data=${JSON.stringify(urlData)}`;
        const componentID = componentSchema?.componentID;
        const baseUrl = `app/${appID}/CRUD/${componentID}`;
        const finalUrl = `${baseUrl}${filterUrl}`;
        const componentRes = await _supistaApiGet(finalUrl);
        if (isRelatedTypeTable) {
          if (componentRes?.rows?.[0]) {
            handleSelectedId({ target: { value: componentRes?.result?.rows?.[0]?.id ?? '' } });
          }
        }
        setSampleData(componentRes?.rows ?? []);
      })();
    }
  }, [
    selectedParentId,
    componentSchema?.componentID,
    appID,
    selectedTableData?.relationID,
    selectedTableData?.relationType,
  ]);
  const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  // useEffect(() => {
  //   setIsCodeDataChange(codePresent);
  // }, [codePresent]);
  if (!codePresent && isCodeDataChange) {
    return (
      <Col md={9} className={styles.editorContainer}>
        <div className={styles.queryContainerOuter}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              gap: '1rem',
              flexDirection: 'column',
              border: '1px solid #e0e0e0',
              borderRadius: '6px',
            }}
          >
            <div> {mls('Do you want to add code?')}</div>
            <button
              type='button'
              className='primaryButton secondaryButtonColor'
              onClick={() => setIsCodeDataChange(false)}
            >
              {mls('Add code')}
            </button>
          </div>
        </div>
      </Col>
    );
  }
  return (
    <Col md={9} className={styles.editorContainer}>
      <div className={styles.queryContainerOuter}>
        <QueryContainerPermissionContainer
          componentSchema={componentSchema}
          title={title}
          setTitle={setTitle}
          activeButtonKey={activeButtonKey}
          setComponentSchema={setComponentSchema}
          errors={errors}
          setErrors={setErrors}
          selectedCode={selectedCode}
          selectedTable={selectedTable}
          updateCodeCustomization={updateCodeCustomization}
          connectedTablesData={connectedTablesData}
          groupedConnectedTablesData={groupedConnectedTablesData}
          selectedParentId={selectedParentId}
          componentRowList={componentRowList}
          selectedId={selectedId}
          buttonType={buttonType}
          sampleData={sampleData}
          isRelatedTypeTable={isRelatedTypeTable}
          handleSelectedParentId={handleSelectedParentId}
          handleSelectedId={handleSelectedId}
          isSampleDataPopup={isSampleDataPopup}
          setIsSampleDataPopup={setIsSampleDataPopup}
          isMoreOptionsOpen={isMoreOptionsOpen}
          setIsMoreOptionsOpen={setIsMoreOptionsOpen}
          selectedTableData={selectedTableData}
        />
        <QueryContainer
          componentSchema={componentSchema}
          activeButtonKey={activeButtonKey}
          title={title}
          selectedCode={selectedCode}
          selectedTable={selectedTable}
          updateCodeCustomization={updateCodeCustomization}
          buttonType={buttonType}
          codeContainerType={codeContainerType}
          setCodeContainerType={setCodeContainerType}
          outputData={outputData}
          code={code}
          handleCodeChange={handleCodeChange}
          inputData={inputData}
          setInputData={setInputData}
          setIsInputChanged={setIsInputChanged}
          appID={appID}
          setOutputData={setOutputData}
          sampleData={sampleData}
          setCode={setCode}
          setButtonType={setButtonType}
          setActiveButtonKey={setActiveButtonKey}
          isMoreOptionsOpen={isMoreOptionsOpen}
          setIsMoreOptionsOpen={setIsMoreOptionsOpen}
          selectedId={selectedId}
          selectedParentId={selectedParentId}
          connectedTablesData={connectedTablesData}
        />
      </div>
    </Col>
  );
};

export default AICustomizationEditorContainer;
