export const warningTypeObject = {
  save: {
    key: 'save',
    title: 'Save',
  },
  delete: {
    key: 'delete',
    title: 'Delete',
  },
};
