import { mls } from 'lib/multilanguagesupport';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import styles from './aiCustomizationEditorContainer.module.scss';
import React, { useEffect, useState } from 'react';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import ExpandingTextarea from 'lib/reusable-components/Components/ExpandingTextarea/ExpandingTextarea';

interface tableSchemaSelectionProps {
  isTableSchema: boolean;
  setIsTableSchema: Function;
  updateTableSchemaFunction: Function;
  componentSchema: any;
  selectedData: any;
  connectedTablesData: any;
  selectedCustomizationTable: string;
  appDatatable: any;
  tableSchema: any[];
}
const TableSchemaSelection = ({
  isTableSchema,
  setIsTableSchema,
  connectedTablesData,
  appDatatable,
  selectedCustomizationTable,
  updateTableSchemaFunction,
  tableSchema,
}: tableSchemaSelectionProps) => {
  // const tableSchema = selectedData?.tableSchema ?? [];
  const [tableSchemaSearch, setTableSchemaSearch] = useState('');
  const [tableSchemaData, setTableSchemaData] = useState(appDatatable ?? {});
  const [selectedTableID, setSelectedTableID] = useState(
    Object.keys(appDatatable ?? {})?.[0] ?? ''
  );

  useEffect(() => {
    let newConnectedTableSchema = {};
    let newTableSchema = {};

    Object.keys(appDatatable ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = appDatatable[templateKey];
      // if (selectedTemplateData?.tableType === 'junction') {
      //   return;
      // }
      if (
        (selectedTemplateData?.name ?? '').toLowerCase()?.includes(tableSchemaSearch.toLowerCase())
      ) {
        const isConnectedTable = Object.keys(connectedTablesData ?? {}).some(
          (connectedTableID: string) => {
            const connectedTablesElem = connectedTablesData[connectedTableID];
            return connectedTablesElem.tableID === templateKey;
          }
        );
        if (isConnectedTable) {
          newConnectedTableSchema = {
            ...newConnectedTableSchema,
            [templateKey]: selectedTemplateData,
          };
        } else {
          newTableSchema = { ...newTableSchema, [templateKey]: selectedTemplateData };
        }
      }
    });
    const finalTableSchema = { ...newConnectedTableSchema, ...newTableSchema };

    if (Object.keys(finalTableSchema ?? {})?.[0] === selectedCustomizationTable) {
      setSelectedTableID(Object.keys(finalTableSchema ?? {})?.[1] ?? '');
    } else {
      setSelectedTableID(Object.keys(finalTableSchema ?? {})?.[0] ?? '');
    }
    setTableSchemaData(finalTableSchema);
  }, [tableSchemaSearch, appDatatable]);
  const updateTableSchemaData = (e: any) => {
    const isRemove = e?.isRemove;
    const target = e?.target;
    if (isRemove) {
      const newTableSchema = (tableSchema ?? []).filter(
        (tableSchemaElem: { tableID: string }) => tableSchemaElem?.tableID !== target
      );
      updateTableSchemaFunction(newTableSchema);
    } else {
      const newTableSchema = [...tableSchema, { tableID: target, tableUsed: '' }];
      updateTableSchemaFunction(newTableSchema);
    }
  };
  const selectedTable = appDatatable[selectedTableID];
  const handleTableUsed = ({ tableIndex, value }: { value: string; tableIndex: number }) => {
    if (tableIndex < 0) {
      return;
    }
    const newTableSchema = Array.from(tableSchema);
    const [selectedTableElem]: any[] = newTableSchema.splice(tableIndex, 1);
    const newSelectedTableElem = {
      ...selectedTableElem,
      tableUsed: value,
    };
    newTableSchema.splice(tableIndex, 0, newSelectedTableElem);
    updateTableSchemaFunction(newTableSchema);
  };
  const selectedTableSchemaIndex = (tableSchema ?? []).findIndex(
    (tableSchemaElem: { tableID: string }) => tableSchemaElem.tableID === selectedTable?.tableID
  );
  const selectedTableSchemaData = tableSchema?.[selectedTableSchemaIndex];

  return (
    <div>
      <PopUpDivChild
        initialValue={isTableSchema}
        setPopUp={setIsTableSchema}
        popupTitle={mls('Table Schema')}
      >
        <div className={styles.tableSchemaContainer}>
          <div className={styles.tableSchemaContainerLeft}>
            <div className={styles.tableSelectionContainer}>
              <div className={styles.tableSelectionContainerTitle}>{mls('Tables')}</div>
              <div className={styles.tableSelectionContainerSubTitle}>
                <SearchInputDiv value={tableSchemaSearch} onChange={setTableSchemaSearch} border />
              </div>
              <div className={styles.actionSelectionInnerContainer}>
                {Object.keys(tableSchemaData ?? {}).map((templateKey: string) => {
                  const selectedTemplateData = tableSchemaData[templateKey];
                  const isSelected = (tableSchema ?? []).some(
                    (tableSchemaKey: { tableID: string }) => {
                      return tableSchemaKey?.tableID === selectedTemplateData?.tableID;
                    }
                  );
                  const tableSelected = selectedTableID === selectedTemplateData?.tableID;
                  // if (selectedCustomizationTable === templateKey) {
                  //   return null;
                  // }
                  return (
                    <div
                      className={`${styles.tableSelectionInnerElem} ${
                        tableSelected ? styles.selectedTableElem : null
                      }`}
                      key={templateKey}
                      onClick={() => {
                        setSelectedTableID(selectedTemplateData?.tableID);
                      }}
                    >
                      <CheckBox
                        checked={isSelected}
                        onClick={(action: any) => {
                          updateTableSchemaData({
                            isRemove: isSelected,
                            target: selectedTemplateData?.tableID,
                          });
                        }}
                        checkBoxCheckedColor={tableSelected ? '' : ''}
                        checkBoxUncheckedColor={tableSelected ? '' : ''}
                        checkBoxBorderCheckedColor={tableSelected ? '#a4deff' : '#a4deff'}
                        // checkBoxBorderUncheckedColor={tableSelected ? '#a4deff' : '#a4deff'}
                      />
                      {selectedTemplateData.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.tableSchemaContainerRight}>
            <div className={styles.columnSchemaTitle}>{mls('Column Schema')}</div>
            <div className={styles.columnSchemaOuterContainer}>
              {selectedTableSchemaIndex >= 0 ? (
                <div className={styles.columnSchemaQuestionSection}>
                  <div className={styles.columnSchemaQuestionTitle}>
                    {mls(
                      `How ${
                        selectedTable?.name ?? selectedTable?.tableID
                      } is related with ${selectedCustomizationTable}. `
                    )}
                  </div>
                  <div className={styles.columnSchemaQuestionInput}>
                    <ExpandingTextarea
                      value={selectedTableSchemaData?.tableUsed ?? ''}
                      placeholder='Table used for'
                      name='tableUsed'
                      // maxLineCount={1}
                      onChange={(e: any) =>
                        handleTableUsed({
                          value: e.target.value,
                          tableIndex: selectedTableSchemaIndex,
                        })
                      }
                    />
                  </div>
                </div>
              ) : null}
              <div className={styles.columnSchemaInnerContainer}>
                <div className={styles.columnSchemaContainer}>
                  {React.Children.toArray(
                    (selectedTable?.columnSchema ?? []).map(
                      (columnElem: any, templateIndex: number) => {
                        return (
                          <div
                            key={columnElem?.columnName}
                            onClick={() => {
                              copyToClipBoard({ text: columnElem?.columnName });
                            }}
                          >
                            <div className={styles.columnSchemaElem}>
                              <div className={styles.columnData}>
                                {columnElem?.name ?? columnElem?.columnName} ({' '}
                                {columnElem?.dataType} )
                              </div>
                              <div className={styles.dragIconOuter}>
                                <MdContentCopy className={styles.dragIcon} />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default TableSchemaSelection;

const copyToClipBoard = ({ text = '', isMsg = true, customMsg = null }) => {
  navigator.clipboard.writeText(text);

  if (isMsg) {
    const tostMsg = customMsg ?? `${mls('Copied the text')}: ${text}`;
    // Alert the copied text
    toast.success(tostMsg, {
      theme: 'light',
    });
  }
};
