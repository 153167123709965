import { relationTypeObjects } from 'lib/appComponent/componentSettings/data/appComponentData';
import {
  actionTypes,
  handleBelongToTableRemove,
  handleColumnUpdates,
  relationNestedLevel,
} from './componentUpdateFunction';
import { getRemovedUpdatedTableList, handleDeleteRelation } from './handleRealtionUpdate';
import { getAddUpdatedTableList, handleAddRelation } from './handleAddRelation';

interface handleUpdateOperationProps {
  allComponentSchema: { [key: string]: any };
  allDataTableData: { [key: string]: any };

  previousTableUpdates: { [key: string]: any };
  targetedTableElem: {
    tableID: string;
    operation: string;
    originalDataTableSchema: { [key: string]: any };
    currentDataTableSchema: { [key: string]: any };
    tableColumnsUpdates: { [key: string]: any };
  };
}
export const handleUpdateOperation = ({
  allComponentSchema,
  allDataTableData,

  targetedTableElem,
  previousTableUpdates,
}: handleUpdateOperationProps) => {
  let originalComponent: { [key: string]: any } = {};
  let affectedComponent: { [key: string]: any } = {};
  let tableUpdates: { [key: string]: any } = { ...previousTableUpdates };
  let finalUpdatedComponentSchema = { ...allComponentSchema };
  const currentDataTableSchema = targetedTableElem?.currentDataTableSchema;
  const originalDataTableSchema = targetedTableElem?.originalDataTableSchema;
  const tableColumnsUpdates = targetedTableElem?.tableColumnsUpdates;

  const { deleteRelationsList, addRelationList } = getRelationsUpdateData({
    currentDataTableSchema,
    originalDataTableSchema,
  });

  // handleBelongToTableRemove
  Object.keys(deleteRelationsList).forEach((relationElemKey: string) => {
    const relationElem = deleteRelationsList[relationElemKey];
    // If belongsToMany
    const { belongToTableUpdates } = handleBelongToTableRemove({
      allDataTableData,
      relationElem,
      previousTableUpdates,
    });
    tableUpdates = {
      ...tableUpdates,
      ...belongToTableUpdates,
    };
  });
  const mainTableID = targetedTableElem?.tableID;
  //Handle Delete Reation ( deleteRelationsList )

  Object.keys(finalUpdatedComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = finalUpdatedComponentSchema[componentElemKey];
    let componentAffected = false;
    let updatedComponentSchema = { ...componentElem };
    const { tablePresence: isTablePresent } = checkTablePresence({
      targetTableID: mainTableID,
      componentElem: componentElem,
      nestingLevel: relationNestedLevel - 1,
    });
    if (isTablePresent) {
      //Remove Relation Update
      const {
        updatedTableList: removeUpdatedTableList,
        tableListAffected: removeTableListAffected,
      } = getRemovedUpdatedTableList({
        deleteRelationsList,
        componentElem: updatedComponentSchema,
        nestingLevel: relationNestedLevel - 2,
      });
      if (removeTableListAffected) {
        componentAffected = true;
        updatedComponentSchema = { ...updatedComponentSchema, tableList: removeUpdatedTableList };
      }

      //Columns Update
      const { isTargetTableUpdated: isTargetTableUpdatedInner, updatedComponentElem } =
        handleColumnUpdates({
          tableColumnsUpdates,
          componentElem: updatedComponentSchema,
          targetedTableElem,
        });
      if (isTargetTableUpdatedInner) {
        componentAffected = true;
        updatedComponentSchema = updatedComponentElem;
      }

      //Add Relation Update
      const { updatedTableList: addUpdatedTableList, tableListAffected: addTableListAffected } =
        getAddUpdatedTableList({
          addRelationList,
          componentElem: updatedComponentSchema,
          nestingLevel: relationNestedLevel - 2,
          targetTableID: mainTableID,
          allDataTableData,
        });

      if (addTableListAffected) {
        componentAffected = true;
        updatedComponentSchema = { ...updatedComponentSchema, tableList: addUpdatedTableList };
      }
    }

    if (componentAffected) {
      affectedComponent = { ...affectedComponent, [componentElemKey]: updatedComponentSchema };
      originalComponent = { ...originalComponent, [componentElemKey]: componentElem };
    }
  });

  return { originalComponent, affectedComponent, tableUpdates };
};
const checkTablePresence = ({
  targetTableID,
  componentElem,
  nestingLevel,
}: {
  targetTableID: string;
  nestingLevel: number;
  componentElem: { [key: string]: any };
}) => {
  const nestedTablePresenceCheck = ({
    tableList,
    targetTableID,
    nestingLevel,
  }: {
    nestingLevel: number;
    tableList: { [key: string]: any }[];
    targetTableID: string;
  }) => {
    const isTablePresent = (tableList ?? []).some((tableElem: any) => {
      const isTablePresent = tableElem?.tableID === targetTableID;
      if (isTablePresent) {
        return true;
      } else {
        if (nestingLevel >= 0) {
          return nestedTablePresenceCheck({
            tableList: tableElem?.tableList ?? [],
            targetTableID,
            nestingLevel: nestingLevel - 1,
          });
        }
      }
      return true;
    });
    return isTablePresent;
  };
  if (componentElem?.table === targetTableID) {
    return { tablePresence: true };
  }
  const tablePresence = nestedTablePresenceCheck({
    tableList: componentElem?.tableList ?? [],
    targetTableID,
    nestingLevel,
  });

  return { tablePresence: tablePresence };
};
const getRelationsUpdateData = ({
  currentDataTableSchema,
  originalDataTableSchema,
}: {
  currentDataTableSchema: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
}) => {
  let deleteRelationsList: { [key: string]: any } = {};
  let addRelationList: { [key: string]: any } = {};
  Object.keys(originalDataTableSchema?.relations ?? {}).forEach(
    (originalDataTableElemKey: string) => {
      const currentDataTableElem = originalDataTableSchema?.relations?.[originalDataTableElemKey];
      deleteRelationsList = {
        ...deleteRelationsList,
        [originalDataTableElemKey]: currentDataTableElem,
      };
    }
  );
  Object.keys(currentDataTableSchema?.relations ?? {}).forEach(
    (currentDataTableElemKey: string) => {
      if (deleteRelationsList?.[currentDataTableElemKey]) {
        delete deleteRelationsList?.[currentDataTableElemKey];
      } else {
        const currentDataTableElem = currentDataTableSchema?.relations?.[currentDataTableElemKey];
        addRelationList = {
          ...addRelationList,
          [currentDataTableElemKey]: currentDataTableElem,
        };
      }
    }
  );
  return { deleteRelationsList, addRelationList };
};
