import clsx from 'clsx';
import React, { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { convertToTitleCase, updateDataByIndex } from '../../util/utilFunctions';
import {
  defaultColumnSchema,
  erpAllDataType,
  erpNormalDataType,
  erpValidationTypes,
} from '../../data/erpData';
import SelectDataTypeCase from './SelectMenu/SelectDataTypeCase';
import UserSelection from './UserSelection';
import { toast } from 'react-toastify';
import ValidationSection from './validationComp/ValidationSection';
import { getValidationDataByType } from './validationComp/validationUtils';

interface columnSettingsProps {
  children: any;
  dataTableSchema: any;
  columnData: any;
  setColumnData: Function;
  tableUpdates: any;
  setTableUpdates: Function;
  setCurrentExpandedAccordion?: Function;
  handleDefaultDataFromType?: Function;
}

const ColumnSettings = ({
  children,
  dataTableSchema,
  columnData,
  setColumnData,
  tableUpdates,
  setTableUpdates,
  setCurrentExpandedAccordion = () => {},
  handleDefaultDataFromType = ({ columnData }: { columnData: { [key: string]: any } }) =>
    columnData,
}: columnSettingsProps) => {
  const [tempColumnName, setTempColumnName] = useState(columnData?.columnName ?? '');
  const [tempDisplayName, setTempDisplayName] = useState(columnData?.name ?? '');

  const handleUpdateColumnData = (columnData: any) => {
    setColumnData(columnData);
  };

  const handleTableUpdate = (tableUpdateData: any) => {
    setTableUpdates(tableUpdateData);
  };
  const handleTableUpdateColumnName = ({
    columnData,
    columnName,
    isColumnName,
  }: {
    columnData: { [key: string]: any };
    columnName: string;
    isColumnName?: boolean;
  }) => {
    let addColumns = tableUpdates?.addColumns ?? [];
    let renameColumns = tableUpdates?.renameColumns ?? {};
    const addColumnsIndex = addColumns.findIndex((elem: any) => elem?.columnName === columnName);
    if (addColumnsIndex >= 0) {
      addColumns = updateDataByIndex({
        index: addColumnsIndex,
        arrayData: addColumns,
        newData: columnData,
      });
      handleTableUpdate({
        ...tableUpdates,
        addColumns: addColumns,
      });
      return;
    }
    let updateColumns = tableUpdates?.updateColumns ?? [];
    const updateColumnsIndex = updateColumns.findIndex(
      (elem: any) => elem?.columnName === columnName
    );
    if (updateColumnsIndex >= 0) {
      updateColumns = updateDataByIndex({
        index: updateColumnsIndex,
        arrayData: updateColumns,
        newData: columnData,
      });
    } else {
      updateColumns = [...updateColumns, columnData];
    }
    if (isColumnName) {
      const allReadyPresentKey = Object.keys(renameColumns).find(
        (elem: any) => renameColumns[elem] === columnName
      );
      if (allReadyPresentKey) {
        renameColumns = { ...renameColumns, [allReadyPresentKey]: columnData?.columnName };
      } else {
        renameColumns = { ...renameColumns, [columnName]: columnData?.columnName };
      }
    }
    setCurrentExpandedAccordion(tempColumnName);
    handleTableUpdate({
      ...tableUpdates,
      updateColumns: updateColumns,
      renameColumns: renameColumns,
    });
  };
  const updateColumnData = ({ key, value }: { key: string; value: any }) => {
    handleTableUpdateColumnName({
      columnData: { ...columnData, [key]: value },
      columnName: columnData?.columnName,
    });
    handleUpdateColumnData({ ...columnData, [key]: value });
  };

  const handleChangeColumnName = () => {
    if (!tempColumnName) {
      toast.error(mls('Please Enter Column Name!'));
      setTempColumnName(columnData.columnName);
      return;
    }
    const newColumnString = tempColumnName.trim();
    const isColumnNameExist = (dataTableSchema?.columnSchema ?? []).some((colElem: any) => {
      if (newColumnString === columnData.columnName) {
        return false;
      } else {
        if (newColumnString === colElem?.columnName) {
          return true;
        }
      }
      return false;
    });

    if (isColumnNameExist) {
      toast.error(mls('Column name already exist !'));
      setTempColumnName(columnData.columnName);
      return;
    }
    if ((newColumnString ?? '').toLowerCase() === 'id') {
      toast.error(mls('Column name Cannot be ID!'));
      setTempColumnName(columnData.columnName);
      return;
    }
    handleTableUpdateColumnName({
      columnData: {
        ...columnData,
        columnName: newColumnString,
        name: convertToTitleCase(newColumnString),
      },
      columnName: columnData?.columnName,
      isColumnName: true,
    });
    handleUpdateColumnData({
      ...columnData,
      columnName: newColumnString,
      name: convertToTitleCase(newColumnString),
    });
    setTempColumnName(newColumnString);
    setTempDisplayName(convertToTitleCase(newColumnString));
  };

  const handleColTypeChange = (e: any) => {
    const newType = e.target.value;
    const selectedColumnTypeData: any = erpAllDataType[newType as keyof typeof erpAllDataType];
    const getDefaultValidation = () => {
      let validationArray: any[] = [];
      Object.keys(selectedColumnTypeData?.defaultValidation ?? {}).forEach(
        (validationKey: string) => {
          const validationData = selectedColumnTypeData?.defaultValidation[validationKey];
          validationArray = [
            ...validationArray,
            getValidationDataByType({
              validationType: validationData.key,
              validationData: {
                type: validationData.key,
                message:
                  erpValidationTypes[validationData.key as keyof typeof erpValidationTypes]
                    ?.defaultMessage ?? '',
              },
            }),
          ];
        }
      );
      return validationArray;
    };
    const defaultValidation = getDefaultValidation();
    let column: { [key: string]: any } = {
      ...defaultColumnSchema,
      defaultValue: [],
      dataType: newType ?? defaultColumnSchema?.dataType,
      // prevDataType: columnData?.dataType,
      columnName: columnData?.columnName ?? defaultColumnSchema?.columnName,
      name: columnData?.name ?? defaultColumnSchema?.name,
      // readOnly: columnData?.readOnly ?? defaultColumnSchema?.readOnly,
      readOnly: selectedColumnTypeData?.defaultReadOnly ?? defaultColumnSchema?.readOnly,
      // validation: columnData?.validation ?? defaultColumnSchema?.validation,
      validation: defaultValidation ?? columnData?.validation ?? defaultColumnSchema?.validation,
    };
    // console.log('column', column);
    const newColumn = handleDefaultDataFromType({ columnData: column });

    handleUpdateColumnData(newColumn);
    column = {
      ...newColumn,
      prevDataType: columnData?.dataType,
    };
    handleTableUpdateColumnName({
      columnData: column,
      columnName: column?.columnName,
    });
  };
  const handleChangeDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      columnData.dataType === erpAllDataType.number.key ||
      columnData.dataType === erpAllDataType.decimal.key
        ? Number(e.target.value)
        : e.target.value;
    updateColumnData({ key: 'defaultValue', value: [value] });
  };
  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateColumnData({ key: 'description', value: e.target.value });
  };
  // const checkIfColumnNameExists = (newName, indx) => {
  //   for (var i = 0; i < dataTableSchema?.columnSchema?.length; i++) {
  //     if (dataTableSchema.columnSchema[i].name === newName && i !== indx) {
  //       setColumnNameExist(true);
  //       setColumnNameError(true);
  //       break;
  //     } else {
  //       setColumnNameExist(false);
  //       setColumnNameError(false);
  //     }
  //   }
  // };

  // const checkColumnNameValid = (newName) => {
  //   const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
  //     (char) => {
  //       return newName.includes(char);
  //     }
  //   );
  //   containsCharacterWhichIsNotAllowed &&
  //     setColumnNameError(
  //       "Column name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
  //     );
  // };

  const handleReadOnlyChange = () => {
    updateColumnData({ key: 'readOnly', value: columnData?.readOnly ? false : true });
  };

  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const handleTempColumnNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const newColumnString = e.target.value.trim();
    const newColumnString = e.target.value;
    let formErrors: { [key: string]: any } = { ...errors };
    if (newColumnString.length > 30) {
      formErrors.name = mls('Max 30 characters allowed');
      setErrors(formErrors);
      return;
    }
    delete formErrors?.name;
    setErrors(formErrors);
    setTempColumnName(newColumnString);
  };
  const handleTempDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumnString = e.target.value;
    setTempDisplayName(newColumnString);
  };

  const handleChangeDisplayName = () => {
    if (!tempDisplayName) {
      toast.error(mls('Please Enter Display Name!'));
      setTempDisplayName(columnData.name);
      return;
    }
    const newDisplayNameString = tempDisplayName.trim();

    const isDisplayNameExist = (dataTableSchema?.columnSchema ?? []).some((colElem: any) => {
      if (newDisplayNameString === columnData.name) {
        return false;
      } else {
        if (newDisplayNameString === colElem?.name) {
          return true;
        }
      }
      return false;
    });

    if (isDisplayNameExist) {
      toast.error(mls('Display name already exist!'));
      setTempDisplayName(columnData.name);
      return;
    }
    updateColumnData({ key: 'name', value: tempDisplayName });
  };
  const handleBlur = (e: any) => {
    const { name, value } = e.target;
    let formErrors: { [key: string]: any } = { ...errors };
    if (!value) {
      formErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else {
      delete formErrors[name];
    }
    setErrors(formErrors);
  };

  if (columnData.dataType === erpAllDataType.belongsTo.key) {
    return (
      <div id={`edit_card_`} data-bs-parent='#kt_accordion_1'>
        <div>
          <form className='fv-row mb-10'>
            <div className='row px-4 pb-4'>
              <div className='col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column name')}</label>
                <input
                  placeholder='Column name'
                  value={tempColumnName}
                  onChange={(e) => handleTempColumnNameChange(e)}
                  onBlur={(e) => {
                    handleChangeColumnName();
                  }}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  type='text'
                  name='columnName'
                  autoComplete='off'
                  // onBlur={handleBlur}
                  disabled
                  // style={{ backgroundColor: '#e4e6ef' }}
                />
              </div>
              <div className='col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>{mls('Display name')}</label>
                <input
                  placeholder='Display name'
                  value={columnData.name}
                  onChange={(e) => handleTempDisplayNameChange(e)}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  type='text'
                  name='name'
                  autoComplete='off'
                  disabled
                  // style={{ backgroundColor: '#e4e6ef' }}
                />
              </div>
            </div>
            <div className='row px-4'>
              <div className=' col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column Type')}</label>
                <select
                  name='dataType'
                  onChange={handleColTypeChange}
                  className='form-select form-control form-control-lg form-control-solid'
                  value={columnData.dataType || 'text'}
                  disabled
                >
                  {React.Children.toArray(
                    [...erpNormalDataType, erpAllDataType.belongsTo].map((dataTypeElem) => {
                      return <option value={dataTypeElem.key}>{mls(dataTypeElem.name)}</option>;
                    })
                  )}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    <div id={`edit_card_`} data-bs-parent='#kt_accordion_1'>
      <div>
        <form className='fv-row mb-10'>
          <div className='row px-4 pb-4'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column name')}</label>
              <input
                placeholder='Column name'
                value={tempColumnName}
                onChange={(e) => handleTempColumnNameChange(e)}
                onBlur={(e) => {
                  handleBlur(e);
                  handleChangeColumnName();
                }}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='columnName'
                autoComplete='off'
                // style={{ backgroundColor: '#e4e6ef' }}
              />
              {errors.name && <div className='text-danger'>{errors.name}</div>}
            </div>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Display name')}</label>
              <input
                placeholder='Display name'
                value={tempDisplayName}
                onChange={(e) => handleTempDisplayNameChange(e)}
                onBlur={(e) => {
                  handleChangeDisplayName();
                }}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='name'
                autoComplete='off'
                // style={{ backgroundColor: '#e4e6ef' }}
              />
            </div>
          </div>
          <div className='row px-4'>
            <div className=' col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column Type')}</label>
              <select
                name='dataType'
                onChange={handleColTypeChange}
                className='form-select form-control form-control-lg form-control-solid'
                value={columnData.dataType || 'text'}
              >
                {React.Children.toArray(
                  erpNormalDataType.map((dataTypeElem) => {
                    return <option value={dataTypeElem.key}>{mls(dataTypeElem.name)}</option>;
                  })
                )}
              </select>
            </div>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Description')}</label>
              <input
                placeholder='Description'
                value={columnData.description || ''}
                onChange={(e) => handleChangeDescription(e)}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='name'
                autoComplete='off'
                // style={{ backgroundColor: '#e4e6ef' }}
              />
            </div>
          </div>
          <div className='row px-4'>
            {columnData.dataType === erpAllDataType.text.key ? (
              <>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {mls('Default Value')}
                  </label>
                  <input
                    placeholder='Default Value'
                    value={columnData?.defaultValue?.[0] ?? ''}
                    onChange={(e) => handleChangeDefaultValue(e)}
                    className={clsx('form-control form-control-lg form-control-solid')}
                    type='text'
                    name='defaultValue'
                    autoComplete='off'
                    // style={{ backgroundColor: '#e4e6ef' }}
                  />
                </div>
              </>
            ) : null}
            {columnData.dataType === erpAllDataType.number.key ||
            columnData.dataType === erpAllDataType.decimal.key ? (
              <>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {mls('Default Value')}
                  </label>
                  <input
                    placeholder='Default Value'
                    value={columnData?.defaultValue?.[0] ?? ''}
                    onChange={(e) => handleChangeDefaultValue(e)}
                    className={clsx('form-control form-control-lg form-control-solid')}
                    type='number'
                    name='defaultValue'
                    autoComplete='off'
                    // style={{ backgroundColor: '#e4e6ef' }}
                  />
                </div>
              </>
            ) : null}
          </div>

          {columnData.dataType === erpAllDataType.select.key ? (
            <SelectDataTypeCase columnData={columnData} updateColumnData={updateColumnData} />
          ) : null}
          {columnData.dataType === erpAllDataType.user.key ||
          columnData.dataType === erpAllDataType.multipleUser.key ? (
            <UserSelection
              columnData={columnData}
              setDataTableSchema={() => {}}
              dataTableSchema={dataTableSchema}
              updateColumnData={updateColumnData}
            />
          ) : null}
          <ValidationSection
            columnSchema={columnData}
            handleUpdateColumnData={handleUpdateColumnData}
            handleTableUpdateColumnName={handleTableUpdateColumnName}
          />
          {columnData.dataType !== erpAllDataType.__d3__CreatedAtDate.key &&
          columnData.dataType !== erpAllDataType.__d3__CreatedAtTime.key &&
          columnData.dataType !== erpAllDataType.__d3__UpdatedAtDate.key &&
          columnData.dataType !== erpAllDataType.__d3__UpdatedAtTime.key ? (
            <div className='d-flex justify-content-between'>
              <label className='mt-5 ms-10 form-check form-switch form-check-custom form-check-solid'>
                <span className='form-label fs-6 fw-bolder text-dark mx-3 '>
                  {mls('Read-Only')}
                </span>
                <input
                  onChange={() => handleReadOnlyChange()}
                  className='form-check-input'
                  type='checkbox'
                  checked={columnData?.readOnly}
                />
              </label>
            </div>
          ) : null}
          <div className='d-flex justify-content-between'>
            <div></div>
            {mls(children)}
          </div>
        </form>
      </div>
    </div>
  );
};
export default ColumnSettings;
