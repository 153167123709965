import React, { useEffect, useMemo, useState } from 'react';
import styles from './templatesWrapper.module.scss';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import HtmlToPdf from './HtmlToPdf';
import CodeContainer from './CodeContainer';
import { Button, Form } from 'react-bootstrap';
import { _supistaApiGetRawResponse, _supistaApiPost } from 'lib/server-connection/connections';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AIToQuery from '../../QueryGenerator/AIToQuery';
import { FaHistory } from 'react-icons/fa';
import { defaultTemplateCode } from './templateData';
import { Play } from 'react-bootstrap-icons';

import { v4 as uuid } from 'uuid';
import { outputTypeObject } from '../../data/appComponentData';
import ConfirmReset from '../CodeCustomization/ConfirmReset';
import { getDefaultCodeForTemplate } from '../../utils/componentSettingFunction';
import { mls } from 'lib/multilanguagesupport';
interface emailTemplateProps {
  downloadPdfButtonRef: any;
  connectedTablesData: any;
  appID: string;
  templateData: any;
  componentSchema: any;
  setTemplateData: Function;
  sampleData: any;
  appSchema: any;
  inputData: any;
  setInputData: (e: string) => any;
  assetsObject: any;
  selectedId: number | string;
}
const codeContainerTypes = {
  code: {
    key: 'code',
    name: 'Code',
  },
  output: {
    key: 'output',
    name: 'Output',
  },
  html: {
    key: 'html',
    name: 'Html Editor',
  },
  data: {
    key: 'data',
    name: 'Data',
  },
  input: {
    key: 'input',
    name: 'Input',
  },

  // variables: {
  //   key: 'variables',
  //   name: 'Variables',
  // },
};
const usableContainerTypes = {
  html: codeContainerTypes.html,
  input: codeContainerTypes.input,
  data: codeContainerTypes.data,

  // variables: {
  //   key: 'variables',
  //   name: 'Variables',
  // },
};
const defaultOutputData = { msg: {}, outputType: outputTypeObject.normal };
const EmailTemplate = ({
  downloadPdfButtonRef,
  appID,
  connectedTablesData,
  templateData,
  componentSchema,
  setTemplateData,
  sampleData,
  appSchema,
  assetsObject,
  inputData,
  setInputData,
}: emailTemplateProps) => {
  const [htmlContent, setHtmlContent] = useState(templateData?.htmlString);
  const [outputData, setOutputData] = useState(defaultOutputData);
  const [isCodeTestLoading, setIsCodeTestLoading] = useState(false);
  const [codeData, setCodeData] = useState(
    getDefaultCodeForTemplate({
      selectTableName: templateData?.origin,
      currentCode: templateData?.code?.functionCode ?? defaultTemplateCode,
      templateType: templateData?.type,
    }) ?? defaultTemplateCode
  );
  const [assetsResponseData, setAssetsResponseData] = useState<any>({});
  const [codeContainerType, setCodeContainerType] = useState(codeContainerTypes.html.key);
  const debounceHtmlContent = useDebounce(htmlContent);
  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);
  const [isAIQuaryPopup, setIsAIQuaryPopup] = useState(false);
  const [aiQuaryData, setAiQuaryData] = useState('');
  const handelContainetChange = (value: string) => {
    setHtmlContent(value ?? '');
    setTemplateData({ ...templateData, htmlString: value ?? '' });
  };
  const [query, setQuery] = useState('');
  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };
  const [isCodeDataChange, setIsCodeDataChange] = useState(true);

  // const getTableList = () => {
  //   let tableList: any[] = [];
  //   const tableSchema = selectedCode?.tableSchema ?? [];
  //   const mainTableName = newConnectedTableData?.tableName?.[0];
  //   tableSchema.forEach(({ tableID, tableUsed }: { tableID: string; tableUsed: string }) => {
  //     const tableElem = appDatatable?.[tableID];
  //     if (tableElem) {
  //       let columns = '';
  //       (tableElem?.columnSchema ?? []).forEach((columnElem: any, index: number) => {
  //         let options = '';
  //         if (columnElem?.dataType === erpAllDataType.select.key) {
  //           (columnElem?.options?.selectMenu ?? []).forEach(
  //             (selectMenuElem: any, index: number) => {
  //               if (index === 0) {
  //                 options = ` <${selectMenuElem?.value}`;
  //               } else {
  //                 options = `${options}, ${selectMenuElem?.value}`;
  //               }
  //             }
  //           );
  //           if (options) {
  //             options = `${options}>`;
  //           }
  //         }
  //         const newDatatype =
  //           erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType
  //             ?.key ?? erpSimpleDataType.text.key;
  //         if (index === 0) {
  //           columns = `${columnElem?.columnName} (${newDatatype})${options}`;
  //         } else {
  //           columns = `${columns}, ${columnElem?.columnName} (${newDatatype})${options}`;
  //         }
  //       });
  //       tableList = [
  //         ...tableList,
  //         {
  //           tableName: tableElem?.tableID,
  //           columns: columns,
  //           [`${mainTableName} - ${tableElem?.tableID} - relations`]: tableUsed,
  //         },
  //       ];
  //     }
  //   });
  //   return { tableList };
  // };
  const handleGenerateCode = async () => {
    let columnString: string = '';
    // const selectedTableData = connectedTablesData[selectedTable];
    // (selectedTableData?.columnSchema || []).forEach((columnElem: any, index: number) => {
    //   const newDatatype =
    //     erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType
    //       ?.key ?? erpSimpleDataType.text.key;

    //   if (index === 0) {
    //     columnString = `${columnElem?.columnName} (${newDatatype})`;
    //   } else {
    //     columnString = `${columnString}, ${columnElem?.columnName} (${newDatatype})`;
    //   }
    // });
    // const { tableList } = getTableList();
    let tableName: string[] = [];
    let columnSchema: { [key: string]: any }[] = [];

    Object.keys(connectedTablesData).forEach((connectedTablesKey) => {
      tableName = [...tableName, connectedTablesKey];
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      const newColumSchema = (connectedTablesElem?.columnSchema ?? []).map((columnElem: any) => {
        return { ...columnElem, tableID: connectedTablesKey };
      });
      columnSchema = [...columnSchema, ...newColumSchema];
    });
    const connectedTableFinalData = {
      tableName: tableName,
      columnSchema: columnSchema,
    };
    const baseUrl = `aiQuery/${appID}`;
    let payloadData: { [key: string]: any } = {
      payloadData: {
        tableName: connectedTableFinalData?.tableName,
        columnSchema: connectedTableFinalData?.columnSchema,
      },
    };
    if (codeContainerType === codeContainerTypes.html.key) {
      payloadData.queryArray = [
        {
          promptType: 'PDFTemplate',
          query: query,
          apiType: 'PUT',
        },
      ];
    } else {
      payloadData.queryArray = [
        {
          promptType: 'CRUD',
          query: query,
          apiType: 'GET',
        },
      ];
      payloadData.data = {
        // tableList: tableList,
        selectedTable: {
          columns: columnString,
          // tableName: selectedTableData?.tableID,
        },
      };
      const finalCode =
        getDefaultCodeForTemplate({
          selectTableName: templateData?.origin,
          currentCode: defaultTemplateCode,
          templateType: templateData?.type,
        }) ?? defaultTemplateCode;
      payloadData.code = finalCode;
    }
    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data: payloadData });
    if (componentRes?.[0]) {
      setIsAIQuaryPopup(true);
      setAiQuaryData(componentRes[0]?.response ?? '');
      setIsAiQueryLoading(false);
    } else {
      setIsAiQueryLoading(false);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleGenerateCode();
  };
  const handleCodeChange = (data: string) => {
    if (codeContainerType === codeContainerTypes.html.key) {
      setHtmlContent(data);
    } else {
      setCodeData(data);
    }
  };
  const handleTestCode = async () => {
    const componentID = componentSchema?.componentID;
    const baseUrl = `app/${appID}/TestCustomCode/${componentID}`;
    const functionID = templateData?.code?.functionID ?? uuid();
    let parsedInput = {};

    const data = {
      functionCode: codeData,
      functionID: functionID,
      functionName: 'customizeERP',
      inputs: parsedInput ?? {},
      isFormCustomization: true,
    };
    setIsCodeTestLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.__d3__success) {
      const code = {
        fuctionName: 'actionFunc',
        functionID: functionID,
        functionCode: codeData ?? '',
      };
      setTemplateData({ ...templateData, code: code });

      let finalOutput = { ...outputData };
      if (componentRes?.response?.codeResponse?.errorType) {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.error,
        };
      } else {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.success,
        };
      }
      setOutputData(finalOutput);
      setCodeContainerType(codeContainerTypes.output.key);
      setIsCodeTestLoading(false);
    } else {
      if (componentRes?.error) {
        let finalOutput = { ...outputData };
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.error ?? {} },
          outputType: outputTypeObject.error,
        };
        setOutputData(finalOutput);
        setCodeContainerType(codeContainerTypes.output.key);
      }
      setIsCodeTestLoading(false);
    }
  };
  const [confirmResetModal, setConfirmResetModal] = useState(false);

  const handleResetCode = () => {
    let newTemplateData = { ...templateData };
    delete newTemplateData?.code;
    setTemplateData(newTemplateData);
    const newTemplateCode =
      getDefaultCodeForTemplate({
        selectTableName: templateData?.origin,
        currentCode: defaultTemplateCode,
        templateType: templateData?.type,
      }) ?? defaultTemplateCode;
    setCodeData(newTemplateCode);
    setConfirmResetModal(false);
  };
  const [htmLoading, setHtmLoading] = useState(false);
  useEffect(() => {
    function extractAssetKeys(htmlString: string) {
      const placeholderPattern = /{{assets(?:\.\[(.+?)\]|\.(\w+))}}/g;
      const matches = [];
      let match;
      while ((match = placeholderPattern.exec(htmlString)) !== null) {
        matches.push(match[1] || match[2]);
      }

      return matches;
    }

    const allAssetsKeyArray = extractAssetKeys(htmlContent);

    (async () => {
      setHtmLoading(true);
      // const allAssetsKeyArray = Object.keys(appSchema?.app?.assets ?? {});
      const newDownloadableAssetsKey = allAssetsKeyArray.filter((assetKey) => {
        const assetsData = assetsObject?.[assetKey];
        if (assetsResponseData[assetsData?.assetName]) {
          return false;
        }
        return true;
      });

      const assetsKeyArray: string[] = newDownloadableAssetsKey;
      const newAssetsData = await getAssetsData({
        assetsData: assetsObject,
        assetsKeyArray,
        appID,
      });
      setAssetsResponseData({ ...assetsResponseData, ...newAssetsData });
      setHtmLoading(false);
    })();

    // console.log(htmlContent);
  }, [htmlContent, assetsObject, appID]);

  const finalInput = useMemo(() => {
    try {
      return JSON.parse(inputData);
    } catch (error) {
      // console.error('Error parsing input data', error);
      return {};
    }
  }, [inputData]);
  return (
    <div className={styles.PDFTemplateOuterContainer}>
      <div className={styles.PDFTemplateContainer}>
        <div className={styles.templateLeftContainer}>
          <div className={styles.codeContainerHeader}>
            {React.Children.toArray(
              Object.keys(usableContainerTypes).map((codeContainerTypeKey) => {
                const codeContainerTypeElem =
                  usableContainerTypes[codeContainerTypeKey as keyof typeof usableContainerTypes];
                return (
                  <div
                    className={`${styles.codeContainerHeaderOption} ${
                      codeContainerType === codeContainerTypeKey ? styles.activeContainerTab : ''
                    }`}
                    onClick={() => {
                      setIsCodeDataChange(true);
                      setCodeContainerType(codeContainerTypeElem.key);
                    }}
                  >
                    {mls(codeContainerTypeElem.name)}
                    {codeContainerTypeElem.key === codeContainerTypes.output.key ? (
                      <div
                        className={`${styles.outputColorIndicator} ${
                          styles?.[outputData?.outputType?.class ?? outputTypeObject?.normal?.class]
                        }`}
                      ></div>
                    ) : null}
                  </div>
                );
              })
            )}
            <ConfirmReset
              confirmResetModal={confirmResetModal}
              setConfirmResetModal={setConfirmResetModal}
              handleReset={handleResetCode}
              buttonType={'buttonType'}
            />
            <div
              // className={` ${styles.resetCodeButton} ${
              //   buttonType !== buttonTypeObject.actionButton.key ? styles.MarginLeftAuto : ''
              // }`}
              className={` ${styles.resetCodeButton} ${styles.MarginLeftAuto}`}
              onClick={() => setConfirmResetModal(true)}
            >
              {mls('Reset Code')}
            </div>
            <div className={` ${styles.runCodeButton}`} onClick={() => handleTestCode()}>
              {isCodeTestLoading ? (
                <>
                  <CircularProgress color='inherit' size={10} /> &nbsp;
                </>
              ) : null}
              {mls('Run Code')}
              &nbsp;
              <Play />
            </div>
          </div>
          <CodeContainer
            sampleData={sampleData}
            htmlContent={htmlContent}
            handelContainetChange={handelContainetChange}
            codeContainerType={codeContainerType}
            codeContainerTypes={codeContainerTypes}
            outputData={outputData}
            codeData={codeData}
            setCodeData={setCodeData}
            inputData={inputData}
            setInputData={setInputData}
            templateData={templateData}
            setIsCodeDataChange={setIsCodeDataChange}
            isCodeDataChange={isCodeDataChange}
            setCodeContainerType={setCodeContainerType}
          />
        </div>
        <div className={styles.templateRightContainer}>
          <HtmlToPdf
            htmLoading={htmLoading}
            htmlContent={debounceHtmlContent}
            // htmlContentData={sampleData}
            htmlContentData={{ ...(finalInput ?? {}) }}
            // htmlContentData={{ ...sampleData, assets: assetsResponseData }}
            templateData={templateData}
            downloadPdfButtonRef={downloadPdfButtonRef}
          />
        </div>
      </div>
      <div>
        {isAIQuaryPopup ? (
          <AIToQuery
            isAIQuaryPopup={isAIQuaryPopup}
            aiQuaryData={aiQuaryData}
            setIsAIQuaryPopup={setIsAIQuaryPopup}
            handleCodeChange={handleCodeChange}
            defaultLanguage={
              codeContainerType === codeContainerTypes.html.key ? 'handlebars' : 'javascript'
            }
            code={htmlContent}
          />
        ) : null}
        <Form className={styles.queryGenerator} onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.queryHistoryButton} onClick={() => setIsAIQuaryPopup(true)}>
            <FaHistory />
          </div>
          <Form.Group controlId='queryInput' className={styles.queryInput}>
            <Form.Control
              type='text'
              // placeholder='Type your query here...'
              placeholder={mls('Generate Code using AI ...')}
              value={query}
              onChange={handleQueryChange}
            />
          </Form.Group>
          <Button variant='primary' onClick={handleGenerateCode} className={styles.generateButton}>
            {isAiQueryLoading ? (
              <>
                <CircularProgress color='inherit' size={12} />
                &nbsp;
              </>
            ) : (
              <SendIcon fontSize={'small'} />
            )}
            &nbsp;
            {mls('Send')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default EmailTemplate;
const getAssetsData = async ({
  assetsKeyArray,
  assetsData,
  appID,
}: {
  assetsKeyArray: string[];
  assetsData: { [key: string]: any };
  appID: string;
}) => {
  let assetDataObject: { [key: string]: string } = {};
  if (assetsKeyArray) {
    for (const appAssetsKey of assetsKeyArray) {
      try {
        // const appAssetsElem = appSchema?.app?.assets?.[appAssetsKey];
        const appAssetsElem = assetsData?.[appAssetsKey];
        if (!appAssetsElem) {
          console.warn(`Asset not found for key: ${appAssetsKey}`);
          continue;
        }

        const endpoint = `manageFile/${appID}/assets/${appID}/${appAssetsElem.fileUrl}`;
        const response: any = await _supistaApiGetRawResponse(endpoint);

        if (!response?.ok) {
          console.error(`Failed to fetch asset from ${endpoint}`);
          continue;
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        // assetDataObject[appAssetsElem.assetName] = {
        //   assetName: appAssetsElem.fileName || 'Sample.png',
        //   assetUrl: blobUrl,
        // };
        assetDataObject = {
          ...assetDataObject,
          [appAssetsElem.assetName]: blobUrl,
        };
      } catch (error) {
        console.error(`Error processing asset key: ${appAssetsKey}`, error);
      }
    }
  }
  return assetDataObject;
};
