import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './chekBox.scss';

interface CheckBoxProps {
  checked: boolean;
  onClick?: Function;
  disabled?: Boolean;
  checkBoxCheckedColor?: string;
  checkBoxUncheckedColor?: string;
  checkBoxBorderCheckedColor?: string;
  checkBoxBorderUncheckedColor?: string;
  checkBoxSize?: 'normalCheckBox' | 'mediumCheckBox' | 'largeCheckBox';
  customPadding?: number;
}
const CheckBox = ({
  checked,
  onClick = () => {},
  disabled = false,
  checkBoxCheckedColor = '',
  checkBoxUncheckedColor = '',
  checkBoxBorderCheckedColor = '',
  checkBoxBorderUncheckedColor = '',
  checkBoxSize = 'normalCheckBox',
  customPadding = 0,
}: CheckBoxProps) => {
  return (
    <div
      className={`CustomCheckBoxInputContainer ${checked ? 'checked' : ''} ${checkBoxSize}  ${
        disabled ? 'disabled' : ''
      }`}
      style={
        checked
          ? {
              backgroundColor: checkBoxCheckedColor,
              border: checkBoxBorderCheckedColor ? `1px solid ${checkBoxBorderCheckedColor}` : '',
              // padding: customPadding,
            }
          : {
              backgroundColor: checkBoxUncheckedColor,
              border: checkBoxBorderUncheckedColor
                ? `1px solid ${checkBoxBorderUncheckedColor}`
                : '',
              // padding: customPadding,
            }
      }
      onClick={() => !disabled && onClick(!checked)}
    >
      <FaCheck className='checkIcon' />
    </div>
  );
};

export default CheckBox;
