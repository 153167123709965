import { relationTypeObjects } from 'lib/appComponent/componentSettings/data/appComponentData';
import { udpateTableListElem } from './handleTableListUpdate';
import { udpateRoleAccessListElem } from './handleRoleAccessUpdate';
import { handleUpdateOperation } from './handleUpdateOperation';
import { handleCodeCustomizationUpdate } from './handleCodeCustomizationUpdate';
export const actionTypes = {
  UPDATE: {
    key: 'UPDATE',
  },
  DELETE: {
    key: 'DELETE',
  },
};
export const relationNestedLevel = 2;
interface componentUpdateFunctionProps {
  allComponentSchema: { [key: string]: any };
  allDataTableData: { [key: string]: any };
  updatedRelatedTableList: { [key: string]: any };
  // targetedTableData: {
  //   tableID: string;
  //   operation: string;
  //   originalDataTableSchema: { [key: string]: any };
  //   currentDataTableSchema: { [key: string]: any };
  // }[];
  targetedTableElem: {
    tableID: string;
    operation: string;
    originalDataTableSchema: { [key: string]: any };
    currentDataTableSchema: { [key: string]: any };
    tableColumnsUpdates: { [key: string]: any };
    // dataTableSchema: any;
  };
  isMainTable?: boolean;
  prevTableUpdates?: { [key: string]: any };
}
export const componentUpdateFunction = ({
  allComponentSchema,
  allDataTableData,
  updatedRelatedTableList,
  // targetedTableData,
  targetedTableElem,
  isMainTable = true,
  prevTableUpdates = {},
}: componentUpdateFunctionProps) => {
  let originalComponent: { [key: string]: any } = {};
  let affectedComponent: { [key: string]: any } = {};
  let tableUpdates: { [key: string]: any } = { ...(prevTableUpdates ?? {}) };
  const relationsData = targetedTableElem?.currentDataTableSchema?.relations ?? {};

  // updateTablePervTableUpdate
  Object.keys(updatedRelatedTableList ?? {})?.forEach((relatedTableListElemKey: string) => {
    const relatedTableListElem = updatedRelatedTableList[relatedTableListElemKey];
    const tableUpdateElem = {
      action: actionTypes.UPDATE.key,
      tableID: relatedTableListElemKey,
      tableData: relatedTableListElem,
    };
    tableUpdates = { ...tableUpdates, [tableUpdateElem.tableID]: tableUpdateElem };
  });

  // Handle Operation Actions
  if (targetedTableElem?.operation === actionTypes.DELETE.key) {
    const deleteOperationData = handleDeleteOperation({
      allComponentSchema,
      allDataTableData,
      targetedTableElem,
      relationsData,
      previousTableUpdates: tableUpdates,
    });
    originalComponent = { ...originalComponent, ...(deleteOperationData?.originalComponent ?? {}) };
    affectedComponent = { ...affectedComponent, ...(deleteOperationData?.affectedComponent ?? {}) };
    tableUpdates = { ...tableUpdates, ...(deleteOperationData?.tableUpdates ?? {}) };
  }
  if (targetedTableElem?.operation === actionTypes.UPDATE.key) {
    const updateOperationData = handleUpdateOperation({
      allComponentSchema,
      allDataTableData,
      targetedTableElem,
      previousTableUpdates: tableUpdates,
    });
    originalComponent = {
      ...originalComponent,
      ...(updateOperationData?.originalComponent ?? {}),
    };
    affectedComponent = {
      ...affectedComponent,
      ...(updateOperationData?.affectedComponent ?? {}),
    };
    tableUpdates = { ...tableUpdates, ...(updateOperationData?.tableUpdates ?? {}) };
  }

  if (isMainTable) {
    Object.keys(tableUpdates ?? {}).forEach((tableUpdatesElemKey) => {
      const tableUpdatesElem = tableUpdates[tableUpdatesElemKey];
      const getOriginalTableSchema = ({ tableData }: { tableData: { [key: string]: any } }) => {
        const tableUpdateData = tableUpdates[tableData?.tableID];
        if (tableUpdateData) {
          return { tableData: tableUpdateData?.tableData };
        }
        return { tableData: tableUpdatesElem?.tableData };
      };
      const affectedData = componentUpdateFunction({
        allComponentSchema: { ...originalComponent, ...affectedComponent },
        allDataTableData: allDataTableData,
        updatedRelatedTableList: {},
        prevTableUpdates: tableUpdates,
        targetedTableElem: {
          tableID: tableUpdatesElem?.tableID,
          operation: tableUpdatesElem.action,
          tableColumnsUpdates: {},
          originalDataTableSchema: getOriginalTableSchema({
            tableData: tableUpdatesElem?.tableData,
          })?.tableData,
          currentDataTableSchema: tableUpdatesElem?.tableData,
        },
        isMainTable: false,
      });
      originalComponent = {
        ...originalComponent,
        ...(affectedData?.originalComponent ?? {}),
      };
      affectedComponent = {
        ...affectedComponent,
        ...(affectedData?.affectedComponent ?? {}),
      };
      tableUpdates = {
        ...tableUpdates,
        ...(affectedData?.tableUpdates ?? {}),
      };
    });
  }

  return { affectedComponent, originalComponent, tableUpdates };
};

interface handleDeleteOperationProps {
  allComponentSchema: { [key: string]: any };
  allDataTableData: { [key: string]: any };
  previousTableUpdates: { [key: string]: any };

  targetedTableElem: {
    tableID: string;
    operation: string;
    originalDataTableSchema: { [key: string]: any };
    currentDataTableSchema: { [key: string]: any };
  };
  relationsData: { [key: string]: any };
}
const handleDeleteOperation = ({
  allComponentSchema,
  allDataTableData,
  targetedTableElem,
  relationsData,
  previousTableUpdates,
}: handleDeleteOperationProps) => {
  let originalComponent: { [key: string]: any } = {};
  let affectedComponent: { [key: string]: any } = {};
  let tableUpdates: { [key: string]: any } = { ...previousTableUpdates };

  // handleComponentUpdate
  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];
    let affected = false;
    let updatedComponentSchema = { ...componentElem };
    if (componentElem?.table === targetedTableElem?.tableID) {
      delete updatedComponentSchema.tableList;
      delete updatedComponentSchema.table;
      delete updatedComponentSchema.codeCustomization;
      delete updatedComponentSchema.template;
      affected = true;
    } else {
      const udpateTable = getUpdatedTableList({
        componentElem,
        targetedTableElem,
        nestedLevel: relationNestedLevel,
      });
      if (udpateTable?.affected) {
        affected = udpateTable?.affected;
        updatedComponentSchema.tableList = udpateTable?.updatedTableList;
      }
    }
    if (affected) {
      affectedComponent = { ...affectedComponent, [componentElemKey]: updatedComponentSchema };
      originalComponent = { ...originalComponent, [componentElemKey]: componentElem };
    }
  });
  // handleTableUpdate
  Object.keys(relationsData).forEach((relationElemKey: string) => {
    const relationElem = relationsData[relationElemKey];
    // If belongsToMany
    const { belongToTableUpdates } = handleBelongToTableRemove({
      allDataTableData,
      relationElem,
      previousTableUpdates,
    });
    tableUpdates = {
      ...tableUpdates,
      ...belongToTableUpdates,
    };
  });
  return { originalComponent, affectedComponent, tableUpdates };
};

export const handleBelongToTableRemove = ({
  allDataTableData,
  relationElem,
  previousTableUpdates,
}: {
  allDataTableData: { [key: string]: any };
  relationElem: { [key: string]: any };
  previousTableUpdates: { [key: string]: any };
}) => {
  let tableUpdates: { [key: string]: any } = { ...previousTableUpdates };

  // If belongsToMany
  if (relationElem?.type === relationTypeObjects.belongsToMany.key) {
    const throughTableID = relationElem?.through;
    if (allDataTableData[throughTableID]) {
      tableUpdates = {
        ...tableUpdates,
        [throughTableID]: {
          action: actionTypes.DELETE.key,
          tableID: throughTableID,
          tableData: allDataTableData[throughTableID],
        },
      };
    }
    const targetTableID = relationElem?.target;
    // isTableExist in allDataTable
    if (allDataTableData[targetTableID]) {
      // isTableExist in tableUpdates
      if (tableUpdates[targetTableID]) {
        // isExisted Table is not DELETED
        if (tableUpdates[targetTableID]?.action !== actionTypes.DELETE.key) {
          const tableData = tableUpdates[targetTableID]?.tableData ?? {};
          const tableRelation = { ...(tableData?.relations ?? {}) };
          delete tableRelation?.[relationElem.as];

          tableUpdates = {
            ...tableUpdates,
            [targetTableID]: {
              action: actionTypes.UPDATE.key,
              tableID: targetTableID,
              tableData: allDataTableData[targetTableID],
            },
          };
        }
      } else {
        const tableData = allDataTableData[targetTableID];
        const updateRelations = { ...(tableData?.relations ?? {}) };
        delete updateRelations?.[relationElem.as];
        tableUpdates = {
          ...tableUpdates,
          [targetTableID]: {
            action: actionTypes.UPDATE.key,
            tableID: targetTableID,
            tableData: { ...tableData, relations: updateRelations },
          },
        };
      }
    }
  }
  return { belongToTableUpdates: tableUpdates };
};
const getUpdatedTableList = ({
  componentElem,
  targetedTableElem,
  nestedLevel,
}: {
  componentElem: any;
  targetedTableElem: any;
  nestedLevel: number;
}) => {
  let affected = false;
  // Handle TableList
  let updatedTableList: { [key: string]: any }[] = [];
  if (Array.isArray(componentElem?.tableList)) {
    (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
      let updatedTableListElem = { ...tableListElem };
      let isTableListElemDeleted = false;
      if (tableListElem?.tableID === targetedTableElem?.tableID) {
        isTableListElemDeleted = true;
        affected = true;
        return;
      }
      if (!isTableListElemDeleted) {
        if (nestedLevel >= 0) {
          const { updatedTableList: updatedInnerTableList, affected: affectedInner } =
            getUpdatedTableList({
              componentElem: tableListElem,
              targetedTableElem,
              nestedLevel: nestedLevel - 1,
            });
          if (affectedInner) {
            updatedTableListElem = { ...updatedTableListElem, tableList: updatedInnerTableList };
            affected = true;
          }
          updatedTableList = [...updatedTableList, updatedTableListElem];
        }
      }
    });
  }
  return { affected, updatedTableList };
};

export const handleColumnUpdates = ({
  tableColumnsUpdates,
  componentElem,
  targetedTableElem,
}: {
  tableColumnsUpdates: { [key: string]: any };
  componentElem: { [key: string]: any };
  targetedTableElem: { [key: string]: any };
}) => {
  const targatedTableID = targetedTableElem?.tableID;
  let updatedComponentElem = { ...componentElem };

  // tableList
  const { isTargetTableUpdated: isTargetTableUpdatedInner, updateTableList } = udpateTableListElem({
    tableList: updatedComponentElem?.tableList ?? [],
    targatedTableID,
    tableColumnsUpdates,
  });

  if (isTargetTableUpdatedInner) {
    updatedComponentElem = { ...updatedComponentElem, tableList: updateTableList };
  }
  // roleAccess
  const { isTargetTeamUpdated: isTargetTeamUpdatedInner, updatedRoleAccessData } =
    udpateRoleAccessListElem({
      roleAccess: updatedComponentElem?.roleAccess ?? {},
      tableList: updatedComponentElem?.tableList ?? {},
      targetedTableElem,
      tableColumnsUpdates,
    });
  if (isTargetTeamUpdatedInner) {
    updatedComponentElem = { ...updatedComponentElem, roleAccess: updatedRoleAccessData };
  }

  // roleAccess
  const {
    isTargetCodeCustomizationUpdated: isTargetCodeCustomizationUpdatedInner,
    updatedCodeCustomizationData,
  } = handleCodeCustomizationUpdate({
    codeCustomization: updatedComponentElem?.codeCustomization ?? {},
    tableList: updatedComponentElem?.tableList ?? [],
    targetedTableElem,
    tableColumnsUpdates,
  });
  if (isTargetCodeCustomizationUpdatedInner) {
    updatedComponentElem = {
      ...updatedComponentElem,
      codeCustomization: updatedCodeCustomizationData,
    };
  }
  return { isTargetTableUpdated: isTargetTableUpdatedInner, updatedComponentElem };
};
