import { getSelectTableFinalKey } from 'lib/appComponent/componentSettings/utils/componentSettingFunction';

export const handleCodeCustomizationUpdate = ({
  codeCustomization,
  tableList,
}: {
  codeCustomization: { [key: string]: any };
  tableList: { [key: string]: any }[];
  targetedTableElem: { [key: string]: any };
  tableColumnsUpdates: { [key: string]: any };
}) => {
  let isTargetCodeCustomizationUpdated = false;
  let updatedCodeCustomizationData = {};

  Object.keys(codeCustomization ?? {}).forEach((codeCustomizationKey) => {
    const codeCustomizationElem = codeCustomization[codeCustomizationKey];
    const getIsRoleAcessElemPresentInTableList = () => {
      const isPresent = (tableList ?? []).some((tableListElem: any) => {
        const finalTableID = getSelectTableFinalKey({
          tableId: tableListElem?.tableID,
          innerRelationID: tableListElem?.innerRelationID,
          relationID: tableListElem?.relationID,
          parentRelationIDList: tableListElem?.parentRelationIDList,
        });
        return codeCustomizationKey === finalTableID;
      });
      return { isPresent: isPresent };
    };
    const isRoleAcessElemPresentInTableList = getIsRoleAcessElemPresentInTableList();

    if (isRoleAcessElemPresentInTableList.isPresent) {
      updatedCodeCustomizationData = {
        ...updatedCodeCustomizationData,
        [codeCustomizationKey]: codeCustomizationElem,
      };
    } else {
      isTargetCodeCustomizationUpdated = true;
    }
  });
  return { isTargetCodeCustomizationUpdated, updatedCodeCustomizationData };
};
