import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import markDownDefaultImport from 'lib/erp-data-table/components/util/ComponentUpdateFunctions/Component and Table Data Management Function Description Data.md';
import styles from './warningPopup.module.scss';
// import remarkGfm from 'remark-gfm';
const RenderReadMDFile = () => {
  const [markDownData, setMarkDownData] = useState({ markDownText: '' });
  const getMarkdownData = () => {
    fetch(markDownDefaultImport)
      .then((response) => response.text())
      .then((text) => {
        setMarkDownData({ markDownText: text });
      });
  };
  useEffect(() => {
    getMarkdownData();
  }, []);
  return (
    <div className={styles.MarkDownContainer}>
      <Markdown>{markDownData.markDownText}</Markdown>
    </div>
  );
};

export default RenderReadMDFile;
