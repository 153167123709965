import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React from 'react';

import styles from './AssetsSelection.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';

interface assetsSelectionPorps {
  isAssetsSelectedPopup: boolean;
  setIsAssetsSelectedPopup: (e: boolean) => void;
  appSchema: { [key: string]: any };
  appID: string;
  assetBlobs: { [key: string]: any };
  selectedAssets: { [key: string]: any };
  setSelectedAssets: (e: { [key: string]: any }) => void;
}
const AssetsSelection = ({
  isAssetsSelectedPopup,
  setIsAssetsSelectedPopup,
  assetBlobs,
  selectedAssets,
  setSelectedAssets,
}: assetsSelectionPorps) => {
  return (
    <div className={styles.AppAssets}>
      <PopUpDivChild
        initialValue={isAssetsSelectedPopup}
        setPopUp={setIsAssetsSelectedPopup}
        popupTitle={mls('Assets Selection')}
      >
        <div className={styles.appAssetsList}>
          {React.Children.toArray(
            Object.keys(assetBlobs ?? {}).map((appAssetskey: string, index: number) => {
              const appAssetsElem = assetBlobs?.[appAssetskey];
              return (
                <AppAssetsListElem
                  appAssetsElem={appAssetsElem}
                  index={index}
                  setSelectedAssets={setSelectedAssets}
                  selectedAssets={selectedAssets}
                />
              );
            })
          )}

          {(Object.keys(assetBlobs) ?? []).length === 0 ? (
            <div style={{ padding: '1rem' }}>
              {mls("No assets available. Click 'Upload Assets' to add new assets.")}
            </div>
          ) : null}
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default AssetsSelection;
const AppAssetsListElem = ({
  appAssetsElem,
  index,
  selectedAssets,
  setSelectedAssets,
}: {
  appAssetsElem: { [key: string]: any };
  index: number;
  selectedAssets: { [key: string]: any };
  setSelectedAssets: (e: { [key: string]: any }) => void;
}) => {
  const isCheck = selectedAssets[appAssetsElem?.assetID] ? true : false;
  const handleAssetSelection = () => {
    let finalSelectedAssets: { [key: string]: any } = { ...selectedAssets };
    if (isCheck) {
      delete finalSelectedAssets[appAssetsElem?.assetID];
    } else {
      finalSelectedAssets = { ...finalSelectedAssets, [appAssetsElem?.assetID]: appAssetsElem };
    }
    setSelectedAssets(finalSelectedAssets);
  };
  return (
    <div key={index} className={styles.appAssetsListElem} onClick={() => handleAssetSelection()}>
      <div className={styles.appAssetsImage}>
        <img src={appAssetsElem.assetUrl} alt={appAssetsElem.assetName} />
      </div>
      <div className={styles.appAssetsListName}>
        <CheckBox checked={isCheck} onClick={() => {}} />
        {appAssetsElem?.assetName}
      </div>
    </div>
  );
};
