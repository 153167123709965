import React from 'react';
import styles from './relatedFields.module.scss';
import { relationTypeObjects } from 'lib/appComponent/componentSettings/data/appComponentData';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';

interface chooseColumnProps {
  selecteTable: { [key: string]: any };
  relationData: { [key: string]: any };
  handleColumnValue: Function;
}
const ChooseColumn = ({ selecteTable, relationData, handleColumnValue }: chooseColumnProps) => {
  return (
    <div className={styles.relatedFieldsColumnOuterContainer}>
      <div className={styles.relatedFieldsChooseColumnTitle}>{mls('Choose Column')}</div>
      <div className={styles.relatedFieldsColumnInnerContainer}>
        {React.Children.toArray(
          (selecteTable?.columnSchema ?? []).map((columnElem: { [key: string]: any }) => {
            const isSelected = (relationData?.defaultView ?? []).some(
              (columnNameElem: any) => columnNameElem?.columnName === (columnElem?.columnName ?? '')
            );
            const handleColumnToggle = () => {
              let finalColumnData: any = [...(relationData?.defaultView ?? [])];

              if (isSelected) {
                finalColumnData = (finalColumnData ?? []).filter(
                  (columnNameElem: any) =>
                    columnNameElem?.columnName !== (columnElem?.columnName ?? '')
                );
              } else {
                finalColumnData = [...finalColumnData, columnElem];
              }
              handleColumnValue(finalColumnData);
            };
            if (columnElem.dataType === relationTypeObjects.belongsTo.key) {
              return null;
            }
            return (
              <>
                <div
                  className={styles.relatedFieldsColumnElem}
                  onClick={() => {
                    handleColumnToggle();
                  }}
                >
                  <SlideSwitch isChecked={isSelected} setIsChecked={() => {}} />
                  <div>
                    {columnElem?.name} ( {columnElem?.dataType} )
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ChooseColumn;
