import React, { useState } from 'react';
import styles from '../columnSettings.module.scss';
import { mls } from 'lib/multilanguagesupport';
import OptionField from './OptionField';
import { checkAllErrorValidtion } from './selectMenuUtils';
import { selectMenuColorPalettes } from './selectMenuColors';

const AddSelectOptions = ({
  selectMenu,
  updateSelectMenu,
  columnData,
}: {
  selectMenu: any;
  updateSelectMenu: Function;
  columnData: { [key: string]: any };
}) => {
  const [addOptionTempData, setAddOptionTempData] = useState<{
    status: boolean;
    optionData: { [key: string]: any };
  }>({
    status: false,
    optionData: {},
  });
  // console.log(selectMenu);
  const addOption = () => {
    if (addOptionTempData.status) {
      const optionErrorData = checkAllErrorValidtion({
        optionData: addOptionTempData?.optionData,
        selectMenu,
      });

      if (optionErrorData.isError) {
        setAddOptionTempData({
          ...addOptionTempData,
          optionData: optionErrorData?.udpatedOptionData,
        });
      } else {
        setAddOptionTempData({
          ...addOptionTempData,
          optionData: optionErrorData?.udpatedOptionData,
        });
        const option = optionErrorData?.udpatedOptionData;
        let updatedSelectMenu = [...(selectMenu ?? []), option];
        updateSelectMenu({ updatedSelectMenu });
        setAddOptionTempData({ status: false, optionData: {} });
      }
    } else {
      // const colorElem =
      //   selectMenuColorPalettes[Math.floor(Math.random() * selectMenuColorPalettes.length)];
      const colorElem = selectMenuColorPalettes[0];
      const option = { label: '', value: '', color: colorElem };
      setAddOptionTempData({ status: true, optionData: option });
    }
  };

  const updateOptionData = ({ optionData }: { optionData: any }) => {
    setAddOptionTempData({ ...addOptionTempData, optionData: optionData });
  };
  const handleChangeOption = (e: any, name: string) => {
    const newOptionData = {
      ...(addOptionTempData.optionData ?? {}),
      [name]: e.target.value,
    };
    updateOptionData({ optionData: newOptionData });
  };
  return (
    <>
      {addOptionTempData.status ? (
        <div className={styles.addValidationContainer}>
          <OptionField
            optionData={addOptionTempData?.optionData}
            handleChangeOption={handleChangeOption}
            updateOptionData={updateOptionData}
            selectMenu={selectMenu}
            columnData={columnData}
          />
          <div>
            <button
              className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
              onClick={(e) => addOption()}
              type='button'
            >
              {mls('Add Option')}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button
            className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
            onClick={(e) => addOption()}
            type='button'
          >
            {mls('Add Option')}
          </button>
        </div>
      )}
    </>
  );
};

export default AddSelectOptions;
