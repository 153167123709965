import { v5 as uuidv5 } from 'uuid';
import { v4 as uuid4 } from 'uuid';

import {
  defaultDataType,
  defaultDateArray,
  defaultDateKey,
  defaultFilterIDData,
  numberConditionData,
  textConditionData,
} from '../data/defaultFilterSchema';
import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';
export const dataTableColumnObjectTypes = {
  text: { name: 'Text', key: 'text' },
  number: { name: 'Number', key: 'number' },
  decimal: { name: 'Decimal', key: 'decimal' },
  boolean: { name: 'Boolean', key: 'boolean' },
  check: { name: 'Checkbox', key: 'check' },
  select: { name: 'Categorical', key: 'select' },
  date: { name: 'Date', key: 'date' },
  id_text: { name: 'Text ID Field', key: 'id_text' },
  id_num: { name: 'Num ID Field', key: 'id_num' },
  // multiple: { name: 'Multiple', key: 'multiple' },
};
const uuidKey = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export const filterByTableName = (data: any) => {
  let mainTableID = '';
  if (!data) return { mainTableID: mainTableID, tableData: [] };
  const normalFilter = () => {
    const tableData = data;
    // Adding unique ID
    const tableSchemaWithID = tableData.map((o: any) => {
      return {
        ...o,
        id: uuidv5(`${o.tableName}.${o.columnName}`, uuidKey),
      };
    });
    // Grouped By TableName
    let o: any = {};

    let groupedArray = tableSchemaWithID.reduce(function (r: any, el: any) {
      let e = el.tableName;

      if (!o[e as keyof typeof o]) {
        o[e as keyof typeof o] = {
          tableName: el?.nameOfTable || el?.tableName,
          relationType: el?.relationType,
          items: [],
        };
        if (el?.relationType === 'MAIN') {
          mainTableID = el?.nameOfTable || el?.tableName;
        }
        r.push(o[e as keyof typeof o]);
      }
      o[e as keyof typeof o]?.items.push(el);
      return r;
    }, []);
    return { mainTableID: mainTableID, groupedArray: groupedArray };
  };
  // return { mainTable: '', tableData: [] };
  return normalFilter();
};
export const tableFilterFuntion = (props: any) => {
  const { search, type, allTableData, setFilterdTableSchema } = props;
  setFilterdTableSchema(() => {
    const newArray = allTableData.filter((el: any) => {
      const lowerCaseElemName = el.name.toLowerCase();
      const lowerCaseElemType = el.dataType;
      const lowerCaseValue = search.toLowerCase();
      if (
        type === defaultDataType.ALL ||
        (erpAllDataType?.[type as keyof typeof erpAllDataType]?.simpleDataType?.key ?? type) ===
          defaultDataType.ALL
      ) {
        // if (type === defaultDataType.ALL) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      // if (lowerCaseElemType === type) {
      if (
        (lowerCaseElemType === type || lowerCaseElemType) ===
        (erpAllDataType?.[type as keyof typeof erpAllDataType]?.simpleDataType?.key ?? type)
      ) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      return false;
    });

    return newArray;
  });
};
export const addFilter = (selectedItems: any) => {
  const newSelection = {
    [`${selectedItems.tableName}.${selectedItems.columnName}`]: {
      column: selectedItems.columnName,
      dataType: selectedItems.dataType,
      sheetID: selectedItems.sheetID,
      tableName: selectedItems.tableName,
      value: [],
      filterData: selectedItems.filterData,
    },
  };
  return newSelection;
};

export const mergeDashboardSchema = (dashSchema: any) => {
  const addingExtraValue = (dashSchema: any) => {
    const newDashFilter = Object.keys(dashSchema).map((dashboradID: string) => {
      const newData = dashSchema[dashboradID as keyof typeof dashSchema].data.map((elem: any) => {
        const uniqueDashboardID = `${dashboradID}.${elem.tableName}.${elem.columnName}`;
        const dashboardName = dashSchema[dashboradID as keyof typeof dashSchema].name;
        const isJoinedTable =
          dashSchema[dashboradID as keyof typeof dashSchema].isJoinedTable || false;
        return { ...elem, uniqueDashboardID, dashboardName, isJoinedTable };
      });
      return newData;
    });
    return newDashFilter;
  };
  const extraValueArray: any[] = addingExtraValue(dashSchema);
  return extraValueArray.flat();
};
export const specialCaseSchema = (tableSchema: any) => {
  const getDefaultColumns = ({ tableData }: { tableData: any }) => {
    let relationData: { [key: string]: any } = {
      tableName: tableData?.tableName,
      relationType: tableData?.relationType,
    };
    if (tableData?.relationType !== 'MAIN') {
      relationData = {
        ...relationData,
        relationID: tableData?.relationID,
      };
    }
    const defaultColumn = [
      {
        columnName: 'id',
        dataType: defaultDataType.ID,
        id: uuid4(),
        name: 'id',
        ...relationData,
      },
      {
        columnName: '__d3__createdBy',
        dataType: defaultDataType.ID,
        id: uuid4(),
        name: 'Created By',
        ...relationData,
      },
      {
        columnName: '__d3__lastUpdatedBy',
        dataType: defaultDataType.ID,
        id: uuid4(),
        name: 'Last Updated By',
        ...relationData,
      },
      {
        columnName: '__d3__createdAt',
        dataType: defaultDataType.date,
        id: uuid4(),
        name: 'Created At',
        ...relationData,
      },
      {
        columnName: '__d3__updatedAt',
        dataType: defaultDataType.date,
        id: uuid4(),
        name: 'Updated At',
        ...relationData,
      },
    ];

    return defaultColumn;
  };

  const uniqueTables = tableSchema.filter(
    (item: any, index: number, self: any) =>
      index === self.findIndex((obj: any) => obj?.tableName === item?.tableName)
  );

  let defaultColumns: any[] = [];
  (uniqueTables ?? []).forEach((tableData: any) => {
    defaultColumns = [...defaultColumns, ...(getDefaultColumns({ tableData }) ?? [])];
  });
  return [...(defaultColumns ?? []), ...tableSchema];
};
export const getUserLevelAccessColumn = ({ tableID }: { tableID: string }) => {
  const target = {
    name: 'User Level Access',
    columnName: defaultDataType.userLevelAccess,
    dataType: defaultDataType.userLevelAccess,
    tableName: tableID,
    value: [],
  };
  return target;
};
interface chartFilterPopulationProps {
  chartWidthObject: object;
  allTableData: any[];
  isDashboard: boolean;
}
export const chartFilterPopulation = ({
  chartWidthObject,
  allTableData,
  isDashboard,
}: chartFilterPopulationProps) => {
  if (chartWidthObject) {
    const Array = Object.entries(chartWidthObject).map(([key, subject]) => {
      if (isDashboard) {
        const [colomnData] = allTableData.filter(
          (e: any) =>
            e.sheetID + '.' + e.tableName + '.' + e.columnName === key ||
            e.tableName + '.' + e.columnName === key
        );
        const newObject = {
          ...colomnData,
          value: subject.value,
        };
        return newObject;
      }
      if (subject?.dataType === defaultDataType.userLevelAccess) {
        const colomnData = getUserLevelAccessColumn({ tableID: subject.tableName });
        const newObject = {
          ...colomnData,
          value: subject.value,
        };
        return newObject;
      }
      const [colomnData] = allTableData.filter(
        (e: any) => e.tableName + '.' + e.columnName === key
      );

      const newObject = {
        ...colomnData,
        value: subject.value,
      };
      return newObject;
    });

    return Array;
  } else {
    return [];
  }
};
interface filtersSchemaProps {
  filterArray: any;
  sheetID: string;
  isDashboard: boolean;
  isDataSource: boolean;
}
export const filtersSchema = ({
  filterArray,
  sheetID,
  isDashboard,
  isDataSource,
}: filtersSchemaProps) => {
  const defaultValue = {
    valueCAT: [],
    valueNUM: {
      min: 0,
      max: Infinity,
      condition: numberConditionData.inRange.key,
    },
    valueID: [],
    valueText: {
      value: [],
      condition: textConditionData.contains.key,
    },
    valueNUMmin: 0,
    valueNUMmax: Infinity,
    valueDATE: defaultDateArray[4],
  };
  const newFilterObject: any = {};
  filterArray.forEach((filterItem: any, i: number) => {
    const { dataType, tableName, columnName, value, nameOfTable, name } = filterItem;
    const updateValue = () => {
      if (dataType === defaultDataType?.CAT || dataType === defaultDataType.ID_TEXT) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.text) {
        return value ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.select) {
        return value ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.switch) {
        return value ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.userLevelAccess) {
        return value ? value : defaultValue.valueCAT;
      }
      if (
        dataType === defaultDataType.NUM ||
        dataType === defaultDataType.ID_NUM ||
        dataType === defaultDataType.number
      ) {
        console.log('value', value);

        return value ? value : defaultValue.valueNUM;
      }
      if (dataType === defaultDataType.ID) {
        return value ?? defaultFilterIDData;
      }
      if (
        dataType === defaultDataType.DATETIME ||
        dataType === defaultDataType.datePicker ||
        dataType === defaultDataType.date
      ) {
        if (value?.key === defaultDateKey.CUSTOM) {
          const newValue = { ...value };
          delete newValue.dateInISO;
          return newValue;
        }
        return value ? value : defaultValue.valueDATE;
      }
      // if (dataType === defaultDataType.ID) {
      //   return value && value[0] ? value : defaultValue.valueCAT;
      // }
      if (dataType === defaultDataType.GEO) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      return {};
    };
    // remove empty catagorical filter
    if (dataType === defaultDataType?.CAT || dataType === defaultDataType.ID_TEXT) {
      if ((value ?? []).length <= 0) {
        return;
      }
    }
    if (dataType === defaultDataType?.userLevelAccess) {
      if (!value?.[(value ?? []).length - 1]?.isUserID) {
        return;
      }
    }
    let newObj: { [key: string]: any } = {
      column: columnName,
      dataType: dataType,
      tableName: tableName,
      name: filterItem?.name,
      relationType: filterItem?.relationType,
      value: updateValue(),
    };
    if (filterItem?.relationType !== 'MAIN') {
      newObj = {
        ...newObj,
        relationID: filterItem?.relationID,
      };
    }
    // Add object with Keynames
    const keyName = `${tableName}.${columnName}`;
    newFilterObject[keyName] = newObj;
    return;
  });
  // console.log(newFilterObject);

  return newFilterObject;
};
