import { relationNestedLevel } from './componentUpdateFunction';

export const handleDeleteRelation = ({
  deleteRelationsList,
  mainTableID,
  allComponentSchema,
}: {
  deleteRelationsList: { [key: string]: any };
  allComponentSchema: { [key: string]: any };
  mainTableID: string;
}) => {
  let updatedDeleteRelationTableList: { [key: string]: any } = {};
  // Outer Table Check
  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];
    // if main table id and component id matched then remove realtions from tableList.
    const { tablePresence: isTablePresent } = checkTablePresence({
      targetTableID: mainTableID,
      componentElem: componentElem,
      nestingLevel: relationNestedLevel - 1,
    });
    if (isTablePresent) {
      const { updatedTableList, tableListAffected } = getRemovedUpdatedTableList({
        deleteRelationsList,
        componentElem: componentElem,
        nestingLevel: relationNestedLevel - 2,
      });
      if (tableListAffected) {
        updatedDeleteRelationTableList = {
          ...updatedDeleteRelationTableList,
          [componentElemKey]: { ...componentElem, tableList: updatedTableList },
        };
      }
    }
  });
  return { updatedDeleteRelationTableList };
};
export const getRemovedUpdatedTableList = ({
  deleteRelationsList,
  componentElem,
  nestingLevel,
}: {
  deleteRelationsList: { [key: string]: any };
  componentElem: { [key: string]: any };
  nestingLevel: number;
}) => {
  let updatedTableList: { [key: string]: any }[] = [];
  let tableListAffected = false;
  if (Array.isArray(componentElem?.tableList)) {
    (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
      let isTableListElemDeleted = false;
      Object.keys(deleteRelationsList).forEach((deleteRelationsElemKey: string) => {
        const deleteRelationsElem = deleteRelationsList[deleteRelationsElemKey];
        if (deleteRelationsElem?.as === tableListElem?.relationID) {
          isTableListElemDeleted = true;
          tableListAffected = true;
        }
      });
      if (!isTableListElemDeleted) {
        let newTableListElem = { ...tableListElem };
        if (nestingLevel >= 0) {
          const { updatedTableList, tableListAffected: innerTableListAffected } =
            getRemovedUpdatedTableList({
              deleteRelationsList,
              componentElem: tableListElem,
              nestingLevel: nestingLevel - 1,
            });
          if (innerTableListAffected) {
            newTableListElem = { ...newTableListElem, tableList: updatedTableList };
            tableListAffected = innerTableListAffected;
          }
        }
        updatedTableList = [...updatedTableList, newTableListElem];
      }
    });
  }
  return { updatedTableList, tableListAffected };
};
const checkTablePresence = ({
  targetTableID,
  componentElem,
  nestingLevel,
}: {
  targetTableID: string;
  nestingLevel: number;
  componentElem: { [key: string]: any };
}) => {
  const nestedTablePresenceCheck = ({
    tableList,
    targetTableID,
    nestingLevel,
  }: {
    nestingLevel: number;
    tableList: { [key: string]: any }[];
    targetTableID: string;
  }) => {
    const isTablePresent = tableList.some((tableElem: any) => {
      const isTablePresent = tableElem?.tableID === targetTableID;
      if (isTablePresent) {
        return true;
      } else {
        if (nestingLevel >= 0) {
          return nestedTablePresenceCheck({
            tableList: tableElem?.tableList ?? [],
            targetTableID,
            nestingLevel: nestingLevel - 1,
          });
        }
      }
      return true;
    });
    return isTablePresent;
  };
  if (componentElem?.table === targetTableID) {
    return { tablePresence: true };
  }
  const tablePresence = nestedTablePresenceCheck({
    tableList: componentElem?.tableList ?? [],
    targetTableID,
    nestingLevel,
  });

  return { tablePresence: tablePresence };
};
