import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './realtionData.module.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { toast } from 'react-toastify';
import './realtionData.scss';
import { FaRegCopy } from 'react-icons/fa';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { getGroupOptionData } from 'lib/appComponent/componentSettings/utils/componentSettingFunction';
import { getConnectedTables } from '../../PDFTemplates/helperFuntion';

interface realtionDataProps {
  isRealtionDataPopup: boolean;
  setIsRealtionDataPopup: Function;
  mainTableID: string;
  updatedConnectedTablesData: { [key: string]: any };
}

const RealtionData = ({
  isRealtionDataPopup,
  setIsRealtionDataPopup,
  updatedConnectedTablesData,
  mainTableID,
}: realtionDataProps) => {
  const [selectedMainTable, setSelectedMainTable] = useState(mainTableID ?? '');

  const [selectedConnectedTable, setSelectedConnectedTable] = useState(
    updatedConnectedTablesData ?? {}
  );
  const [templatesData, setTemplatesData] = useState(selectedConnectedTable);
  const [templatesDataSearch, setTemplatesDataSearch] = useState('');
  const [copiedID, setCopiedID] = useState('');
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  useEffect(() => {
    const getData = ({ selectedMainTable }: { selectedMainTable: string }) => {
      const appDatatable = appSchema?.appDatatable ?? {};
      const connectedTablesData = getConnectedTables({
        tableId: selectedMainTable ?? '',
        appDatatable,
      });
      const { groupOptionData, updatedConnectedTablesData } = getGroupOptionData({
        connectedTablesData: connectedTablesData,
        nestedLevel: 3,
      });
      setSelectedConnectedTable(updatedConnectedTablesData);
      setTemplatesData(updatedConnectedTablesData);
    };
    getData({ selectedMainTable });
  }, [selectedMainTable, appSchema]);

  useEffect(() => {
    const templateData = selectedConnectedTable ?? {};
    let newDownloadData = {};
    Object.keys(templateData ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = templateData[templateKey];
      if (
        (selectedTemplateData?.relationName ?? '')
          .toLowerCase()
          ?.includes(templatesDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
        return;
      }
      if (
        (selectedTemplateData?.name ?? '')
          .toLowerCase()
          ?.includes(templatesDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
        return;
      }
      if (
        (selectedTemplateData?.relationType ?? '')
          .toLowerCase()
          ?.includes(templatesDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
        return;
      }
    });
    setTemplatesData(newDownloadData);
  }, [templatesDataSearch, selectedConnectedTable]);

  const copyTemplateID = ({ id }: any) => {
    const selectedTableDataData = templatesData[id];
    const tableDataData = {
      type: selectedTableDataData?.relationType,
      name: selectedTableDataData?.name ?? '',
      relationName: selectedTableDataData?.relationName ?? '',
      relationID: selectedTableDataData?.relationID ?? '',
    };
    copyToClipBoard({
      text: tableDataData.relationID ?? id,
      customMsg: `${mls('Copied the Realtions ID of')}: ${tableDataData?.relationName ?? id}`,
    });
  };

  const copyToClipBoard = ({
    text = '',
    isMsg = true,
    customMsg = null,
  }: {
    text: string;
    isMsg?: boolean;
    customMsg: null | string;
  }) => {
    navigator.clipboard.writeText(text);

    if (isMsg) {
      const tostMsg = customMsg ?? `${mls('Copied the text')}: ${text}`;
      // Alert the copied text
      toast.success(tostMsg, {
        theme: 'light',
      });
    }
  };
  const onCopy = ({ id }: { id: string }) => {
    copyTemplateID({ id: id });
    setCopiedID(id);
  };
  const updatedAppDatatable = useMemo(() => {
    let newAppDatatable: { [key: string]: any } = {};
    const appDatatable = appSchema?.appDatatable;
    Object.keys(appDatatable).forEach((tableID) => {
      const tableData = appDatatable[tableID];
      if (tableData?.tableType !== 'junction') {
        newAppDatatable[tableID] = tableData;
      }
    });
    return newAppDatatable;
  }, [appSchema?.appDatatable]);

  return (
    <div>
      <PopUpDivChild
        initialValue={isRealtionDataPopup}
        setPopUp={setIsRealtionDataPopup}
        popupTitle={mls('Realtions')}
        className='realtionDataContainerPopup'
      >
        <div className={styles.realtionDataContainer}>
          <div className={styles.realtionDataContainerLeft}>
            <div className={styles.actionSelectionContainer}>
              <div className={styles.actionSelectionContainerSubTitle}>
                <div>{mls('Select Main Table')}</div>
                <ObjectDropDown
                  object={updatedAppDatatable}
                  dropDownValue={selectedMainTable || ''}
                  setdropDownValue={setSelectedMainTable}
                  mainKey={'tableID'}
                  ObjectInObject
                  search
                  inputPlaceHolder={mls('Select Main Table')}
                />
              </div>
              <div className={styles.actionSelectionContainerSubTitle}>
                <SearchInputDiv
                  value={templatesDataSearch}
                  onChange={setTemplatesDataSearch}
                  placeholder={mls('Search by Realtions Name, Realtions Type or Table Name')}
                  border
                  fullWidth
                />
              </div>
              <DataGridTableContainer
                onCopy={onCopy}
                tableData={templatesData}
                copiedID={copiedID}
              />
            </div>
          </div>
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default RealtionData;

interface dataGridTableContainerProps {
  onCopy: (e: any) => void;
  tableData: { [key: string]: any };
  copiedID: string;
}
const DataGridTableContainer = ({ onCopy, tableData, copiedID }: dataGridTableContainerProps) => {
  return (
    <div className={styles.dataTableContainer}>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Table Name')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Parent Table Name')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Relation Type')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Relation Name')}
      </div>
      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('Relation ID')}
      </div>

      <div className={`${styles.dataTableContainerElem} ${styles.dataTableContainerElemHeader}`}>
        {mls('')}
      </div>

      {Object.keys(tableData ?? {}).map((tableDataKey: string) => {
        const selectedTableDataData = tableData[tableDataKey];
        const tableDataData = {
          type: selectedTableDataData?.relationType,
          name: selectedTableDataData?.name ?? '',
          relationName: selectedTableDataData?.relationName ?? '',
          relationID: selectedTableDataData?.relationID ?? '',
        };
        console.log('selectedTableDataData', selectedTableDataData);

        const isCopied = copiedID === tableDataKey;

        return (
          <>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.name}</div>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.name}</div>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.type}</div>
            <div className={`${styles.dataTableContainerElem}`}>{tableDataData.relationName}</div>
            <div className={`${styles.dataTableContainerElem}`}>
              <b>{tableDataData.relationID}</b>
            </div>
            <div className={`${styles.dataTableContainerElem}`}>
              {tableDataData.type !== 'MAIN' ? (
                <button
                  className={`primaryButton ${isCopied ? styles.buttonCopied : ''}`}
                  onClick={() => {
                    onCopy({ id: tableDataKey });
                  }}
                >
                  <FaRegCopy /> &nbsp;
                  {mls(isCopied ? 'Copied' : 'Copy')}
                </button>
              ) : null}
            </div>
          </>
        );
      })}
    </div>
  );
};
