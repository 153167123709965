/*
Shows the app projects in cards
*/

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Card from '../components/Card';

// import Sorting from 'app/pages/sharable/Sorting';

import { mls } from 'lib/multilanguagesupport';
import clsx from 'clsx';
import ProjectOrder from './ProjectOrder';
import AIGenerateModule from './AIGenerateModule';

export const ProjectsHomePage = ({ openProjectToEdit }) => {
  const apps = useSelector((store) => store.config_store.appSchema.app);
  const projects = useSelector((state) => state.config_store.appSchema.app.projects);
  // const [sortedProjects, setSortedProjects] = useState([...projects]);
  const [projectOrder, setProjectOder] = useState(false);
  const [openAIModule, setOpenAIModule] = useState(false);
  const handleCreateProject = () => {
    const currentTimestamp = Date.now();
    const newProjectBlankData = {
      isNewProject: true,
      name: '',
      icon: '',
      description: '',
      screenGroup: [],
      connectedScreenGroup: [],
      projectID: uuid(),
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };
    openProjectToEdit(newProjectBlankData);
  };
  const toolbarButtonHeightClass = 'w-30px h-30px w-md-35px h-md-35px';

  return (
    <>
      <div className='d-flex justify-content-end align-items-center mb-5'>
        {/* <Sorting
          dataInRedux={projects}
          arrayOfObjects={sortedProjects}
          setArrayOfObjects={setSortedProjects}
        /> */}
        <div className={clsx('d-flex align-items-center me-3')}>
          {/* begin::Drawer toggle */}
          <div
            className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
            onClick={() => setProjectOder(true)}
          >
            <i
              className='bi bi-grid-3x3-gap-fill'
              style={{ fontSize: '1.5rem' }}
              title={mls('Change order of charts')}
            ></i>
          </div>
        </div>
        {projectOrder && (
          <ProjectOrder
            show={projectOrder}
            setShow={setProjectOder}
            projects={projects}
            apps={apps}
            appID={apps.appID}
          />
        )}

        <button onClick={handleCreateProject} className='btn btn-sm btn-primary me-3'>
          {mls('Create New Module')}
        </button>

        <button className='btn btn-sm btn-primary' onClick={() => setOpenAIModule(true)}>
          {mls('AI ERP Module')}
        </button>
      </div>
      <div className='row'>
        {(projects || []).map((project, index) => {
          return (
            <div
              className='col-md-4 col-sm-6 mb-6'
              key={index}
              onClick={() => openProjectToEdit(project)}
            >
              <Card project={project} />
            </div>
          );
        })}
      </div>
      {openAIModule && (
        <AIGenerateModule
          appID={apps.appID}
          showModal={openAIModule}
          setShowModal={setOpenAIModule}
        />
      )}
    </>
  );
};
