import {
  getConnectedTableList,
  getInnerData,
  getInnerDataElem,
  getRelatedListData,
} from 'lib/appComponent/componentSettings/utils/componentSettingFunction';
import { relationNestedLevel } from './componentUpdateFunction';

export const handleAddRelation = ({
  addRelationList,
  mainTableID,
  allComponentSchema,
  allDataTableData,
}: {
  addRelationList: { [key: string]: any };
  allComponentSchema: { [key: string]: any };
  allDataTableData: { [key: string]: any };
  mainTableID: string;
}) => {
  let updatedAddRelationTableList: { [key: string]: any } = {};
  // Outer Table Check
  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];
    // if main table id and component id matched then remove realtions from tableList.
    const { tablePresence: isTablePresent } = checkTablePresence({
      targetTableID: mainTableID,
      componentElem: componentElem,
      nestingLevel: relationNestedLevel - 1,
    });
    if (isTablePresent) {
      const { updatedTableList, tableListAffected } = getAddUpdatedTableList({
        addRelationList,
        componentElem: componentElem,
        nestingLevel: relationNestedLevel - 2,
        targetTableID: mainTableID,
        allDataTableData,
      });
      if (tableListAffected) {
        updatedAddRelationTableList = {
          ...updatedAddRelationTableList,
          [componentElemKey]: { ...componentElem, tableList: updatedTableList },
        };
      }
    }
  });

  return { updatedAddRelationTableList };
};

export const getAddUpdatedTableList = ({
  addRelationList,
  componentElem,
  nestingLevel,
  targetTableID,
  allDataTableData,
}: {
  addRelationList: { [key: string]: any };
  componentElem: { [key: string]: any };
  nestingLevel: number;
  targetTableID: string;
  allDataTableData: { [key: string]: any };
}) => {
  let updatedTableList: { [key: string]: any }[] = [];
  let tableListAffected = false;

  if (Array.isArray(componentElem?.tableList)) {
    (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
      let newTableListElem = { ...tableListElem };
      if (targetTableID === (tableListElem?.tableID ?? '')) {
        let innerTableList = newTableListElem?.tableList ?? [];
        Object.keys(addRelationList).forEach((addRelationsElemKey: string) => {
          const addRelationListElem = addRelationList[addRelationsElemKey];
          const tableElem = getInnerDataElem({ tableElem: addRelationListElem });
          const connectedTablesData = getConnectedTableList({
            belongsToTableId: [tableElem],
            finalConectedTableId: [],
            nestingLevel: relationNestedLevel,
            appDatatable: allDataTableData,
          });
          let relatedListElem: any[] = getRelatedListData({
            connectedTablesData,
            nestingLevel: relationNestedLevel - 2,
          });
          // updatedTableList = [...updatedTableList, ...relatedListElem];
          innerTableList = [...innerTableList, ...relatedListElem];
          tableListAffected = true;
        });
        if (tableListAffected) {
          newTableListElem = { ...newTableListElem, tableList: innerTableList };
        }
        updatedTableList = [...updatedTableList, newTableListElem];
      } else {
        if (nestingLevel >= 0) {
          const { updatedTableList, tableListAffected: innerTableListAffected } =
            getAddUpdatedTableList({
              addRelationList,
              componentElem: tableListElem,
              nestingLevel: nestingLevel - 1,
              targetTableID,
              allDataTableData,
            });
          if (innerTableListAffected) {
            newTableListElem = { ...newTableListElem, tableList: updatedTableList };
            tableListAffected = innerTableListAffected;
          }
        }
        updatedTableList = [...updatedTableList, newTableListElem];
      }
    });
  }
  return { updatedTableList, tableListAffected };
};

const checkTablePresence = ({
  targetTableID,
  componentElem,
  nestingLevel,
}: {
  targetTableID: string;
  nestingLevel: number;
  componentElem: { [key: string]: any };
}) => {
  const nestedTablePresenceCheck = ({
    tableList,
    targetTableID,
    nestingLevel,
  }: {
    nestingLevel: number;
    tableList: { [key: string]: any }[];
    targetTableID: string;
  }) => {
    const isTablePresent = tableList.some((tableElem: any) => {
      const isTablePresent = tableElem?.tableID === targetTableID;
      if (isTablePresent) {
        return true;
      } else {
        if (nestingLevel >= 0) {
          return nestedTablePresenceCheck({
            tableList: tableElem?.tableList ?? [],
            targetTableID,
            nestingLevel: nestingLevel - 1,
          });
        }
      }
      return true;
    });
    return isTablePresent;
  };
  if (componentElem?.table === targetTableID) {
    return { tablePresence: true };
  }
  const tablePresence = nestedTablePresenceCheck({
    tableList: componentElem?.tableList ?? [],
    targetTableID,
    nestingLevel,
  });

  return { tablePresence: tablePresence };
};
