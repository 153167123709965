import { Redirect, Route, Switch } from 'react-router-dom';

// import ManageEmployees from '../pages/manage-employees/ManageEmployees';
import MlsPage from '../pages/mls/MLS';
// import ButtonPage from '../pages/button-page/ButtonPage';
// import AccessGroupPage from '../pages/access-group/AccessGroupPage';
import ProjectsPage from 'app/pages/project-page/ProjectsPage';
// import DataWarehouse from 'lib/data-connectors/DataWarehouse';
import Users from 'app/pages/roleAccess/Users';
import Teams from 'app/pages/roleAccess/Teams';
// import DataTable from 'lib/data-table/DataTablePage';
import ComponentPage from 'lib/appComponent/ComponentPage';
import DataTable from 'lib/erp-data-table/DataTablePage';
import GeneralSettings from 'lib/app-base-settings/GeneralSettings/GeneralSettings';
import AppAssets from 'lib/app-base-settings/AppAssets/AppAssets';
import AppCredentials from 'lib/app-base-settings/AppCredentials/AppCredentials';

export function PrivateRoutes() {
  return (
    // <Suspense fallback={<FallbackView />}>
    <Switch>
      <Route path='/:appID/settings/general' component={GeneralSettings} />
      <Route path='/:appID/settings/assets' component={AppAssets} />
      <Route path='/:appID/settings/credentials' component={AppCredentials} />
      <Route path='/:appID/modules' component={ProjectsPage} />
      <Route path='/:appID/mls' component={MlsPage} />
      {/* <Route path='/:appID/manage-users' component={ManageEmployees} /> */}
      <Route path='/:appID/components' component={ComponentPage} />
      {/* <Route path='/:appID/buttons' component={ButtonPage} /> */}
      {/* <Route path='/:appID/access-group' component={AccessGroupPage} /> */}
      <Route path='/:appID/data-table' component={DataTable} />
      <Route path='/:appID/role-access/users' component={Users} />
      <Route path='/:appID/role-access/teams' component={Teams} />
      <Route path='/:appID/role-access' component={Users} />
      <Redirect from='/:appID/settings' exact to='/:appID/settings/general' />
      <Redirect from='/:appID/role-access' exact to='/:appID/role-access/users' />
      <Redirect from='/:appID' exact to='/:appID/data-table' />
      <Redirect from='/auth' to='/:appID/data-table' />
      {/* <Redirect to='error/404' /> */}
    </Switch>
    // </Suspense>
  );
}
