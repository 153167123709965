import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export const isXLSXFile = (file: File): boolean => {
  const allowedTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ];
  return allowedTypes.includes(file.type) || file.name.endsWith('.xlsx');
};
interface getFileDataProps {
  isXLSX: boolean;
  csvFileData: string;
  doesHaveHeaderRow: boolean;
  fileName: string;
}
export const getFileData = ({
  isXLSX,
  csvFileData,
  doesHaveHeaderRow,
  fileName,
}: getFileDataProps) => {
  let isMultipleTable = false;
  let tableData = {};
  if (isXLSX) {
    const excelData = XLSXToProcessedData({ excelFileData: csvFileData, doesHaveHeaderRow });
    tableData = { ...tableData, ...excelData };
    Object.keys(excelData ?? {}).length > 1 && (isMultipleTable = true);
  } else {
    // If the CSV does not have header rows, add a header row 'Column <n>'
    if (!doesHaveHeaderRow) {
      const numberOfColumns: number = csvFileData
        .slice(0, csvFileData.indexOf('\n'))
        .split(',').length;
      const headers = Array.from(
        { length: numberOfColumns },
        (_, colIndex) => `Column ${colIndex + 1}`
      ).join(',');
      csvFileData = headers + '\n' + csvFileData;
    }

    const excelData = getPapaCsvToData({ csvFileData });
    tableData = { ...tableData, [fileName]: excelData };
  }

  return { isMultipleTable, tableData };
};

interface XLSXToProcessedDataProps {
  excelFileData: any;
  doesHaveHeaderRow: boolean;
}
const XLSXToProcessedData = ({ excelFileData, doesHaveHeaderRow }: XLSXToProcessedDataProps) => {
  let excelData = {};

  const wb = XLSX.read(excelFileData, { type: 'buffer' });
  const sheets = wb.SheetNames;

  sheets.forEach((sheetName) => {
    const sheet = wb.Sheets[sheetName];
    let sheetData: any[] = XLSX.utils.sheet_to_json(sheet, {
      raw: false, // Ensures date conversion
      dateNF: 'yyyy-mm-dd', // Specify the desired format
    });
    if (!doesHaveHeaderRow) {
      sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Get raw data as array
      if (sheetData.length > 0) {
        const headers = sheetData?.[0]?.map(
          (_: { [key: string]: any }, index: number) => `Column ${index + 1}`
        ); // Custom column names
        sheetData = sheetData
          .slice(1) // Remove the first row (headers)
          .filter((row: { [key: string]: any }[]) => row.length > 0) // Filter out empty rows
          .map((row: { [key: string]: any }[]) => {
            let obj: { [key: string]: any } = {};
            row.forEach((cell, index) => {
              obj[headers[index]] = cell !== undefined ? cell : null; // Handle missing values
            });
            return obj;
          });
      }
    }

    excelData = { ...excelData, [sheetName]: sheetData };
  });
  return excelData;
};
interface getPapaCsvToDataProps {
  csvFileData: string;
}
const getPapaCsvToData = ({ csvFileData }: getPapaCsvToDataProps) => {
  let csvData: { [key: string]: any } = [];
  Papa.parse(csvFileData, {
    header: true,
    skipEmptyLines: true,
    dynamicTyping: true,
    complete: (results: { [key: string]: any }) => {
      const updatedData = (results?.data ?? [])?.map((dataElem: { [key: string]: any }) => {
        let newDataElem = {};
        Object.keys(dataElem ?? {}).forEach((key) => {
          const value = dataElem[key];
          const finalValue =
            typeof value === 'string' && value.match(/^\d{1,3}(,\d{3})*$/)
              ? parseFloat(value.replace(/,/g, ''))
              : value;
          newDataElem = { ...newDataElem, [key]: finalValue };
        });

        const newElem: { [key: string]: any } = { ...newDataElem };
        delete newElem.id;
        delete newElem.ID;
        delete newElem.Id;
        delete newElem.iD;
        return newElem;
      });
      csvData = updatedData;
    },
    // transform: transformValue,
  });
  return csvData;
};
